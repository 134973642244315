import { useFetchRedundantRuns } from "@/hooks/queries/useFetchRedundantRuns"
import { RedundantCIRunsTable } from "@/components/designSystem/RedundantCIRuns/RedundantRunsTable"
import { StatCard } from "@/components/designSystem/RedundantCIRuns/StatCard"
import { ContentContainer } from "@/components/designSystem/ContentContainer"
import StopWatchSVG from "@/assets/icons/StopWatchSVG"
import { useMemo } from "react"
import { Paginator } from "@/components"
import { useTranslation } from "react-i18next"
import { Runs } from "@/models/RedundantRuns"
import DollarSVG from "@/assets/icons/DollarSVG"
import GithubSVG from "@/assets/icons/GithubSVG"
import { formatDuration } from "@/lib/dateFormats"
import NoRedundantRunsSVG from "@/assets/icons/NoRedundantRuns"
import { MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { formatCurrency } from "@/lib/formatters"
import { useCollapsedSidebar } from "@/hooks/useSidebarWidth"
import { useSearchParams } from "react-router-dom"
import { useFetchFeatureFlags } from "@/hooks/queries/useFetchFeatureFlags"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { errorHandler } from "@/services/api/helpers"
import { useSettings } from "@/services/store/useSettings"
import NoRedundantRunsSVGDark from "@/assets/icons/NoRedundantRunsDark"

export function RedundantCIRuns() {
  const { t } = useTranslation("RedundantRun")
  document.title = "Runs"
  const [searchParams] = useSearchParams()
  const pageParam = searchParams.get("page")
  const { selectedTheme } = useSettings()

  const { data: featureFlagsData } = useFetchFeatureFlags()
  useCollapsedSidebar()

  const {
    data: apiData,
    isLoading,
    error
  } = useFetchRedundantRuns({
    page: pageParam
  })

  const data = useMemo<Runs>(() => {
    const enableRedundantData = Boolean(featureFlagsData?.enable_redundant_ci_comment)

    return (
      (enableRedundantData && apiData) || {
        redundantRuns: [],
        mostRedundantJob: "",
        estimatedRedundantTime: 0,
        estimatedSavings: 0
      }
    )
  }, [apiData, featureFlagsData])

  if (isLoading || error) return <div className="py-4">{isLoading ? <SpinnerLoader /> : errorHandler(error)}</div>

  const statCards: StatCard[] = [
    {
      title: "EstimatedRedundantCITime",
      icon: <StopWatchSVG />,
      value: formatDuration(data.estimatedRedundantTime)
    },
    {
      title: "EstimatedSavings",
      icon: <DollarSVG />,
      value: formatCurrency(data.estimatedSavings)
    },
    {
      title: "MostRedundantJob",
      icon: <GithubSVG />,
      value: data.mostRedundantJob
    }
  ]

  return (
    <ContentContainer>
      <header className="mb-8 flex items-center justify-between">
        <div className="mt-6">
          <h1 className="mb-1 text-base font-medium text-primary">{t("Header")}</h1>
          <h2 className="text-s leading-5 text-secondary">{t("SubHeader")}</h2>
        </div>
      </header>

      {data.redundantRuns.length > 0 ? (
        <>
          {statCards.every((card) => card.value === undefined) ? (
            <div className="mb-6 flex flex-col items-center justify-center rounded-lg border border-border bg-background px-4 py-5">
              <MagnifyingGlassIcon color="var(--base-secondary)" />
              <p className="mt-2 text-xs text-secondary">{t("NoRunsKPI")}</p>
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-8">
              {statCards.map((statCard, index) => (
                <StatCard key={index} statCard={statCard} />
              ))}
            </div>
          )}

          <RedundantCIRunsTable data={data} />
        </>
      ) : (
        <div className="flex h-[calc(100vh-273px)] flex-col items-center justify-center">
          {selectedTheme === "light" ? <NoRedundantRunsSVG /> : <NoRedundantRunsSVGDark />}
          <h1 className="mb-1 mt-6 text-[16px] font-semibold leading-7 text-secondary">
            {t("EmptyRedundantRunsHeader")}
          </h1>
          <h2 className="text-s font-normal leading-5 text-hint">{t("EmptyRedundantRunsSubHeader")}</h2>
        </div>
      )}
      <Paginator hasMore={data.redundantRuns.length > 10} total={data.redundantRuns.length} itemsPerPage={10} />
    </ContentContainer>
  )
}
