import { useCallback, useMemo } from "react"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useSelection } from "@/services/store/useSelection"
import {
  BazzyCodeOptions,
  BazzyContext,
  BazzyErrorType,
  BazzyInsightOptions,
  BazzyQuestionsCategories
} from "@/models/Bazzy.ts"
import { useFetchBazzy } from "@/hooks/queries/useFetchBazzy"
import { useBazzy } from "@/services/store/useBazzy"
import { createBazzyContext } from "@/components/designSystem/Discussions/components/Bazzy/utils"

interface UseBazzyMessagesReturn {
  addUserQuestionToBazzy: (
    questionId: BazzyCodeOptions | BazzyInsightOptions,
    questionLabel: string,
    type?: BazzyQuestionsCategories,
    relevantInsight?: string,
    predefinedContext?: BazzyContext,
    insightType?: BazzyErrorType
  ) => void
  isLoading: boolean
  error: Error | null
}

export const useBazzyMessages = (): UseBazzyMessagesReturn => {
  const { changeRequest } = useChangeRequest()
  const {
    toggleBazzy,
    addMessageToContext,
    setBazzyMessageTimestamp,
    setBazzyOption,
    updateContext,
    currentBazzyOption,
    currentContext,
    currentMessageTimestamp,
    freeText,
    enableFetch,
    setEnableFetch
  } = useBazzy()

  const { selectedLines, firstSelectedSide, selectedTableFilePath } = useSelection()
  const shouldCallBazzy = currentBazzyOption !== BazzyCodeOptions.FREE_TEXT || freeText !== ""

  const memoizedBaseProps = useMemo(
    () => ({
      enabled: enableFetch && shouldCallBazzy,
      bazzyOption: currentBazzyOption,
      context: currentContext,
      commitSha: changeRequest?.commits[0]?.sha || "",
      fullRepoName: changeRequest?.head_repo_full_name || "",
      freeText,
      timestamp: currentMessageTimestamp || 0,
      insight: currentContext?.relevantInsight
    }),
    [
      enableFetch,
      shouldCallBazzy,
      currentBazzyOption,
      currentContext,
      changeRequest?.commits,
      changeRequest?.head_repo_full_name,
      freeText,
      currentMessageTimestamp
    ]
  )

  const currentQuery = useFetchBazzy(currentContext?.relevantInsight ? "insight" : "ask", memoizedBaseProps)

  const { error, isLoading } = currentQuery
  if (error) {
    addMessageToContext({
      role: "bazzy",
      content: "Failed. Please try another question",
      timestamp: currentMessageTimestamp
    })
  }

  const addUserQuestionToBazzy = useCallback(
    (
      questionId: BazzyCodeOptions | BazzyInsightOptions,
      questionLabel: string,
      type?: BazzyQuestionsCategories,
      relevantInsight?: string,
      predefinedContext?: BazzyContext,
      insightType?: BazzyErrorType
    ) => {
      const context =
        predefinedContext ||
        (!selectedLines.startLine && !selectedLines.endLine
          ? currentContext
          : createBazzyContext({
              selectedTableFilePath,
              selectedLines,
              firstSelectedSide,
              type,
              relevantInsight,
              insightType
            }))

      if (!context) {
        console.error("Failed to create Bazzy context. Aborting question addition.")
        return
      }

      const timestamp = Date.now()

      setBazzyOption(questionId)
      setBazzyMessageTimestamp(timestamp + 1)
      updateContext(context)
      if (questionLabel !== "Ask Bazzy") {
        addMessageToContext({
          role: "user",
          content: questionLabel,
          timestamp
        })
      }
      toggleBazzy(true)
      setEnableFetch(true)
    },
    [
      currentContext,
      selectedTableFilePath,
      selectedLines,
      firstSelectedSide,
      setBazzyOption,
      setBazzyMessageTimestamp,
      updateContext,
      addMessageToContext,
      toggleBazzy,
      setEnableFetch
    ]
  )

  return { addUserQuestionToBazzy, isLoading, error }
}
