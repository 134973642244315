import { Runs } from "@/models/RedundantRuns"
import { Table } from "@/components"
import { flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table"
import { useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react"
import { createRedundantCIRunsColumns } from "./createRedundantCIRunsColumns"

interface Props {
  data: Runs
}

export const RedundantCIRunsTable = ({ data }: Props) => {
  const [searchParams] = useSearchParams()
  const currentPage = Number(searchParams.get("page") || "1")
  const itemsPerPage = 10
  const [sorting, setSorting] = useState<SortingState>([{ id: "duration", desc: true }])

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedData = useMemo(() => {
    return data.redundantRuns.slice(startIndex, endIndex)
  }, [data, startIndex, endIndex])

  // Get columns from the separate file
  const columns = useMemo(() => createRedundantCIRunsColumns(), [])

  const handleHeaderClick = (columnId: string) => {
    setSorting((prev) => [
      {
        id: columnId,
        desc: prev[0]?.id === columnId ? !prev[0].desc : false
      }
    ])
  }

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <div className="mt-6 overflow-hidden rounded-lg border border-border">
      <Table className="w-full border-separate border-spacing-0">
        <thead className="bg-fill">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={`
                    cursor-pointer border border-border bg-fill px-4 py-3 text-left font-medium text-primary
                    ${index === 0 && "rounded-tl-lg"}
                    ${index === headerGroup.headers.length - 1 && "rounded-tr-lg"}
                  `}
                  onClick={() => header.column.getCanSort() && handleHeaderClick(header.column.id)}
                >
                  <div className="flex items-center gap-2">
                    <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>

                    {header.column.getCanSort() && (
                      <span className="ml-auto">
                        {header.column.getIsSorted() === "asc" ? (
                          <ArrowUp className="size-4" />
                        ) : header.column.getIsSorted() === "desc" ? (
                          <ArrowDown className="size-4" />
                        ) : (
                          <ArrowUpDown className="size-4 opacity-30" />
                        )}
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-background">
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr key={row.id} className="hover:bg-fill">
              {row.getVisibleCells().map((cell, cellIndex) => (
                <td
                  key={cell.id}
                  className={`
                    border border-border px-4 py-3
                    ${cellIndex === 0 && rowIndex === table.getRowModel().rows.length - 1 ? "rounded-bl-lg" : ""}
                    ${cellIndex === row.getVisibleCells().length - 1 && rowIndex === table.getRowModel().rows.length - 1 ? "rounded-br-lg" : ""}
                  `}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
