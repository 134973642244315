import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "@/components"
import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

interface Props {
  total: number
  itemsPerPage?: number
  hasMore: boolean
}

const isActive = (currentPage: number, pageNum: number) => {
  return currentPage === pageNum
}

export const Paginator = ({ total, itemsPerPage = 20, hasMore }: Props) => {
  const totalPages = Math.ceil(total / itemsPerPage)
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get("page") || "1")

  const handleNext = useCallback(() => {
    if (!hasMore) return

    const nextPage = currentPage + 1

    setSearchParams((params) => {
      params.set("page", nextPage.toString())
      return params
    })
  }, [hasMore, currentPage, setSearchParams])

  const handlePrev = useCallback(() => {
    if (currentPage <= 1) return

    const prevPage = currentPage - 1
    setSearchParams((params) => {
      params.set("page", prevPage.toString())
      return params
    })
  }, [currentPage, setSearchParams])

  const handlePageClick = useCallback(
    (page: number) => {
      setSearchParams((params) => {
        params.set("page", page.toString())
        return params
      })
    },
    [setSearchParams]
  )

  // Generate the visible page numbers with ellipses
  const visiblePages = useMemo(() => {
    const delta = 1 // Number of pages to show on each side of current page
    const pages = []

    // Always include first and last page
    const rangeStart = Math.max(2, currentPage - delta)
    const rangeEnd = Math.min(totalPages - 1, currentPage + delta)

    // Always add page 1
    if (totalPages > 0) {
      pages.push(1)
    }

    // Add ellipsis if there's a gap after page 1
    if (rangeStart > 2) {
      pages.push("ellipsis1")
    }

    // Add pages around the current page
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(i)
    }

    // Add ellipsis if there's a gap before the last page
    if (rangeEnd < totalPages - 1) {
      pages.push("ellipsis2")
    }

    // Add the last page (if it's not already the only page)
    if (totalPages > 1) {
      pages.push(totalPages)
    }

    return pages
  }, [currentPage, totalPages])

  if (total === 0) {
    return <div></div>
  }

  return (
    <Pagination className="mt-6 flex w-full justify-start">
      <PaginationPrevious disabled={currentPage <= 1} onClick={handlePrev} />
      <PaginationContent className="px-1">
        {visiblePages.map((page, index) => {
          if (page === "ellipsis1" || page === "ellipsis2") {
            return <PaginationEllipsis key={`ellipsis-${index}`} />
          }

          return (
            <PaginationItem
              onClick={() => handlePageClick(page as number)}
              className="cursor-pointer"
              key={`page-${page}`}
            >
              <PaginationLink className="px-1" isActive={isActive(currentPage, page as number)}>
                {page}
              </PaginationLink>
            </PaginationItem>
          )
        })}
      </PaginationContent>
      <PaginationNext disabled={currentPage === totalPages} onClick={handleNext} />
    </Pagination>
  )
}
