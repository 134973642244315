import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { tBodyScrollOffset } from "@/pages/crFileChanges/components/helpers"
import { BREAKING_POINT_FOR_MINIMIZING_HEADER } from "@/components/designSystem/FCC/utils"

interface ScrollToAnchorProps {
  sideEffectCallback?: (hash: string) => void
}

export function ScrollToAnchor({ sideEffectCallback }: ScrollToAnchorProps) {
  const location = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!location.hash) {
        return
      }
      const hash = location.hash.slice(1)

      if (hash && document.getElementById(hash)) {
        const elementTop = document.getElementById(hash)?.getBoundingClientRect().top || 0
        scrollTo({ top: elementTop - tBodyScrollOffset - BREAKING_POINT_FOR_MINIMIZING_HEADER, behavior: "instant" })
      }

      sideEffectCallback && sideEffectCallback(hash)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [location, sideEffectCallback])

  return null
}
