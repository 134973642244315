import React from "react"

type ColorKey = "modified" | "added" | "removed" | "text"

type ThemeColors = {
  [key in ColorKey]: string
}

const THEME_COLORS: Record<"dark" | "light", ThemeColors> = {
  dark: {
    modified: "rgba(237, 172, 76, 0.85)",
    added: "rgba(21, 170, 122, 0.85)",
    removed: "rgba(205, 82, 112, 0.85)",
    text: "#94A3B8"
  },
  light: {
    modified: "#EDAC4C",
    added: "#15AA7A",
    removed: "#CD5270",
    text: "#64748B"
  }
}

type LegendItem = {
  key: Exclude<ColorKey, "text">
  text: string
}

const LEGEND_ITEMS: LegendItem[] = [
  { key: "modified", text: "Modified" },
  { key: "added", text: "Added" },
  { key: "removed", text: "Removed" }
]

interface LegendItemsProps {
  theme: "dark" | "default"
}

export const Legend: React.FC<LegendItemsProps> = ({ theme }) => {
  const localTheme = theme === "default" ? "light" : "dark"
  const colors = THEME_COLORS[localTheme]

  return (
    <div className="absolute bottom-4 right-4 flex items-center justify-end gap-2">
      {LEGEND_ITEMS.map((item) => (
        <div key={item.key} className="flex items-center gap-1">
          <span style={{ color: colors.text, fontSize: "10px" }}>{item.text}</span>
          <div
            style={{
              width: "16px",
              height: "2px",
              backgroundColor: colors[item.key]
            }}
          />
        </div>
      ))}
    </div>
  )
}
