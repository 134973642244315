import { Discussion, NewCommentBubble } from "@/models/Discussions"
import { TFunction } from "i18next"
import { z } from "zod"

export interface GetServerFormatArgs {
  current: NewCommentBubble | Discussion | null
  body: string
  commitSha?: string
  crId?: string
}

export const getSchema = (t: TFunction<"Discussions", undefined>) =>
  z.object({
    body: z.string().min(1, {
      message: t("AddCommentErrorMessage")
    })
  })

export const getServerFormat = ({ current, body, crId, commitSha }: GetServerFormatArgs) => {
  const { side, start_line, end_line, file, selectedText } = current as NewCommentBubble
  let computedStartLine = start_line

  if (!commitSha || !body || !crId || !current || !side || !start_line || !end_line || !file) {
    console.error("Error: one of the parameters to create a comment is missing")
    return null
  }

  if (start_line === end_line) {
    computedStartLine = null
  }

  return {
    body,
    body_content_type: "html" as const,
    commit_sha: commitSha,
    discussion_type: "code" as const,
    start_line: computedStartLine,
    end_line,
    code_change_id: crId,
    side,
    commented_code: selectedText || "No selected text",
    end_column: null,
    start_column: null,
    file,
    start_side: side
  }
}

// translates characters to a string
export const escapeHTML = (text: string | undefined) => {
  const element = document.createElement("div")
  if (text) {
    element.innerText = text
    return element.innerHTML
  }
  return ""
}

/*
 * This function is used to check if the given text contains a word is too long. It breaks the text according
 * to spaces and special characters to check if any word is longer than 35 characters.
 * @param text - the text to check
 * @returns boolean - true if the text contains a word that is too long, false otherwise
 */
export const isWordTooLong = (text: string) => {
  const result = text.split(/[\s<>]/).find((word) => word.length > 35)
  return !!result
}
