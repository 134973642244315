import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader.tsx"
import { useFetchUser } from "@/hooks/queries/useFetchUser.tsx"
import { useFetchReviewerConfig } from "@/hooks/queries/useFetchReviewerConfig.tsx"
import { REVIEWER_TYPES } from "@/pages/settings/reviewer/ReviewerTypes.tsx"

import { AlertCircle } from "lucide-react"
import { useUpdateReviewerConfiguration } from "@/hooks/mutations/useUpdateReviewerConfiguration"
import { ExcludedConfig, FindingConfig } from "@/models/ReviewerConfig.ts"
import { ConfigItem } from "@/pages/settings/reviewer/ConfigItem"
import { ExcludeConfigManager } from "@/pages/settings/reviewer/ExcludeConfigManager"
import { useFetchRepositories } from "@/hooks/queries/useFetchRepositories"
import { useUpdateReviewerExcludedConfiguration } from "@/hooks/mutations/useUpdateReviewerExcludedConfiguration"
import { useDeleteReviewerExcludedConfiguration } from "@/hooks/mutations/useDeleteReviewerExcludedConfiguration"

export function Preferences() {
  const { data: backendUser, isLoading } = useFetchUser()
  const { data: reviewerConfig, isLoading: isReviewerConfigLoading } = useFetchReviewerConfig()
  const { mutateAsync: updateReviewerConfig } = useUpdateReviewerConfiguration()
  const { data: repositories, isLoading: repositoriesLoading } = useFetchRepositories()
  const { mutateAsync: updateExcludedConfiguration } = useUpdateReviewerExcludedConfiguration()
  const { mutateAsync: deleteExcludedConfiguration } = useDeleteReviewerExcludedConfiguration()

  const { t } = useTranslation("Reviewer")
  document.title = "Settings - Preferences"

  const mergedReviewerTypes = useMemo(() => {
    const findingConfig = reviewerConfig?.findingConfig || []
    return REVIEWER_TYPES.map((reviewerType) => {
      const config = findingConfig.find((config: FindingConfig) => config.findingType === reviewerType.findingType)

      return {
        ...reviewerType,
        enabled: config?.enabled ?? true
      }
    })
  }, [reviewerConfig])

  const computedRepositoriesOptions = useMemo(
    () =>
      repositories?.map((repo) => ({
        name: repo.fullName,
        value: repo.fullName
      })) || [],
    [repositories]
  )

  const handleClick = (findingType: FindingConfig) => {
    const newConfig = {
      findingType: findingType.findingType,
      enabled: !findingType.enabled
    }
    updateReviewerConfig(newConfig)
  }

  const handleConfigChange = (config: ExcludedConfig | null) => {
    config && updateExcludedConfiguration(config)
  }

  const handleConfigDelete = (repositoryName: string) => {
    deleteExcludedConfiguration({ repoName: repositoryName })
  }

  const isAdmin = useMemo(() => {
    return backendUser && (backendUser.is_owner || backendUser.permissions.some((p) => p.role === "admin"))
  }, [backendUser])

  if (isLoading || isReviewerConfigLoading) {
    return <SpinnerLoader className="py-4" />
  }

  return (
    <section>
      {!isAdmin && (
        <div className="flex justify-between pb-4">
          <h1 className="flex self-center text-s font-medium text-primary">{t("ReviewerWorkflow")}</h1>
          <div className="flex h-fit flex-row rounded-md border border-border bg-fill px-4 py-1.5  text-s font-normal text-secondary">
            <AlertCircle className="mr-3 flex size-4 self-center" />
            {t("ConfigurationRestriction")}
          </div>
        </div>
      )}
      <div className="flex flex-wrap gap-4 pb-2.5">
        {mergedReviewerTypes.map((item, index) => (
          <ConfigItem item={item} key={index} disabled={!isAdmin} handleClick={handleClick} />
        ))}
      </div>

      <div>
        {repositoriesLoading ? (
          <SpinnerLoader className="py-4" />
        ) : (
          <ExcludeConfigManager
            repositories={computedRepositoriesOptions}
            existingConfigs={reviewerConfig?.excludedConfig || []}
            onConfigChange={handleConfigChange}
            onConfigDelete={handleConfigDelete}
            disabled={!isAdmin}
          />
        )}
      </div>
    </section>
  )
}
