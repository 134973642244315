import { ExcludedConfig } from "@/models/ReviewerConfig"
import { ConfigSection, RepositoryOption } from "@/pages/settings/reviewer/ExcludeConfigManager"
import { Button, SingleSelect } from "@/components"
import { SetStateAction } from "react"
import { TriggerElementProps } from "@/components/designSystem/SelectVariations/types"
import { ChevronDown } from "lucide-react"

export interface RepositorySelectorArgs {
  config: ConfigSection
  configSections: Array<ConfigSection>
  repositories: RepositoryOption[]
  handleSelect: (configId: string, repoName: string) => void
  handleToggle: (configId: string) => void
  handleSearchChange: (configId: string, repoName: SetStateAction<string>) => void
  disabled: boolean
}

export const GLOB_PATTERN_URL = "https://code.visualstudio.com/docs/editor/glob-patterns"

export const RepositoriesTriggerElement = ({
  label,
  selectItemPlaceholder,
  TriggerIcon,
  disabled
}: TriggerElementProps) => {
  return (
    <Button
      disabled={disabled}
      className={`
      ${disabled ? "disabled:pointer-events-auto disabled:cursor-not-allowed" : ""} 
      w-full rounded-none rounded-l-md`}
      variant="outline"
    >
      <p className="w-full text-start text-xs text-primary">{label || selectItemPlaceholder}</p>

      {TriggerIcon && <TriggerIcon size={16} />}
    </Button>
  )
}

export const renderRepositorySelector = ({
  repositories,
  configSections,
  config,
  handleSelect,
  handleToggle,
  handleSearchChange,
  disabled
}: RepositorySelectorArgs) => {
  return (
    <SingleSelect
      options={getAvailableRepositories(config.selectedRepo, configSections, repositories)}
      isOpen={config.open}
      selectedValue={config.selectedRepo}
      searchValue={config.searchValue}
      onClick={(value) => !disabled && handleSelect(config.id, value)}
      openToggle={() => !disabled && handleToggle(config.id)}
      setSearchValue={(value) => handleSearchChange(config.id, value)}
      optionalParams={{
        searchPlaceholder: "Search repositories...",
        emptyResultsMessage: "No repositories found",
        selectItemPlaceholder: "Choose repo *",
        withSearch: true,
        disabled,
        CustomTriggerElement: RepositoriesTriggerElement,
        TriggerIcon: ChevronDown
      }}
    />
  )
}

export const getExcludedServerFormat = (
  configureSelections: Array<ConfigSection>,
  fullRepoName: string
): ExcludedConfig | null => {
  if (fullRepoName === "new") {
    console.log("Repo name still not selected")
    return null
  }

  const changedConfig = configureSelections.find((config) => config.selectedRepo === fullRepoName)

  return !changedConfig
    ? null
    : {
        fullRepoName: changedConfig.selectedRepo,
        excludedPaths: changedConfig.excludedPaths
      }
}

export const getAvailableRepositories = (
  currentRepo: string,
  configSections: ConfigSection[],
  repoOptions: RepositoryOption[]
) => {
  const selectedRepos = configSections
    .filter((section) => section.selectedRepo && section.selectedRepo !== currentRepo)
    .map((section) => section.selectedRepo)

  return repoOptions.filter((repo) => !selectedRepos.includes(repo.value))
}

export const validateGlobPattern = (pattern: string): boolean => {
  const patterns = pattern.split(",").map((p) => p.trim())
  const invalidChars = /[<>:"|?]/

  return patterns.every((p) => {
    if (!p) return true
    if (invalidChars.test(p)) return false
    return !p.includes("..")
  })
}
