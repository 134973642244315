import { CommentProps } from "@/components/designSystem/Discussions/components/Comment/index.tsx"
import { isWordTooLong } from "./utils"
import { parseAndSanitizeComment } from "@/lib/utils"
import { splitCommentAndSuggestion } from "@/components/designSystem/Discussions/utils"
import { mdTransformer } from "@/lib/mdTransformer"
import { useSettings } from "@/services/store/useSettings"

export const RenderComment = ({ comment }: CommentProps) => {
  const { comment: commentText } = splitCommentAndSuggestion(comment.comment_body)
  const { selectedTheme } = useSettings()

  if (!commentText) return null

  return (
    <div
      className={`mb-2 ${isWordTooLong(commentText) ? "break-all" : "break-words"} text-xs`}
      tabIndex={0}
      aria-label="comment with rich editor"
    >
      <div>
        {comment.body_content_type === "html" ? (
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: parseAndSanitizeComment(commentText) }}
          ></div>
        ) : (
          mdTransformer(commentText, selectedTheme)
        )}
      </div>
    </div>
  )
}
