import { X } from "lucide-react"
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components"
import { BaseDialogProps } from "@/pages/crFileChanges/components/CRHeader/CRActions/InfoActions/types"
import { EditUserDescriptionForm } from "@/components/designSystem/Description/EditUserDescriptionForm"
import { useForm } from "react-hook-form"

export const EditDescriptionDialog = ({
  onClose,
  onTriggerClick,
  open,
  children,
  userDescription,
  title
}: BaseDialogProps & { userDescription: string; title: string }) => {
  const form = useForm({
    defaultValues: {
      body: userDescription
    }
  })

  return (
    <Dialog open={open} modal={false}>
      <DialogTrigger onClick={onTriggerClick}>{children}</DialogTrigger>

      <DialogContent
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
        className="flex h-fit max-h-[399px] max-w-[696px] flex-col gap-6 overflow-hidden rounded-2xl border border-border p-8 text-primary"
      >
        <DialogTitle className="flex w-full items-center justify-between">
          <p className="content-center text-xs leading-8 text-primary">{title}</p>
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
        </DialogTitle>

        <EditUserDescriptionForm description={userDescription} form={form} onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}
