import * as Prism from "prismjs"
import { Token } from "prismjs"

import { FccRow, IsMarkedArgs, Side } from "@/models/FCC"
import { extractCellNumber, isEmptyContent, isLine, isNumberBetween } from "."
import { addRangeToFlattenedTokens, applyEditsToTokens, flattenTokenArray } from "./codeHighlightComputations"
import { RenderChangedLineArgs } from "./highlighTypes"
import { renderHighlightedSpan, transformTokenToSpan } from "./transformTokenToSpan"
import { fileExtensionMapping, getSHA256Hash } from "@/lib/utils.ts"
import { CellContext } from "@tanstack/react-table"

export const renderChangedLine = ({ action, content, fileExtension, line }: RenderChangedLineArgs) => {
  const highlightedCode = Prism.tokenize(content, fileExtensionMapping[fileExtension].prismLanguage)
  const flattenedTokens = flattenTokenArray(highlightedCode)
  const tokensWithNumbersRange = addRangeToFlattenedTokens(flattenedTokens)
  const tokensWithAppliedEdits = applyEditsToTokens(tokensWithNumbersRange, line.edits, action)

  return (
    <p>
      {tokensWithAppliedEdits.map((token) =>
        renderHighlightedSpan({ text: token.content, backgroundColor: token.backgroundColor, type: token.type })
      )}
    </p>
  )
}

const renderHighlightedSpans = (highlightedCode: (string | Token)[]): JSX.Element[] => {
  return highlightedCode.map((token) => {
    if (typeof token === "string") {
      return renderHighlightedSpan({ text: token })
    }
    return transformTokenToSpan(token)
  })
}

export const renderCodeLine = (
  line: FccRow,
  fileExtension: string,
  columnSide: Side,
  isSplitView: boolean
): React.ReactNode | null => {
  const { line_type: editAction, content, new_content, edits } = line
  const shouldHighlightAsDeleted =
    (isSplitView && columnSide === "left") || (!isSplitView && edits?.[0]?.edit_action === "Deleted")

  const codeToRender = shouldHighlightAsDeleted ? content : new_content
  const action = shouldHighlightAsDeleted ? "Deleted" : "Added"

  if (isEmptyContent(isSplitView, editAction, columnSide)) {
    return <span />
  }

  if (!editAction) {
    return codeToRender ? renderRegularLine(codeToRender, fileExtension) : null
  }

  switch (editAction) {
    case "Added":
    case "Deleted":
    case "Unchanged":
      return renderRegularLine(codeToRender || "", fileExtension)

    case "Changed":
      return codeToRender && renderChangedLine({ content: codeToRender, fileExtension, line, action })

    default:
      console.error("Unsupported edit action type:", editAction)
      return null
  }
}

export const renderRegularLine = (content: string, fileExtension: string) => {
  const highlightedCode = Prism.tokenize(content, fileExtensionMapping[fileExtension].prismLanguage)
  const highlightedSpans = renderHighlightedSpans(highlightedCode)

  return <p>{highlightedSpans}</p>
}

export const renderSubRowString = (content: string, fileExtension: string) => {
  return renderRegularLine(content, fileExtension)
}

export const getFileExtension = (filePath?: string): string => {
  const split = filePath?.split(".") || []
  const extension = split[split.length - 1]
  const keys = Object.keys(fileExtensionMapping)

  return keys.includes(extension) ? extension : "bash"
}

export const isCellMarked = ({
  discussion,
  columnSide,
  filePath,
  info,
  isSplitView,
  bazzyContext,
  isBazzyOpen
}: IsMarkedArgs): boolean => {
  const line = info.getValue()

  if (
    (!discussion && !bazzyContext) ||
    !filePath ||
    (discussion?.file !== filePath && bazzyContext?.filePath !== filePath) ||
    !isLine(line)
  )
    return false

  const cellNumber = extractCellNumber(line, isSplitView, columnSide)

  if (discussion) {
    const { end_line, start_line } = discussion

    if (discussion.side === columnSide) {
      return isNumberBetween({ number: cellNumber, endNumber: end_line, startNumber: start_line })
    }
  }
  if (bazzyContext && isBazzyOpen) {
    const { lines } = bazzyContext

    return isNumberBetween({ number: cellNumber, endNumber: lines.endLine, startNumber: lines.startLine })
  }

  return false
}

export const isCellMarkedByHash = async (info: CellContext<FccRow, FccRow>, cellNumber: number | null, side: Side) => {
  const hash = window.location.hash.slice(1)

  if (hash && hash.startsWith("line")) {
    const decodedFilePath = await getSHA256Hash(info.table.options.meta?.filePath || "")

    return `line-${decodedFilePath}-${side[0]}${cellNumber}` === hash
  }
  return false
}
