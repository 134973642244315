import { useMemo } from "react"
import { CommentedUser } from "@/components/designSystem/Discussions/components/ChipDiscussion"
import { Avatar } from "@/components"
import { NumberChip } from "@/components/designSystem/Discussions/components/ChipDiscussion/NumberChip"

interface Props {
  avatars: CommentedUser[]
  color: AvatarColor
}

export type AvatarColor = "green" | "yellow" | "gray"

const colorsMap = {
  green: {
    bg: "bg-success-normal",
    border: "border-success-normal"
  },
  yellow: {
    bg: "bg-yellow-300",
    border: "border-yellow-300"
  },
  gray: {
    bg: "bg-hint",
    border: "border-hint"
  }
} as const

export function CombinedAvatarChip({ avatars, color }: Props) {
  const slicedAvatars = useMemo(() => {
    return avatars.slice(0, avatars.length <= 3 ? 3 : 2)
  }, [avatars])

  return (
    <div
      className={`flex -space-x-1 overflow-hidden rounded-2xl border-2 ${colorsMap[color].border} ${colorsMap[color].bg}`}
    >
      {slicedAvatars?.map((avatar) => (
        <Avatar
          key={avatar.name + avatar.imageSrc}
          username={avatar.name}
          avatarURL={avatar.imageSrc}
          className={`inline-block size-5 rounded-full ${colorsMap[color].border}`}
        />
      ))}
      {avatars.length > 3 ? (
        <NumberChip className={`inline-block size-5 rounded-full border-white`} number={avatars.length - 2} />
      ) : null}
    </div>
  )
}
