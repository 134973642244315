import { Comment } from "@/models/Discussions"
import { formatName } from "@/lib/utils"
import { relativeTime } from "@/lib/dateFormats"
import { Avatar } from "@/components"
import { useTranslation } from "react-i18next"
import { MessageCircle } from "lucide-react"
import { convert } from "html-to-text"
import { CommentedUser } from "@/components/designSystem/Discussions/components/ChipDiscussion/index"
import { AvatarColor, CombinedAvatarChip } from "@/components/designSystem/Avatar/CombinedAvatarChip"

interface Props {
  comment: Comment
  isResolved: boolean
  lastComment: Comment
  commentedUsers: CommentedUser[]
  avatarColor: AvatarColor
}

export const TooltipComment = ({ comment, isResolved, lastComment, commentedUsers, avatarColor }: Props) => {
  const { t } = useTranslation("Discussions")

  const slicedComment = comment.comment_body.split(" ").slice(0, 10).join(" ")

  return (
    <section className="px-3 py-2">
      <div className="mb-4 flex w-full">
        <MessageCircle size={16} className="pr-1 text-primary" />
        <p className="text-xs text-secondary">{`${t("ThreadFrom")} ${relativeTime(comment.created_at)}`}</p>
      </div>
      <div className="flex w-full border-b border-border pb-2">
        <div className="w-8">
          <Avatar
            username={comment.author_user?.display_name || comment.author}
            avatarURL={comment.author_user?.avatar_url}
            className="mb-1 mr-4 size-6 cursor-default text-sm"
          />
        </div>
        <div>
          <h3 className="mb-1 text-sm font-semibold text-primary">
            {t("ThreadBy")} {formatName(lastComment.author_user?.display_name || comment.author)}
          </h3>
          <p className="mb-1 text-sm text-secondary">{isResolved ? "Resolved Thread" : "Open Thread"}</p>
          <p className="text-sm text-primary">{convert(slicedComment) + "..."}</p>
        </div>
      </div>
      <div className="my-2 ml-8">
        <p className="mb-1">
          Last comment by {formatName(lastComment.author_user?.display_name || lastComment.author)}
        </p>
        <p className="text-xs text-secondary">{relativeTime(comment.created_at)}</p>
      </div>
      <div className="ml-8 flex w-fit">
        <CombinedAvatarChip avatars={commentedUsers} color={avatarColor} />
      </div>
    </section>
  )
}
