import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ArrowDown10, Folders, X } from "lucide-react"
import { AnimatePresence, motion } from "framer-motion"
import {
  Button,
  Tabs,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { diffSorter } from "@/components/designSystem/Filters/createFilters"
import { SorterFilter } from "@/components/designSystem/Filters/variations/SorterFilter"
import { Topics } from "src/components/designSystem/Topics"
import { SectionIcon } from "@radix-ui/react-icons"
import { useSettings } from "@/services/store/useSettings"
import MermaidChart from "@/components/designSystem/MermaidChart/index.tsx"
import { buildCompleteChart, themeConfig } from "@/lib/mermaidCharts"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { extractMermaidContent, PAGE_CONTAINER_PADDING } from "@/pages/crFileChanges/utils"
import Description from "@/components/designSystem/Description"
import { extractComments } from "@/pages/crFileChanges/components/CRHeader/ReviewersSection/utils"
import { IconTooltip } from "@/components/designSystem/Tooltips/IconTooltip"

interface Props {
  onOpen: () => void
  isFoldersTreeOpen: boolean
  areFilesSorted: boolean
  hasReachedTarget: boolean
}

const sorters = [diffSorter({})]

type TabType = "graph" | "description"

export const CRFilters = ({ isFoldersTreeOpen, onOpen, areFilesSorted, hasReachedTarget }: Props) => {
  const { t } = useTranslation("FCC")
  const { selectedTheme } = useSettings()
  const currentTheme = themeConfig[selectedTheme]

  const {
    changeRequest,
    isDescriptionOpen,
    flowChartData,
    setFlowChartData,
    setIsDescriptionOpen,
    userClosedManually,
    setUserClosedManually
  } = useChangeRequest()

  const [chosenTab, setChosenTab] = useState<TabType | null>(null)
  const [isDescriptionTooltipHovered, setIsDescriptionTooltipHovered] = useState(false)
  const [isGraphTooltipHovered, setIsGraphTooltipHovered] = useState(false)

  const comments = useMemo(
    () => (changeRequest ? extractComments(changeRequest.change_level_discussions) : []),
    [changeRequest]
  )

  useEffect(() => {
    if (!changeRequest?.description) {
      // because the mermaid is extracted from the description
      return
    }

    const mermaidContent = extractMermaidContent(changeRequest.description)
    setFlowChartData(mermaidContent ? buildCompleteChart(mermaidContent, currentTheme.mermaidTheme) : null)

    if (mermaidContent) {
      setChosenTab("graph")
    } else {
      setChosenTab("description")
    }
  }, [changeRequest, currentTheme.mermaidTheme, setFlowChartData])

  useEffect(() => {
    if (hasReachedTarget && isDescriptionOpen) {
      setIsDescriptionOpen(false)
      setUserClosedManually(false)
    }

    if (!hasReachedTarget && !isDescriptionOpen && !userClosedManually) {
      const timer = setTimeout(() => {
        setIsDescriptionOpen(true)
      }, 150)

      return () => clearTimeout(timer)
    }
  }, [
    flowChartData,
    hasReachedTarget,
    isDescriptionOpen,
    setIsDescriptionOpen,
    setUserClosedManually,
    userClosedManually
  ])

  const handleClose = () => {
    setIsDescriptionOpen(false)
    setUserClosedManually(true)
    setChosenTab(flowChartData ? "graph" : "description")
  }

  const handleTooltipHover = (setHoverState: (state: boolean) => void) => {
    return {
      onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) =>
        setHoverState(e.currentTarget.querySelector("[disabled]") !== null),
      onMouseLeave: () => setHoverState(false)
    }
  }

  return (
    <TooltipProvider delayDuration={1000}>
      <div
        data-id="cr-filters"
        className={`flex ${PAGE_CONTAINER_PADDING} ${isDescriptionOpen ? "flex-col" : ""} border-y border-border bg-background py-4`}
      >
        <div className="flex items-center">
          {!isFoldersTreeOpen && (
            <Button className="mr-3 size-8" size="icon" variant="outline" onClick={onOpen}>
              <Folders width={16} height={16} />
            </Button>
          )}
          {isDescriptionOpen && (
            <div className="flex w-full justify-between">
              {chosenTab && (
                <Tabs defaultValue={chosenTab ?? "description"} className="h-full w-[200px]">
                  <TabsList className="grid grid-cols-2 rounded-md border border-border bg-background">
                    <Tooltip open={isGraphTooltipHovered}>
                      <TooltipTrigger asChild>
                        <div {...handleTooltipHover(setIsGraphTooltipHovered)}>
                          <TabsTrigger
                            value="graph"
                            className="h-[26px] w-full rounded-b text-xs font-semibold text-primary data-[state=active]:bg-border data-[state=active]:shadow-sm"
                            disabled={!flowChartData}
                            onClick={() => setChosenTab("graph")}
                          >
                            {t("Graph")}
                          </TabsTrigger>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-2xl">{t("DisabledGraph")}</TooltipContent>
                    </Tooltip>

                    <Tooltip open={isDescriptionTooltipHovered}>
                      <TooltipTrigger asChild>
                        <div {...handleTooltipHover(setIsDescriptionTooltipHovered)}>
                          <TabsTrigger
                            value="description"
                            onClick={() => setChosenTab("description")}
                            className="h-[26px] w-full rounded-b text-xs font-semibold text-primary data-[state=active]:bg-border data-[state=active]:shadow-sm"
                          >
                            {t("Description")}
                          </TabsTrigger>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-2xl">{t("DisabledDescription")}</TooltipContent>
                    </Tooltip>
                  </TabsList>
                </Tabs>
              )}
              <Button variant="outline" onClick={handleClose} className="size-8 p-2">
                <X width={16} height={16} className="cursor-pointer" />
              </Button>
            </div>
          )}
        </div>

        <AnimatePresence mode="wait">
          {isDescriptionOpen && (
            <motion.div
              key="mermaid-chart"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              className="w-full overflow-hidden"
            >
              {changeRequest && chosenTab === "graph" ? (
                <MermaidChart
                  flowChartData={flowChartData ? flowChartData : ""}
                  currentTheme={currentTheme}
                  changeRequest={changeRequest}
                />
              ) : (
                changeRequest && (
                  <Description markdownDescription={changeRequest.description} commentsLength={comments.length} />
                )
              )}
            </motion.div>
          )}
          <div className="flex w-full justify-between">
            <Topics minimized={false} />
            <div className="flex h-fit gap-2">
              {areFilesSorted && (
                <div className="flex">
                  <IconTooltip icon={<ArrowDown10 size={16} />} content={t("SortedByApplicationFlow")} />
                </div>
              )}
              {sorters.map((sorter) => (
                <SorterFilter
                  key={sorter.label}
                  sorter={sorter}
                  align="end"
                  Icon={<SectionIcon className="mr-1" />}
                  withTooltip={true}
                  mainTooltip={t("ChangeDiffView")}
                />
              ))}
            </div>
          </div>
        </AnimatePresence>
      </div>
    </TooltipProvider>
  )
}
