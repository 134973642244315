export const FCC = {
  Viewed: "Viewed",
  Services: "Services",
  Checks: "Checks",
  Data: "Data",
  ChangedFiles: "Changed Files",
  ExpandAll: "Expand All",
  CollapseAll: "Collapse All",
  ExpandUp: "Expand Up",
  ExpandDown: "Expand Down",
  Expand: "Expand",
  Binary: "Binary file not shown.",
  SortedByApplicationFlow: "Sorted by application flow",
  Lock: "Generated files are not rendered by default.",
  Empty: "Empty content.",
  Removed: "Removed file.",
  Renamed: "Renamed file.",
  ChangeStatus: "Change Status",
  SetAs: "Set as",
  LoadDiff: "Load diff",
  TooLong: "Very large diffs are not rendered by default.",
  Formatting: "Files with problematic formatting are not rendered.",
  FilesChanged: "Files Changed",
  ChangeDiffView: "Change Diff View",
  Graph: "Graph",
  Description: "Description",
  CRComments: "CR Comments",
  CRComment: "CR Comment",
  GeneratedDescription: "Generated description",
  UserDescription: "User description",
  DisabledDescription: "Covered by topic breakdown",
  DisabledGraph: "No flow available for this type of change request yet, coming soon!",
  DisabledUserDescriptionEditing: "CR description can not be edited in this status",
  Comments: "Comments",
  Comment: "Comment",
  EditDescription: "Edit description",
  AddDescription: "Add description"
}
