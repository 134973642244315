import { RedundantRun } from "@/models/RedundantRuns"
import GithubSVG from "@/assets/icons/GithubSVG"
import { TruncatedTextWithTooltip } from "@/components/designSystem/Tooltips/TruncatedTextWithTooltip"

export const JobNameCell = ({ row }: { row: RedundantRun }) => {
  return (
    <a href={row.jobLink} target="_blank" rel="noopener noreferrer">
      <div className="flex items-center text-primary">
        <div className="mr-2 rounded-md border border-border p-2 text-secondary">
          <GithubSVG />
        </div>

        <TruncatedTextWithTooltip
          tooltipTrigger={row.jobName}
          tooltipContent={row.jobName}
          triggerClassName="max-w-[600px]"
          contentClassName="max-w-2xl"
        />
      </div>
    </a>
  )
}
