import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { ChangeRequest } from "@/models/Changes.ts"
import { CRCommentsController } from "./CRCommentsController.tsx"
import { ChecksController } from "./ChecksController.tsx"
import { GitFork } from "lucide-react"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { TooltipPortal } from "@radix-ui/react-tooltip"

interface Props {
  changeRequest: ChangeRequest
}

export const InfoActions = ({ changeRequest }: Props) => {
  const { isDescriptionOpen, setIsDescriptionOpen, flowChartData, userClosedManually, setUserClosedManually } =
    useChangeRequest()
  const [isTooltipHovered, setIsTooltipHovered] = useState(false)
  const { t } = useTranslation("FCC")

  const handleDescriptionToggle = useCallback(() => {
    setUserClosedManually(!userClosedManually)
    setIsDescriptionOpen(!isDescriptionOpen)
  }, [userClosedManually, isDescriptionOpen, setUserClosedManually, setIsDescriptionOpen])

  return (
    <div className="flex items-center gap-2">
      <TooltipProvider>
        <CRCommentsController changeRequest={changeRequest} />
        <ChecksController ciChecks={changeRequest.runs} />
        <Tooltip open={isTooltipHovered}>
          <TooltipTrigger asChild>
            <div onMouseEnter={() => setIsTooltipHovered(true)} onMouseLeave={() => setIsTooltipHovered(false)}>
              <Button
                variant={isDescriptionOpen ? "secondary" : "outline"}
                onClick={handleDescriptionToggle}
                className={`size-8 rotate-90 p-2 ${isDescriptionOpen ? "bg-fill" : "bg-background"}`}
              >
                <GitFork size={16} />
              </Button>
            </div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent sideOffset={10}>{flowChartData === null ? t("DisabledGraph") : t("Graph")}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
