import { apiRoutes } from "@/services/api/api-routes.ts"
import { axiosClient } from "@/services/api/axiosClient.ts"
import { useMutation } from "@tanstack/react-query"
import { ExcludedConfig } from "@/models/ReviewerConfig.ts"

export const useUpdateReviewerExcludedConfiguration = () => {
  const { mutateAsync, status } = useMutation({
    mutationKey: ["useUpdateReviewerExcludedConfiguration"],
    mutationFn: (config: ExcludedConfig) => {
      const formattedConfig = {
        excludedPaths: config.excludedPaths.split(","),
        fullRepoName: config.fullRepoName
      }

      return axiosClient.put(apiRoutes.reviewerConfig.excluded.put, formattedConfig)
    }
  })

  return { mutateAsync, status }
}
