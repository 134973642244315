/**
 * Utilities for processing and formatting description content
 */

export const removeBazReviewSentence = (content: string): string => {
  if (!content) return ""

  // Remove "This pull request is reviewed by Baz" sentence
  const result = content.replace(/\b[^.!?]*This pull request is reviewed by Baz[^.!?]*\./gi, "")

  return result.trim()
}

/**
 * Removes the "Join team on Baz" sentence from content
 * @param content - The markdown content to process
 * @returns Content without the Baz join sentence
 */
export const removeJoinBazSentence = (content: string): string => {
  if (!content) return ""

  // This pattern looks for sentences containing "Join" and "team on" with an <a> tag to Baz
  const bazJoinRegex = /\b[^.!?]*Join[^.!?]*team on\s*<a[^>]*>[^<]*<\/a>\s*\./gi

  // In case the closing tag is missing or malformed
  const bazJoinRegexAlt = /\b[^.!?]*Join[^.!?]*team on\s*<a[^>]*>[^.!?]*\./gi

  // Try the first pattern, then fall back to the alternative if no match
  let result = content.replace(bazJoinRegex, "")

  // If no replacement was made, try the alternate pattern
  if (result === content) {
    result = content.replace(bazJoinRegexAlt, "")
  }

  return result.trim()
}

/**
 * Removes tables and mermaid code blocks from content
 * @param content - The markdown content to process
 * @returns Filtered content without tables and mermaid blocks
 */
export const removeTablesAndMermaid = (content: string): string => {
  if (!content) return ""

  // Check if the table is malformed (missing closing tag)
  if (/<table[^>]*>(?![\s\S]*?<\/table>)/i.test(content)) {
    return content
  }

  // First, remove mermaid blocks
  const filtered = content.replace(/```mermaid\s+([\s\S]*?)```/g, "")

  // Use a more sophisticated approach for tables
  let inTable = false
  let tableDepth = 0
  let result = ""

  for (let i = 0; i < filtered.length; i++) {
    // Check for table opening tag
    if (filtered.substring(i).toLowerCase().startsWith("<table")) {
      const endTagIndex = filtered.indexOf(">", i)
      if (endTagIndex !== -1) {
        inTable = true
        tableDepth++
        i = endTagIndex // Skip to end of opening tag
        continue
      }
    }

    // Check for table closing tag
    if (filtered.substring(i).toLowerCase().startsWith("</table")) {
      const endTagIndex = filtered.indexOf(">", i)
      if (endTagIndex !== -1) {
        tableDepth--
        if (tableDepth === 0) {
          inTable = false
        }
        i = endTagIndex
        continue
      }
    }

    // Add characters only when not inside a table
    if (!inTable) {
      result += filtered.charAt(i)
    }
  }

  // If result is only whitespace, return empty string
  if (!/\S/.test(result)) {
    return ""
  }

  return result
}

/**
 * Interface for parsed description sections
 */
interface ParsedDescription {
  userDescription: string
  generatedDescription: string
}

export const cleanupHTML = (content: string): string => {
  if (!content) return ""

  // Replace any incomplete HTML tags at the end of content
  // This regex looks for a < that isn't followed by a > before the end of the string
  return content.replace(/<[^>]*$/, "")
}

/**
 * Parses markdown description into user and generated sections
 * @param markdownDescription - The full markdown description
 * @returns Object containing separate user and generated descriptions
 */
export const parseDescription = (markdownDescription: string): ParsedDescription => {
  const result: ParsedDescription = {
    userDescription: "",
    generatedDescription: ""
  }

  // Handle empty input
  if (!markdownDescription?.trim()) {
    return result
  }

  const WithoutMermaidAndTable = removeTablesAndMermaid(markdownDescription)
  const WithoutJoinBazMarkdown = removeJoinBazSentence(WithoutMermaidAndTable)
  const cleanedMarkdown = removeBazReviewSentence(WithoutJoinBazMarkdown)
  const finalContent = cleanupHTML(cleanedMarkdown)

  const trimmedContent = finalContent.trim()

  // Extract generated description first
  const generatedMarker = "# Generated description"
  const hasGeneratedPart = trimmedContent.includes(generatedMarker)

  if (hasGeneratedPart) {
    const [beforeGenerated, afterGenerated] = trimmedContent.split(generatedMarker, 2)
    result.generatedDescription = afterGenerated.trim()

    // Process the content before the generated marker
    const contentToProcess = beforeGenerated.trim()
    extractUserDescription(contentToProcess, result)
  } else {
    // No generated marker, process entire content
    extractUserDescription(trimmedContent, result)
  }

  return result
}

const extractUserDescription = (content: string, result: ParsedDescription): void => {
  const userMarker = "# User description"

  if (content.includes(userMarker)) {
    const [_, afterUserMarker] = content.split(userMarker, 2) // Using _ for unused value
    result.userDescription = afterUserMarker ? afterUserMarker.trim() : ""
  } else {
    // No user marker, use entire content
    result.userDescription = content
  }
}
