import { CellContext } from "@tanstack/react-table"
import { FccRow, Side } from "@/models/FCC"
import { useEffect, useState } from "react"
import { isCellMarkedByHash } from "@/components/designSystem/FCC/utils/codeCellComputations"

export const useHashMarkedCell = (info: CellContext<FccRow, FccRow>, cellNumber: number | null, columnSide: Side) => {
  const [isMarkedByHash, setIsMarkedByHash] = useState(false)

  useEffect(() => {
    isCellMarkedByHash(info, cellNumber, columnSide).then((res) => setIsMarkedByHash(res))
  }, [info, cellNumber, columnSide])

  return { isMarkedByHash }
}
