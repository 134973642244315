import { errorHandler } from "@/services/api/helpers"

interface Props {
  error: unknown
}

export const Error = ({ error }: Props) => {
  return (
    <div className="flex border-r py-6 pl-12 pr-0">
      <p>{errorHandler(error)}</p>
    </div>
  )
}
