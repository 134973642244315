import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { Clock } from "lucide-react"
import { localeDateTime, relativeTime } from "@/lib/dateFormats.ts"
import { useTranslation } from "react-i18next"

export const Timestamp = ({ time }: { time: string }) => {
  const { t } = useTranslation("Common")
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <p className="mt-1 flex cursor-default text-xs text-secondary">
            <Clock width={14} height={14} className="mr-2" />
            {t("Updated")} {relativeTime(time)}
          </p>
        </TooltipTrigger>
        <TooltipContent>{localeDateTime(time)}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
