import { formatDate } from "@/lib/dateFormats"
import { Comment as CommentI, Discussion } from "@/models/Discussions"
import { Comment } from "../Comment"
import { useTranslation } from "react-i18next"
import { useDiscussions } from "@/services/store/useDiscussions"
import { buildDiscussionLink, commentOnLinesFormat, isCommentSuggestion } from "../../utils"
import { Suggestion } from "../SuggestionV2"
import { Button } from "@/components"
import { Link2 } from "lucide-react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useCallback } from "react"
import { useToast } from "@/hooks/useToast"
import { ResolveController } from "@/components/designSystem/Discussions/components/Comment/ResolveController"

interface Props {
  comments: CommentI[]
  discussion: Discussion
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
  id: string
}

export const DiscussionBody = ({ comments, discussion, handleResolveToggle, isResolved, id }: Props) => {
  const { t: commonTranslation } = useTranslation("Common")

  const { clearCurrentHoveredDiscussion, setCurrentHoveredDiscussion } = useDiscussions()
  const { toast } = useToast()

  const onCopyClick = useCallback(() => {
    toast({
      title: commonTranslation("CopiedLink")
    })
  }, [commonTranslation, toast])

  return (
    <div
      className="overflow-x-hidden text-left"
      onMouseEnter={() => setCurrentHoveredDiscussion(discussion)}
      onMouseLeave={() => clearCurrentHoveredDiscussion()}
    >
      <div className="mb-2 flex size-full items-center justify-between">
        <p className="text-xxs text-secondary">
          {commentOnLinesFormat(discussion)}
          {formatDate(discussion.created_at)}
        </p>
        <div className="flex">
          <ResolveController
            discussion={discussion}
            handleResolveToggle={handleResolveToggle}
            isResolved={isResolved}
          />
          <CopyToClipboard text={buildDiscussionLink(id)} onCopy={onCopyClick}>
            <Button variant="outline" className="h-fit p-2">
              <Link2 size={16} />
            </Button>
          </CopyToClipboard>
        </div>
      </div>

      {comments.map((comment) =>
        isCommentSuggestion(comment) ? (
          <Suggestion
            key={comment.id}
            comment={comment}
            current={discussion}
            handleResolveToggle={handleResolveToggle}
            isResolved={isResolved}
          />
        ) : (
          <Comment
            key={comment.id}
            comment={comment}
            current={discussion}
            handleResolveToggle={handleResolveToggle}
            isResolved={isResolved}
          />
        )
      )}
    </div>
  )
}
