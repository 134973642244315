import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { MoreHorizontal } from "lucide-react"
import { useTranslation } from "react-i18next"
import { FindingConfig } from "@/models/ReviewerConfig"
import { ReviewerType } from "@/pages/settings/reviewer/ReviewerTypes"

interface Props {
  handleClick: (item: FindingConfig) => void
  item: ReviewerType & { enabled: boolean }
  disabled: boolean
}

export const ConfigItem = ({ item, disabled, handleClick }: Props) => {
  const { t } = useTranslation("Reviewer")

  return (
    <div
      className={`w-[calc(50%-8px)] rounded-md border border-border p-4 ${item.isConfigurable && !disabled ? "" : "bg-fill"}`}
    >
      <div className="mb-4 flex flex-row justify-between ">
        <div
          className={`flex size-8 items-center justify-center rounded bg-link ${item.isConfigurable && !disabled ? "" : "opacity-65"}`}
        >
          <item.icon className="size-4 text-white" />
        </div>
        {item.enabled ? (
          <div className="flex items-center justify-between">
            {item.isConfigurable ? (
              <div className="ml-2">
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <DropdownMenu modal={false} open={!disabled ? undefined : false}>
                          <DropdownMenuTrigger
                            className={`p-1 ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
                            disabled={disabled}
                          >
                            <MoreHorizontal className="size-4 hover:text-secondary" />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              onClick={() => handleClick(item)}
                              className="flex items-center gap-2 text-error-dark"
                            >
                              {t("Deactivate")}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </TooltipTrigger>
                    {disabled && (
                      <TooltipContent align="end" className="-translate-x-1 text-xs">
                        <p>{t("ConfigurationRestriction")}</p>
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              </div>
            ) : null}
          </div>
        ) : (
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <span>
                  <Button
                    disabled={disabled}
                    variant="outline"
                    className="h-[32px] px-2.5 text-xs font-normal"
                    onClick={() => handleClick(item)}
                  >
                    {t("Activate")}
                  </Button>
                </span>
              </TooltipTrigger>
              {disabled && (
                <TooltipContent align="end" className="text-xs" sideOffset={5}>
                  <p>{t("ConfigurationRestriction")}</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <h5 className="text-s font-medium text-primary">{item.display}</h5>
      <div className="text-xs font-normal text-secondary">{item.description}</div>
    </div>
  )
}
