import { NavLink } from "react-router-dom"
import BazIconLight from "@/assets/icons/BazAvatar-light.svg?react"
import BazIconDark from "@/assets/icons/BazAvatar-dark.svg?react"
import { routes, RouteType } from "@/lib/routes.ts"
import { SVGProps, useMemo } from "react"
import { useSettings } from "@/services/store/useSettings.ts"
import { NavLinkButton } from "@/pages/root/TopHeader/NavbarButton"

type NavLink = {
  href: RouteType
  Icon?: React.ComponentType<SVGProps<SVGSVGElement>>
  key?: string
  label?: string
}

export const Navbar = () => {
  const { selectedTheme } = useSettings()

  const links = useMemo(() => {
    const baseLinks: NavLink[] = [
      {
        href: routes.root,
        Icon: selectedTheme === "light" ? BazIconLight : BazIconDark,
        key: "baz"
      },
      {
        href: routes.code.root,
        key: "main",
        label: "Code"
      },
      {
        href: routes.changes.root,
        label: "Changes"
      },
      {
        href: routes.runs.root,
        label: "Runs"
      }
    ]

    return baseLinks
  }, [selectedTheme])

  return (
    <div className="flex h-full gap-2">
      {links.map(({ href, Icon, key, label }) => (
        <div key={key || href} className="flex items-center">
          {Icon ? <Icon width={32} height={32} className="mr-6" /> : null}
          {label && <NavLinkButton to={href} label={label} showBadge={label === "Runs"} badgeText="Alpha" />}
        </div>
      ))}
    </div>
  )
}
