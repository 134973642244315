const msInHour = 1000 * 60 * 60
const msInDay = msInHour * 24
const msInWeek = msInDay * 7
const msInMonth = msInDay * 30
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export function formatDate(inputDate?: string): string {
  if (!inputDate) {
    return ""
  }

  const date = convertToUtcLocalDate(inputDate)
  const month = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month} ${day}, ${year}`
}

export function formatTime(inputTime: string): string {
  const date = convertToUtcLocalDate(inputTime)

  let hours = date.getHours()
  const minutes = date.getMinutes()
  const period = hours >= 12 ? " PM" : " AM"

  hours = hours % 12 || 12

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  return `${hours}:${formattedMinutes}${period}`
}

export function relativeTime(timeString: string): string {
  const currentTime = new Date()
  const targetTime = convertToUtcLocalDate(timeString)
  const differenceInSeconds = Math.floor((currentTime.getTime() - targetTime.getTime()) / 1000)

  if (differenceInSeconds < 60) {
    return "Just now"
  } else if (differenceInSeconds < 3600) {
    const minutes = Math.floor(differenceInSeconds / 60)
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`
  } else if (differenceInSeconds < 86400) {
    const hours = Math.floor(differenceInSeconds / 3600)
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`
  } else if (differenceInSeconds < 2592000) {
    const days = Math.floor(differenceInSeconds / 86400)
    return `${days} day${days !== 1 ? "s" : ""} ago`
  } else if (differenceInSeconds < 31536000) {
    const months = Math.floor(differenceInSeconds / 2592000)
    return `${months} month${months !== 1 ? "s" : ""} ago`
  }
  const years = Math.floor(differenceInSeconds / 31536000)
  return `${years} year${years !== 1 ? "s" : ""} ago`
}

export function localeDateTime(timeString: string): string {
  return new Date(timeString).toLocaleString(undefined, {
    year: "numeric",
    day: "numeric",
    month: "long",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZoneName: "short"
  })
}

export function convertToUtcLocalDate(dateString: string): Date {
  const utcDate = new Date(dateString)
  return new Date(
    Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes())
  )
}

export function timeElapsedSince(timeString: string): string {
  const now = new Date()
  const targetTime = convertToUtcLocalDate(timeString)
  const diffMs = now.getTime() - targetTime.getTime()

  if (diffMs < msInHour) {
    return "less than an hour"
  }

  const elapsedMonths = Math.floor(diffMs / msInMonth)
  const elapsedWeeks = Math.floor((diffMs % msInMonth) / msInWeek)
  const elapsedDays = Math.floor((diffMs % msInWeek) / msInDay)
  const elapsedHours = Math.floor((diffMs % msInDay) / msInHour)

  const parts = []
  if (elapsedMonths > 0) parts.push(`${elapsedMonths} month${elapsedMonths !== 1 ? "s" : ""}`)
  if (elapsedWeeks > 0) parts.push(`${elapsedWeeks} week${elapsedWeeks !== 1 ? "s" : ""}`)
  if (elapsedDays > 0) parts.push(`${elapsedDays} day${elapsedDays !== 1 ? "s" : ""}`)
  if (elapsedHours > 0) parts.push(`${elapsedHours} hour${elapsedHours !== 1 ? "s" : ""}`)

  if (parts.length > 1) {
    const lastPart = parts.pop()
    return `${parts.join(", ")} and ${lastPart}`
  }
  return parts[0]
}

export const formatDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  const remainingSeconds = seconds % 60

  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`
  }
  if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`
  }
  return `${seconds}s`
}
