import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { TooltipPortal } from "@radix-ui/react-tooltip"
import { MessageSquare } from "lucide-react"
import { Button, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { ChangeRequest } from "@/models/Changes.ts"
import { CRCommentsDialog } from "./CRCommentsDialog.tsx"
import { useChangeRequest } from "@/services/store/useChangeRequest"

interface Props {
  changeRequest: ChangeRequest
}

export const CRCommentsController = ({ changeRequest }: Props) => {
  const { t } = useTranslation("FCC")

  const { isCRCommentsDialogOpen, setIsCRCommentsDialogOpen } = useChangeRequest()

  const handleDescriptionTriggerClick = useCallback(() => {
    setIsCRCommentsDialogOpen(!isCRCommentsDialogOpen)
  }, [isCRCommentsDialogOpen, setIsCRCommentsDialogOpen])

  const handleDescriptionClose = useCallback(() => {
    setIsCRCommentsDialogOpen(false)
  }, [setIsCRCommentsDialogOpen])

  return (
    <Tooltip>
      <TooltipTrigger>
        <CRCommentsDialog
          open={isCRCommentsDialogOpen}
          onClose={handleDescriptionClose}
          onTriggerClick={handleDescriptionTriggerClick}
          changeRequest={changeRequest}
        >
          <Button variant="outline" className="size-8 p-0">
            <MessageSquare size={16} />
          </Button>
        </CRCommentsDialog>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent sideOffset={10}>{t("CRComments")}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}
