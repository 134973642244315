import { MermaidConfig } from "mermaid"

export type MermaidThemes = "dark" | "default"

export interface ThemeConfig {
  mermaidTheme: MermaidThemes
  lineColor: string
  edgeLabelColor: string
  edgeLabelBackground: string
  backgroundImage: string
  arrowTextColor: string
  chartNodeShadow: string
}

// Interface for the placeholder values
interface ChartPlaceholders {
  baseProperties: string
  fillColor: string
  strokeColor: string
  linkStrokeColor: string
  yellowStroke: string
  redStroke: string
  greenStroke: string
  baseStyle: string
}

export const themeConfig: Record<"dark" | "light", ThemeConfig> = {
  dark: {
    mermaidTheme: "dark",
    lineColor: "#334155",
    edgeLabelColor: "#94A3B8",
    edgeLabelBackground: "#1C2026",
    backgroundImage: "/MermaidDark.svg",
    arrowTextColor: "#CBD5E1",
    chartNodeShadow: "unset"
  },
  light: {
    mermaidTheme: "default",
    lineColor: "#CBD5E1",
    edgeLabelColor: "#64748B",
    edgeLabelBackground: "white",
    backgroundImage: "/MermaidLight.svg",
    arrowTextColor: "#64748B",
    chartNodeShadow: "drop-shadow(0px 0px 8px rgba(100, 116, 139, 0.15))"
  }
}

export const getMermaidConfig = (currentTheme: ThemeConfig): MermaidConfig => {
  return {
    startOnLoad: false,
    securityLevel: "strict",
    theme: currentTheme.mermaidTheme,
    flowchart: {},
    themeVariables: {
      edgeLabelBackground: "transparent",
      primaryColor: "#CBD5E1",
      lineColor: currentTheme.lineColor,
      primaryTextColor: "#334155"
    }
  }
}

export const getMermaidStyles = (currentTheme: ThemeConfig) => `
  .edgeLabel div {
    color: ${currentTheme.edgeLabelColor} !important;
    background: ${currentTheme.edgeLabelBackground} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .edgeLabel span {
    color: ${currentTheme.arrowTextColor} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* Remove max-width constraint on SVG */
  #flowchart-container svg {
    max-width: none !important;
    width: 100% !important;
    height: 100% !important;
  }
  /* Prevent text selection */
  #flowchart-container {
    user-select: none !important;
    -webkit-user-select: none !important;
  }
  /* Style node rectangles */
  #flowchart-container .node rect {
    filter: ${currentTheme.chartNodeShadow} !important;
  }
`

export const CHART_CLASS_DEFINITIONS_TEMPLATE = `
  classDef default {{baseStyle}}
  classDef commonStyle {{baseStyle}}
  classDef modified commonStyle,fill:{{fillColor}},stroke:{{yellowStroke}}
  classDef removed commonStyle,fill:{{fillColor}},stroke:{{redStroke}}
  classDef added commonStyle,fill:{{fillColor}},stroke:{{greenStroke}}
  
  linkStyle default stroke:{{linkStrokeColor}},font-size:9px
`

export const MOCK_CHART_CONTENT = `
  graph LR
    
    User(User):::modified -- Login validate --> B(B):::added
    B(B):::added -- Validate user --> C(C):::removed
    User(User):::modified -- Access resources --> D(D)
    D(D) -- Check authorization --> E(E):::removed
  
    classDef added fill:green
    classDef removed fill:red
    classDef modified fill:yellow
  `

// Function to replace placeholders in the template
export const getChartData = (templateString: string, placeholders: ChartPlaceholders): string => {
  let result = templateString

  // Replace each placeholder with its value
  Object.entries(placeholders).forEach(([key, value]) => {
    const placeholder = `{{${key}}}`
    result = result.replace(new RegExp(placeholder, "g"), value)
  })

  return result
}

// Function to generate placeholders based on theme
export const generateChartPlaceholders = (theme: MermaidThemes): ChartPlaceholders => {
  const colors = {
    fill: theme === "dark" ? "#262B33" : "white",
    stroke: theme === "dark" ? "#334155" : "#E8ECF2",
    link: theme === "dark" ? "#334155" : "#CBD5E1",
    yellow: theme === "dark" ? "#EDAC4C85" : "#EDAC4C",
    red: theme === "dark" ? "#CD527085" : "#CD5270",
    green: theme === "dark" ? "#15AA7A85" : "#15AA7A"
  }

  const baseProperties = "font-weight:500,font-size:10px,stroke-width:2px"
  const baseStyle = `${baseProperties},fill:${colors.fill},stroke:${colors.stroke}`

  return {
    baseProperties,
    fillColor: colors.fill,
    strokeColor: colors.stroke,
    baseStyle,
    linkStrokeColor: colors.link,
    yellowStroke: colors.yellow,
    redStroke: colors.red,
    greenStroke: colors.green
  }
}

export const buildCompleteChart = (chartContent: string, theme: MermaidThemes): string => {
  const completeTemplate = `${chartContent}\n\n${CHART_CLASS_DEFINITIONS_TEMPLATE}`
  const placeholders = generateChartPlaceholders(theme)
  return getChartData(completeTemplate, placeholders)
}
