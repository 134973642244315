import { Comment, Discussion, DiscussionState, NewCommentBubble } from "@/models/Discussions"
import { getCurrentURLWithoutHash } from "@/lib/urls"

interface MapValue {
  index: number
  discussions: Array<Discussion>
}

type FilesMap = Record<string, MapValue>

export const sortDiscussionsBasedOnFileOrder = (discussions: Discussion[], filePaths: string[]): Discussion[] => {
  const filesMap: FilesMap = convertDiscussionsToFilesMap(discussions, filePaths)
  const sortedFilesMap = sortDiscussionsByLines(filesMap)
  return convertSortedMapToArray(sortedFilesMap)
}

const convertDiscussionsToFilesMap = (discussions: Discussion[], files: string[]) => {
  const results: FilesMap = {}

  files.forEach((file, index) => (results[file] = { index, discussions: [] }))
  discussions.forEach((discussion) => {
    results[discussion.file]?.discussions.push(discussion)
  })

  return results
}

const sortDiscussionsByLines = (data: FilesMap) => {
  const sortedData: FilesMap = {}

  for (const [fileName, fileData] of Object.entries(data)) {
    const sortedDiscussions = fileData.discussions.sort((a, b) => {
      return (a.end_line || 1) - (b.end_line || 1)
    })

    sortedData[fileName] = {
      ...fileData,
      discussions: sortedDiscussions
    }
  }

  return sortedData
}

const convertSortedMapToArray = (sortedData: FilesMap) => {
  const fileArray: {
    index: number
    discussions: Array<Discussion>
    fileName: string
  }[] = []

  for (const [fileName, fileData] of Object.entries(sortedData)) {
    fileArray.push({
      fileName,
      ...fileData
    })
  }

  fileArray.sort((a, b) => a.index - b.index)

  return fileArray.flatMap((file) => file.discussions)
}

export const calculateRowTopPosition = (discussion: Discussion | NewCommentBubble) => {
  const section = document.querySelector("section[data-id='pr-content-section']")
  const sectionRect = section?.getBoundingClientRect()

  const { side, start_line, end_line, file } = discussion
  const lineNumber = start_line || end_line

  const relativeTable = document.querySelector(`table[data-file-path='${file}']`)
  const tableHead = relativeTable?.querySelector("thead")
  const tHeadRect = tableHead?.getBoundingClientRect()

  const row = relativeTable?.querySelector(`tr[data-${side}='${lineNumber}']`)
  const rowRect = row?.getBoundingClientRect()

  // if the discussion is outdated place it near the table head
  if (isDiscussion(discussion) && discussion.outdated) {
    if (tHeadRect?.top && sectionRect?.top) {
      return tHeadRect?.top - sectionRect?.top
    }
  }

  if (rowRect?.top && sectionRect?.top) {
    return rowRect?.top - sectionRect?.top
  }
  return 0
}

export const getDiscussionHeight = (discussionNumber: number) => {
  return document.querySelector(`section[data-discussion-number='${discussionNumber}']`)?.clientHeight
}

export const getDiscussionActualPosition = (discussionNumber: number) => {
  const section = document.querySelector("section[data-id='pr-content-section']")
  const sectionRect = section?.getBoundingClientRect()

  const element = document.querySelector(`[data-discussion-number='${discussionNumber}']`)?.getBoundingClientRect()

  if (element && sectionRect) {
    return {
      top: element.top - sectionRect.top,
      height: element?.height,
      bottom: element.bottom - sectionRect.top
    }
  }

  return null
}

export const getMinimumComments = (comments: Comment[] | null) => {
  if (!comments) {
    return []
  }

  if (comments.length <= 2) {
    return comments
  }

  return [comments[0], comments[comments.length - 1]]
}

export const isNewCommentBubble = (discussion: Discussion | NewCommentBubble): discussion is NewCommentBubble => {
  return (discussion as NewCommentBubble).type === "new comment"
}

export const isCommentSuggestion = (comment: Comment): boolean => {
  return comment.comment_body.startsWith("```suggestion")
}

export const calculateBubbleTopBasedOnCurrentDiscussions = (
  discussions: Array<NewCommentBubble | Discussion>,
  newBubble: NewCommentBubble
) => {
  let bubbleIndex = 0

  const discussionsActualPositions = discussions.map((_, index) => getDiscussionActualPosition(index))
  const top = calculateRowTopPosition(newBubble)

  for (let i = 0; i < discussionsActualPositions.length; i++) {
    const firstElement = discussionsActualPositions[0]
    const lastElement = discussionsActualPositions[discussionsActualPositions.length - 1]
    const currentElement = discussionsActualPositions[i]
    const nextElement = discussionsActualPositions[i + 1]

    if (firstElement && top <= firstElement?.top) {
      break
    }
    if (lastElement && top >= lastElement.top) {
      bubbleIndex = discussionsActualPositions.length
      break
    }

    // finding the spot in between discussions
    if (currentElement && nextElement && top >= currentElement.top && top <= nextElement.top) {
      bubbleIndex = i + 1
      break
    }
  }

  return { bubbleIndex, top }
}

export const isDiscussion = (discussion: Discussion | NewCommentBubble): discussion is Discussion => {
  return (discussion as Discussion).discussion_type === "code"
}

export const isOpenDiscussion = (discussion: Discussion | NewCommentBubble | null) => {
  return (discussion as Discussion).discussion_state !== DiscussionState.Resolved
}

export const commentOnLinesFormat = (discussion: Discussion) => {
  const startNum = discussion.outdated ? discussion.original_start_line : discussion.start_line
  const endNum = discussion.outdated ? discussion.original_end_line : discussion.end_line

  return `Line${startNum && endNum && startNum !== endNum ? "s " + startNum + "-" + endNum : " " + endNum}, `
}

export const splitCommentAndSuggestion = (
  body: string
): {
  suggestion: string | null
  comment: string | null
} => {
  if (body.includes("```suggestion")) {
    const parts = body.split("```suggestion\n")
    if (parts.length < 2) {
      return {
        suggestion: null,
        comment: body
      }
    }

    const comment = parts[0].trim() || null
    const remainingText = parts[1]

    let suggestion: string | null
    let additionalComment: string | null

    if (remainingText.includes("```")) {
      const suggestionParts = remainingText.split("```")
      suggestion = suggestionParts[0].trim()
      additionalComment = suggestionParts.slice(1).join("```").trim()
    } else {
      // Try to match either three or more newlines OR two newlines followed by <
      const splitRegex = /\n{3,}|\n\n(?=<)/
      const suggestionParts = remainingText.split(splitRegex)
      suggestion = suggestionParts[0].trim()
      additionalComment = suggestionParts.slice(1).join("\n\n").trim()
    }

    const finalComment = comment
      ? additionalComment
        ? `${comment}\n\n${additionalComment}`
        : comment
      : additionalComment || null

    return {
      suggestion,
      comment: finalComment
    }
  }

  return {
    suggestion: null,
    comment: body
  }
}

export const buildDiscussionLink = (discussionId: string) => {
  return `${getCurrentURLWithoutHash()}#${discussionId}`
}

export const STICKY_HEADER_HEIGHT = 53 // px

export const DISCUSSION_STYLES =
  "absolute z-10 max-h-[800px] w-[calc(100%-16px)] overflow-y-auto rounded-r-xl rounded-bl-xl border border-border bg-fill p-4 shadow-md shadow-border dark:shadow-background"

/**
 * Calculates the adjusted top position by subtracting the sticky header height
 * @param topPosition - The original top position value which may be null
 * @returns The adjusted top position with fallback to 0 if original is null
 */
export const calculateAdjustedTopPosition = (topPosition: number | null): number =>
  (topPosition && topPosition - STICKY_HEADER_HEIGHT) || 0
