import { getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table"
import { columns } from "./columns"
import { Table } from "@/components"
import { useMemo } from "react"
import { FileCodeLine } from "../types"
import TableBody from "./TableBody"
import TableHeader from "./TableHeader"
import { ContentMetadata } from "@/models/FC"

interface Props {
  lines: string[]
  contentMetadata: ContentMetadata
}

const computeLines = (lines: string[]): FileCodeLine[] => {
  return lines.map((line, index) => ({ content: line, lineNumber: index + 1, isSuggest: false }))
}

export const FileCodeTable = ({ lines, contentMetadata }: Props) => {
  const computedLines = useMemo(() => computeLines(lines), [lines])

  const table = useReactTable({
    data: computedLines,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    meta: { filePath: contentMetadata.file_path, insights: contentMetadata.insights, elements: [], fileIndex: 0 }
  })

  return (
    <Table data-file-path={"placeholder"} className="table-fixed">
      <TableHeader table={table} />
      <TableBody table={table} />
    </Table>
  )
}
