import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { ReactNode } from "react"

interface IconTooltipProps {
  icon: ReactNode
  content: string
}

export const IconTooltip = ({ icon, content }: IconTooltipProps) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger className="cursor-default">{icon}</TooltipTrigger>
      <TooltipContent>{content}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
)
