import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { Runs } from "@/models/RedundantRuns"
import { defaultQueryConfig } from "@/hooks/queries/queryConfig"

interface FetchRedundantRunsProps {
  page?: string | null
}

export const useFetchRedundantRuns = ({ page }: FetchRedundantRunsProps = {}) => {
  const pageNum = page ? Number(page) : 1

  return useQuery<Runs>({
    queryKey: ["redundantRuns", pageNum],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.runs.get, {
        params: {
          page: pageNum
        }
      })
      return response.data
    },
    ...defaultQueryConfig
  })
}
