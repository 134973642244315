import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "../useToast"
import { errorHandler } from "@/services/api/helpers"

// Add interface for the parsed description
interface ParsedDescription {
  userDescription: string
  generatedDescription: string
}

// Helper function to parse the description
const parseDescription = (markdownDescription: string): ParsedDescription => {
  const result = {
    userDescription: "",
    generatedDescription: ""
  }

  if (!markdownDescription?.trim()) return result

  const content = markdownDescription.trim()
  const [userPart, generatedPart] = content.split("# Generated description")

  // Handle generated description
  result.generatedDescription = generatedPart?.trim() || ""

  // Handle user description
  const userContent = userPart.trim()
  result.userDescription = userContent.replace("# User description", "").trim()

  return result
}

// Function to build the full description
const buildFullDescription = (currentDescription: string, newUserDescription: string): string => {
  const parsed = parseDescription(currentDescription || "")

  // Create the new full description
  let fullDescription = ""

  // Add user description section
  fullDescription += "# User description\n"
  fullDescription += newUserDescription

  // Add generated description if it exists
  if (parsed.generatedDescription) {
    fullDescription += "\n\n# Generated description\n"
    fullDescription += parsed.generatedDescription
  }

  return fullDescription
}

// Base interface with common properties
interface BaseArgs {
  changeId: string
  state?: string
  branch_to?: string
  title?: string
  description?: string | null
}

// Extended interface for user description update
interface UserDescriptionUpdateArgs extends BaseArgs {
  updateUserDescriptionOnly?: boolean
  currentFullDescription?: string
}

// Union type for all args
type Args = UserDescriptionUpdateArgs

export const useUpdateChangeRequest = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const updateChangeRequest = useCallback(async (args: Args) => {
    const { changeId, state, branch_to, title, description, updateUserDescriptionOnly, currentFullDescription } = args

    // Initialize final payload
    let finalDescription = description

    // Handle user description only update if flag is true and we have both required fields
    if (updateUserDescriptionOnly && description && currentFullDescription) {
      finalDescription = buildFullDescription(currentFullDescription, description)
    }

    // Convert 'close' state to 'closed' for API compatibility
    const computed = state === "close" ? "closed" : state

    // Send request with all possible fields (null values won't be updated)
    return await axiosClient.patch(apiRoutes.changes.updateChange(changeId), {
      state: computed || null,
      branch_to: branch_to || null,
      title: title || null,
      description: finalDescription || null
    })
  }, [])

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["useUpdateChangeRequest"],
    mutationFn: updateChangeRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["change"] })
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        description: errorHandler(err)
      })
    }
  })

  return { data, mutateAsync, status }
}
