import { useCallback, useEffect, useRef, useState } from "react"
import HandSVG from "@/assets/icons/HandSVG"
import ZoomInSVG from "@/assets/icons/ZoomInSVG"
import ZoomOutSVG from "@/assets/icons/ZoomOutSVG"
import { Button } from "@/components"
import { INITIAL_ZOOM } from "@/components/designSystem/MermaidChart/index"

interface ZoomControlsProps {
  svgRef: React.RefObject<SVGSVGElement>
  containerRef: React.RefObject<HTMLDivElement>
  theme: "dark" | "light" | string
  onPanToggle?: (active: boolean) => void
}

export interface ViewBoxState {
  x: number
  y: number
  width: number
  height: number
}

export const ZoomControls: React.FC<ZoomControlsProps> = ({ svgRef, containerRef, theme, onPanToggle }) => {
  // State
  const [currentZoom, setCurrentZoom] = useState(INITIAL_ZOOM)
  const [panEnabled, setPanEnabled] = useState(true)
  const [isPanning, setIsPanning] = useState(false)
  const panStartRef = useRef({ x: 0, y: 0 })

  // Store original SVG viewBox parameters
  const originalViewBoxRef = useRef<ViewBoxState | null>(null)
  // Store current viewBox parameters
  const currentViewBoxRef = useRef<ViewBoxState>({ x: 0, y: 0, width: 0, height: 0 })
  const initializedRef = useRef(false)

  const MAX_ZOOM = 4.0
  const MIN_ZOOM = 0.5

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.cursor = panEnabled ? "grab" : "default"
      if (isPanning && panEnabled) {
        containerRef.current.style.cursor = "grabbing"
      }
    }
  }, [containerRef, panEnabled, isPanning])

  // Update SVG viewBox from current ref
  const updateSvgViewBox = useCallback(() => {
    if (!svgRef.current) return

    const { x, y, width, height } = currentViewBoxRef.current
    svgRef.current.setAttribute("viewBox", `${x} ${y} ${width} ${height}`)
  }, [svgRef])

  // Apply zoom level to the viewBox
  const applyZoom = useCallback(
    (zoomLevel: number) => {
      if (!svgRef.current || !originalViewBoxRef.current) return

      // Get original dimensions
      const { width: originalWidth, height: originalHeight } = originalViewBoxRef.current

      // Calculate new dimensions
      const newWidth = originalWidth / zoomLevel
      const newHeight = originalHeight / zoomLevel

      // Get current center point
      const currentCenterX = currentViewBoxRef.current.x + currentViewBoxRef.current.width / 2
      const currentCenterY = currentViewBoxRef.current.y + currentViewBoxRef.current.height / 2

      // Update current viewBox with new dimensions while maintaining center
      currentViewBoxRef.current = {
        x: currentCenterX - newWidth / 2,
        y: currentCenterY - newHeight / 2,
        width: newWidth,
        height: newHeight
      }

      // Update SVG viewBox
      updateSvgViewBox()
    },
    [svgRef, updateSvgViewBox]
  )

  // Initialize from SVG - run only once
  useEffect(() => {
    if (svgRef.current && !initializedRef.current) {
      const viewBox = svgRef.current.viewBox.baseVal

      // Store original viewBox
      originalViewBoxRef.current = {
        x: viewBox.x,
        y: viewBox.y,
        width: viewBox.width,
        height: viewBox.height
      }

      // Initialize current viewBox
      currentViewBoxRef.current = { ...originalViewBoxRef.current }

      // Mark as initialized
      initializedRef.current = true

      // Apply initial zoom
      applyZoom(INITIAL_ZOOM)
    }
  }, [svgRef, applyZoom])

  // Apply zoom level changes
  useEffect(() => {
    if (initializedRef.current) {
      applyZoom(currentZoom)
    }
  }, [currentZoom, applyZoom])

  // Handle mousedown for panning
  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!panEnabled || !svgRef.current) return

      setIsPanning(true)
      panStartRef.current = { x: e.clientX, y: e.clientY }

      if (containerRef.current) {
        containerRef.current.style.cursor = "grabbing"
      }
    },
    [panEnabled, containerRef, svgRef]
  )

  // Handle mousemove for panning
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isPanning || !containerRef.current || !svgRef.current) return

      // Calculate pixel movement
      const dx = e.clientX - panStartRef.current.x
      const dy = e.clientY - panStartRef.current.y

      // Calculate movement in SVG coordinates
      const containerWidth = containerRef.current.clientWidth
      const containerHeight = containerRef.current.clientHeight
      const { width, height } = currentViewBoxRef.current

      const ratioX = width / containerWidth
      const ratioY = height / containerHeight

      // Update position (x, y) only, preserving width and height
      currentViewBoxRef.current = {
        x: currentViewBoxRef.current.x - dx * ratioX,
        y: currentViewBoxRef.current.y - dy * ratioY,
        width, // Keep width unchanged
        height // Keep height unchanged
      }

      // Update SVG viewBox
      updateSvgViewBox()

      // Update start point for next movement
      panStartRef.current = { x: e.clientX, y: e.clientY }
    },
    [isPanning, containerRef, svgRef, updateSvgViewBox]
  )

  // Handle mouseup for panning
  const handleMouseUp = useCallback(() => {
    if (isPanning) {
      setIsPanning(false)

      if (containerRef.current && panEnabled) {
        containerRef.current.style.cursor = "grab"
      }
    }
  }, [isPanning, containerRef, panEnabled])

  // Set up mouse event handlers for panning
  useEffect(() => {
    if (!containerRef.current) return

    const container = containerRef.current

    // Add event listeners
    container.addEventListener("mousedown", handleMouseDown)
    window.addEventListener("mousemove", handleMouseMove)
    window.addEventListener("mouseup", handleMouseUp)

    // Cleanup
    return () => {
      container.removeEventListener("mousedown", handleMouseDown)
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [containerRef, handleMouseDown, handleMouseMove, handleMouseUp])

  // Handle zoom in
  const zoomIn = useCallback(() => {
    setCurrentZoom((prev) => Math.min(prev * 1.2, MAX_ZOOM))
  }, [MAX_ZOOM])

  // Handle zoom out
  const zoomOut = useCallback(() => {
    setCurrentZoom((prev) => Math.max(prev / 1.2, MIN_ZOOM))
  }, [MIN_ZOOM])

  // Toggle pan mode
  const togglePan = useCallback(() => {
    const newPanState = !panEnabled
    setPanEnabled(newPanState)

    // Call callback if provided
    if (onPanToggle) {
      onPanToggle(newPanState)
    }
  }, [panEnabled, onPanToggle])

  return (
    <div
      className={`absolute right-2.5 top-2.5 z-10 flex items-center rounded-full border p-1 ${
        theme === "dark" ? "border-primary-pressed" : "border-border"
      }`}
      style={{
        backgroundColor: theme === "dark" ? "rgba(28, 32, 38, 1)" : "rgba(255, 255, 255, 1)",
        borderRadius: 6
      }}
    >
      {/* Hand icon for panning */}
      <Button
        variant="ghost"
        onClick={togglePan}
        title="Pan mode"
        className={`size-6 p-0.5 ${panEnabled ? "bg-darker-fill" : ""}`}
      >
        <HandSVG color={theme === "dark" ? "#94A3B8" : "#64748B"} />
      </Button>

      {/* Divider */}
      <div className="h-5 w-px" style={{ backgroundColor: theme === "dark" ? "#334155" : "#CBD5E1" }} />

      {/* Zoom in button */}
      <Button
        variant="ghost"
        onClick={zoomIn}
        disabled={currentZoom >= MAX_ZOOM}
        title="Zoom in"
        className="size-6 p-0.5 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <div style={{ color: theme === "dark" ? "#94A3B8" : "#64748B" }}>
          <ZoomInSVG />
        </div>
      </Button>

      {/* Zoom out button */}
      <Button
        variant="ghost"
        onClick={zoomOut}
        disabled={currentZoom <= MIN_ZOOM}
        title="Zoom out"
        className="size-6 p-0.5 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <ZoomOutSVG color={theme === "dark" ? "#94A3B8" : "#64748B"} />
      </Button>
    </div>
  )
}
