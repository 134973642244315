import { Avatar, Card, CardContent, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { Change } from "@/models/Changes"
import { Link } from "react-router-dom"
import { extractCIStatus, extractReviewStatus } from "./helpers"
import { LanguagesChanges } from "./LanguagesChanges"
import { routes } from "@/lib/routes.ts"
import { getChangeRequestStateIcon } from "@/components/designSystem/Badges/CodeChangeStateBadge/utils"
import { uppercaseString } from "@/lib/utils"
import { DotIcon } from "lucide-react"
import { useTranslation } from "react-i18next"
import { BuildStatus } from "./BuildStatus"
import { Common } from "@/services/i18n/locales/en/common.ts"
import { localeDateTime, relativeTime } from "@/lib/dateFormats.ts"

export const ChangeCard = ({ codeChange }: { codeChange: Change }) => {
  const { t: changesT } = useTranslation("Changes")

  const ciStatus = extractCIStatus(codeChange.runs)
  const { summary: reviewSummary, details: reviewDetails } = extractReviewStatus(changesT, codeChange.reviews)
  const currentState = codeChange.is_draft && codeChange.state === "open" ? "draft" : codeChange.state

  let verb = "Opened"
  let timeToShow = codeChange.created_at
  switch (codeChange.state) {
    case "closed":
      verb = "Closed"
      timeToShow = codeChange.closed_at
      break
    case "merged":
      verb = "Merged"
      timeToShow = codeChange.merged_at
      break
    case "draft":
    case "open":
      if (codeChange.created_at !== codeChange.updated_at) {
        verb = "Updated"
        timeToShow = codeChange.updated_at
      }
      break
  }

  return (
    <Card className="min-h-24 rounded-md border bg-background shadow-sm">
      <CardContent className="py-4">
        {/* Title section */}
        <div className="mb-2 flex items-baseline">
          <Link
            className="flex w-fit"
            to={routes.changes.crFileChanges({
              repoFullName: codeChange.repo_full_name,
              prNumber: codeChange.pr_number.toString()
            })}
            state={{ changeId: codeChange.id }}
          >
            <h3 className="flex items-center gap-2 text-lg">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    {getChangeRequestStateIcon({
                      is_draft: codeChange.is_draft,
                      state: codeChange.state
                    })}
                  </TooltipTrigger>
                  <TooltipContent align="start">{uppercaseString(currentState)}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              #{codeChange.pr_number} {codeChange.title}
            </h3>
          </Link>
          {ciStatus && codeChange.runs && <BuildStatus runs={codeChange.runs} status={ciStatus} />}
        </div>

        {/* Info section */}
        <div className="flex justify-between">
          <div className="flex items-center font-normal">
            <p className="cursor-default text-sm text-secondary">{codeChange.repo_full_name}</p>
            <DotIcon className="size-6 text-secondary" />
            <Avatar
              className="mr-2 size-6 rounded-full"
              avatarURL={codeChange.author_user?.avatar_url}
              username={codeChange.author_user?.display_name}
              fallbackClassName="text-[10px]"
            />
            <p className="text-sm text-secondary">{codeChange.author_user?.display_name}</p>
            <DotIcon className="size-6 text-secondary" />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <p className="cursor-default text-sm text-secondary">{reviewSummary}</p>
                </TooltipTrigger>
                {reviewDetails && <TooltipContent className="whitespace-pre-wrap">{reviewDetails}</TooltipContent>}
              </Tooltip>
            </TooltipProvider>
            <DotIcon className="size-6 text-secondary" />
            <p className="text-sm text-secondary"> {Common.Files(codeChange.files_affected)}</p>
            <DotIcon className="size-6 text-secondary" />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <p className="mr-2 cursor-default text-sm text-secondary">
                    {verb} {relativeTime(timeToShow)}
                  </p>
                </TooltipTrigger>
                <TooltipContent className="whitespace-pre-wrap">{localeDateTime(timeToShow)}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {/* Languages section */}
          <div className="flex gap-1">
            <LanguagesChanges
              aggregated_edits={codeChange.aggregated_edits}
              lines_added={codeChange.lines_added}
              lines_deleted={codeChange.lines_deleted}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
