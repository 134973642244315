import {
  calculateLineMarkTop,
  getAvailableHeight,
  percentageToPixels
} from "@/pages/crFileChanges/components/Scrollbar/utils"
import { colorsMap } from "@/pages/crFileChanges/components/Scrollbar/index"
import { forwardRef, MouseEvent, useCallback, useMemo } from "react"
import { Discussion } from "@/models/Discussions"
import { calculateRowTopPosition } from "@/components/designSystem/Discussions/utils"
import { DiscussionWithTop, useDiscussions } from "@/services/store/useDiscussions"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { formatName } from "@/lib/utils"
import { TooltipPortal } from "@radix-ui/react-tooltip"
import ResolvedIcon from "@/assets/icons/Resolved.svg?react"
import OutdatedIcon from "@/assets/icons/Outdated.svg?react"
import OpenIcon from "@/assets/icons/Open.svg?react"
import _ from "lodash"
import { BREAKING_POINT_FOR_MINIMIZING_HEADER } from "@/components/designSystem/FCC/utils"

interface LineMarkProps {
  discussion: DiscussionWithTop
}

const iconsMap = {
  green: <ResolvedIcon />,
  yellow: <OpenIcon />,
  gray: <OutdatedIcon />
} as const

export const LineMark = forwardRef<HTMLElement | null, LineMarkProps>(({ discussion }, ref) => {
  const {
    currentOpenedDiscussion,
    expandDiscussionsContainer,
    setCurrentOpenedDiscussion,
    discussionsExpanded,
    toggleManualRecalculationTrigger,
    discussions
  } = useDiscussions()

  const debouncedScroll = useMemo(
    () =>
      _.debounce((top: number) => {
        scrollTo({ top, behavior: "smooth" })
      }, 100),
    []
  )

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>, current: Discussion) => {
      event.stopPropagation()
      expandDiscussionsContainer()
      setCurrentOpenedDiscussion(current)

      // for some reason just a single toggle is not placing the discussion to the proper place
      // if we go from closed to expanded an array of toggle works
      if (!discussionsExpanded) {
        for (const _ of discussions) {
          toggleManualRecalculationTrigger()
        }
      }

      const top = calculateRowTopPosition(current)
      debouncedScroll(top - BREAKING_POINT_FOR_MINIMIZING_HEADER)
    },
    [
      debouncedScroll,
      expandDiscussionsContainer,
      setCurrentOpenedDiscussion,
      discussionsExpanded,
      toggleManualRecalculationTrigger,
      discussions
    ]
  )

  const top = calculateLineMarkTop(ref, discussion.topPosition)
  const bgColor = discussion.discussion_state === "resolved" ? "green" : discussion.outdated ? "gray" : "yellow"
  const isDiscussionOpen = currentOpenedDiscussion?.id === discussion.id

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger>
          <div
            onClick={(event) => handleClick(event, discussion)}
            style={{ top: top + "%" }}
            key={discussion.id}
            className={`
            absolute h-1 w-full origin-center cursor-pointer transition-transform duration-300 
            hover:scale-y-[200%] 
            ${colorsMap[bgColor].bg} 
            ${isDiscussionOpen ? `scale-y-[300%] border-y ${colorsMap[bgColor].border} hover:scale-y-[300%]` : ""}`}
          />
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            style={{ top: percentageToPixels(top, getAvailableHeight()) - 20 }}
            className="absolute right-full min-w-40 shadow-md"
          >
            <span className="flex w-full items-center gap-1">
              {iconsMap[bgColor]} {formatName(discussion?.comments[0]?.author)}
            </span>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
})
