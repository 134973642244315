export const RedundantRun = {
  Header: "Runs",
  SubHeader: "Baz analyzes your CI jobs and detects redundant checks",
  EstimatedRedundantCITime: "Estimated redundant CI time",
  EstimatedSavings: "Estimated savings",
  MostRedundantJob: "Most redundant job",
  EmptyRedundantRunsHeader: "No redundant CI runs detected!",
  EmptyRedundantRunsSubHeader: "We'll let you know if there is anything to optimize.",
  NoEstimatedSavingsAvailable: "No estimated savings for now, check later",
  NoRunsKPI: "No runs KPIs, check later"
}
