import { Button, Form, FormControl, FormField, FormItem, FormMessage } from "@/components"
import { useCallback } from "react"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { useTranslation } from "react-i18next"
import { RichEditor } from "@/components/designSystem/Discussions/components/RichEditor"
import { UseFormReturn } from "react-hook-form"
import { useUpdateChangeRequest } from "@/hooks/mutations/useUpdateChangeRequest"
import { useChangeRequest } from "@/services/store/useChangeRequest"

interface Props {
  description: string
  form: UseFormReturn<{ body: string }>
  onClose: () => void
}

export const EditUserDescriptionForm = ({ description, form, onClose }: Props) => {
  const { mutateAsync } = useUpdateChangeRequest()
  const { t } = useTranslation("Common")
  const { changeRequest } = useChangeRequest()

  const { setError, handleSubmit, control } = form

  const onSubmit = useCallback(
    async (values: { body: string }) => {
      try {
        if (!changeRequest?.id) {
          return
        }

        await mutateAsync({
          changeId: changeRequest.id,
          description: values.body,
          updateUserDescriptionOnly: true,
          currentFullDescription: changeRequest.description || ""
        })
      } catch (err) {
        setError("body", { message: ghWriteErrorHandler(err) })
      } finally {
        onClose()
      }
    },
    [changeRequest, mutateAsync, onClose, setError]
  )

  return (
    <Form {...form}>
      <form
        id="edit-user-description-form"
        className="w-full overflow-scroll border border-border bg-background p-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          name="body"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <RichEditor
                  initialContent={description}
                  handleEditorChange={field.onChange}
                  parseMode="full"
                  onCommandEnter={handleSubmit(onSubmit)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
      <div className="flex gap-2 py-2">
        <Button type="submit" form="edit-user-description-form" variant="default" className="h-8 w-[70px] text-xs">
          {t("Save")}
        </Button>
      </div>
    </Form>
  )
}
