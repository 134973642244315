function ZoomInSVG({ color = "currentColor", className }: { color?: string; className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      style={{ color: color }}
      className={className}
    >
      <g id="icons/main/zoom-in">
        <g id="Icon" fill={color}>
          <path d="M6.417 4.23c.241 0 .437.195.437.437v1.312h1.313a.437.437 0 1 1 0 .875H6.854v1.313a.438.438 0 0 1-.875 0V6.854H4.667a.437.437 0 1 1 0-.875h1.312V4.667c0-.242.196-.438.438-.438"></path>
          <path
            fillRule="evenodd"
            d="M9.703 10.322a5.104 5.104 0 1 1 .619-.619l2.237 2.238a.437.437 0 1 1-.618.618zM2.188 6.417A4.23 4.23 0 1 1 9.454 9.36a.4.4 0 0 0-.094.094 4.23 4.23 0 0 1-7.172-3.037"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default ZoomInSVG
