import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components"
import { useTranslation } from "react-i18next"
import { CIRun } from "@/models/Changes.ts"
import { X } from "lucide-react"
import { BlockerBoxItem } from "@/pages/crFileChanges/components/BlockerBox.tsx"
import { getFormattedCiRuns } from "@/pages/crFileChanges/utils.ts"
import { BaseDialogProps } from "@/pages/crFileChanges/components/CRHeader/CRActions/InfoActions/types"

export const ChecksDialog = ({
  onClose,
  onTriggerClick,
  open,
  children,
  ciChecks
}: BaseDialogProps & { ciChecks: CIRun[] }) => {
  const { t } = useTranslation("Toolbar")

  return (
    <Dialog open={open} modal={false}>
      <DialogTrigger onClick={onTriggerClick}>{children}</DialogTrigger>

      <DialogContent
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
        onOpenAutoFocus={(event) => event.preventDefault()}
        className="min-w-[700px] max-w-dialog gap-[2px] rounded-sm border-0 bg-darker-fill p-2"
      >
        <DialogTitle className="mb-1 flex items-center justify-between">
          <p className="h-8 content-center px-2 py-1 text-xs text-primary">{t("Checks")}</p>
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
        </DialogTitle>

        <div className="flex size-full max-h-[500px] flex-col items-center overflow-y-auto rounded-sm bg-fill text-xs">
          {ciChecks.length > 0 ? (
            getFormattedCiRuns(ciChecks, true).map((check, i) => (
              <div className="w-full" key={check.description + i}>
                <BlockerBoxItem item={check} />
              </div>
            ))
          ) : (
            <p className="my-2 text-xs text-primary">{t("NoChecks")}</p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
