import { CenteredLayout } from "@/pages/CenteredLayout"
import { getActionButtonText, getInfoText, getTitleText } from "@/pages/disabled/helpers"
import { Button } from "@/components"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { routes } from "@/lib/routes"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { useFetchSubscription } from "@/hooks/queries/useFetchSubscription"
import { useFetchUser } from "@/hooks/queries/useFetchUser"
import { errorHandler } from "@/services/api/helpers"

const bazContactLink = "https://baz.co/contact"

export function Disabled() {
  const { data: subscription, isLoading: subscriptionLoading, error: subscriptionError } = useFetchSubscription()
  const { data: user, isLoading: userLoading, error: userError } = useFetchUser()
  const navigate = useNavigate()
  const { t } = useTranslation("DisabledState")

  if (subscriptionLoading || userLoading) {
    return (
      <CenteredLayout>
        <SpinnerLoader />
      </CenteredLayout>
    )
  }

  if (userError || subscriptionError) {
    return (
      <CenteredLayout>
        <div>{errorHandler(userError || subscriptionError)}</div>
      </CenteredLayout>
    )
  }

  if (subscription?.status === "active" || subscription?.status === "trialing") {
    navigate(routes.root)
    return (
      <CenteredLayout>
        <></>
      </CenteredLayout>
    )
  }

  if (subscription) {
    return (
      <CenteredLayout>
        <section className="small-content-container">
          <div className="mb-9 w-full rounded-2xl bg-white px-8 pb-8 pt-10 shadow-xl">
            {/* Title */}
            <h1 className="mb-4 text-center text-2xl text-primary">
              {getTitleText(subscription.status, user?.is_owner)}
            </h1>

            {/* Info paragraph */}
            <p className="mb-8 text-center text-sm text-light-secondary">
              {getInfoText(subscription.status, user?.is_owner)}
            </p>

            {/* Action button */}
            {user?.is_owner ? (
              <Button
                onClick={() => window.open(subscription.customerPortalUrl, "_blank")}
                size="sm"
                className="mb-8 h-10 w-full text-sm font-light"
              >
                {getActionButtonText(subscription.status)}
              </Button>
            ) : null}

            {/* Contact text */}
            <p className="text-center text-xxs text-light-secondary">
              <span className="mr-1 font-bold">{t("NeedHelp")}</span>
              <span className="mr-1">{t("HavingTrouble")}</span>
              <a href={bazContactLink} target="_blank" rel="noopener noreferrer" className="mr-1 text-link">
                {t("ContactSupport")}
              </a>
              <span>{t("HappyToAssist")}</span>
            </p>
          </div>
        </section>
      </CenteredLayout>
    )
  }
}
