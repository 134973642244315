import { runtimeConfig } from "./config"

const joinUrl = (base: string, relative: string) => {
  const baseWithoutSlash = base.endsWith("/") ? base.slice(0, -1) : base
  const relativeWithoutSlash = relative.startsWith("/") ? relative.slice(1) : relative
  return [baseWithoutSlash, relativeWithoutSlash].join("/")
}

export const clientUrl = (relative: string = "/") =>
  new URL(joinUrl(runtimeConfig.VITE_CLIENT_URL_PATH_PREFIX ?? "/", relative), window.location.origin).toString()

export const backendUrl = (relative: string = "/") =>
  new URL(joinUrl(runtimeConfig.VITE_BACKEND_BASE_URL ?? "/", relative), window.location.origin).toString()

export const backendBaseUrlAbsolute = () => new URL(backendUrl(), window.location.origin).toString()

export const getCurrentURLWithoutHash = () => window.location.href.split("#")[0]
