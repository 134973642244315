import { Button, TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@/components"
import { useEffect, useState } from "react"
import { Plus, X, CircleSlash } from "lucide-react"
import { ExcludedConfig } from "@/models/ReviewerConfig.ts"
import { TagsInput } from "react-tag-input-component"
import { useTranslation } from "react-i18next"
import {
  getExcludedServerFormat,
  GLOB_PATTERN_URL,
  renderRepositorySelector,
  validateGlobPattern
} from "@/pages/settings/reviewer/helpers"
import { useSettings } from "@/services/store/useSettings"

export interface RepositoryOption {
  name: string
  value: string
}

interface ExcludeConfigManagerProps {
  repositories: RepositoryOption[]
  existingConfigs: ExcludedConfig[]
  onConfigChange: (config: ExcludedConfig | null) => void
  onConfigDelete: (repository: string) => void
  disabled: boolean
}

export interface ConfigSection {
  id: string
  open: boolean
  searchValue: string
  selectedRepo: string
  excludedPaths: string
  error: string
  localPaths: string
  isExisting: boolean
}

export const ExcludeConfigManager = ({
  repositories,
  existingConfigs = [],
  onConfigDelete,
  onConfigChange,
  disabled
}: ExcludeConfigManagerProps) => {
  const { t } = useTranslation("Integrations")
  const [configSections, setConfigSections] = useState<Array<ConfigSection>>([])
  const isAddButtonDisabled = configSections[configSections.length - 1]?.id === "new"
  const tagsElements = document.querySelectorAll("span.rti--tag")
  const { selectedTheme } = useSettings()

  /*  functions that affect only UI */
  const handleToggle = (configId: string) => {
    setConfigSections((prev) =>
      prev.map((config) => (config.id === configId ? { ...config, open: !config.open } : config))
    )
  }

  const handleSearchChange = (configId: string, value: React.SetStateAction<string>) => {
    setConfigSections((prev) =>
      prev.map((config) =>
        config.id === configId
          ? { ...config, searchValue: typeof value === "string" ? value : value(config.searchValue) }
          : config
      )
    )
  }

  const handleAddNew = () => {
    setConfigSections((prev) => [
      ...prev.filter((config) => config.id !== "new"),
      {
        id: "new",
        open: false,
        searchValue: "",
        selectedRepo: "",
        excludedPaths: "",
        error: "",
        localPaths: "",
        isExisting: false
      }
    ])
  }

  /* functions that affect backend */
  const handleSelect = (configId: string, value: string) => {
    setConfigSections((prev) => {
      const prevSelectedRepo = prev.find((config) => config.id === configId)

      const newConfig = prev.map((config) => {
        if (config.id === configId) {
          return {
            ...config,
            id: value,
            selectedRepo: value,
            searchValue: "",
            open: false
          }
        }

        return config
      })

      onConfigChange(getExcludedServerFormat(newConfig, value))

      // in case there is a previous-selected repo we need to delete its config from the BE
      if (prevSelectedRepo && prevSelectedRepo.selectedRepo !== "") {
        onConfigDelete(prevSelectedRepo.selectedRepo)
      }
      return newConfig
    })
  }

  const handlePathsChange = (configId: string, value: string[]) => {
    const newValue = value.join(",")
    const isValid = validateGlobPattern(newValue)

    setConfigSections((prev) => {
      const newConfig = prev.map((config) =>
        config.id === configId
          ? {
              ...config,
              excludedPaths: value.join(","),
              error: isValid ? "" : "Invalid glob pattern."
            }
          : config
      )

      isValid && onConfigChange(getExcludedServerFormat(newConfig, configId))
      return newConfig
    })
  }

  const handleDelete = (configId: string) => {
    setConfigSections((prev) => prev.filter((config) => config.id !== configId))
    if (configId !== "new") {
      onConfigDelete(configId)
    }
  }

  useEffect(() => {
    setConfigSections([
      ...existingConfigs.map((config) => {
        return {
          id: config.fullRepoName,
          open: false,
          searchValue: "",
          selectedRepo: config?.fullRepoName,
          excludedPaths: config.excludedPaths,
          error: "",
          localPaths: config.excludedPaths,
          isExisting: true
        }
      })
    ])
  }, [existingConfigs])

  useEffect(() => {
    tagsElements?.forEach((tag) => {
      if (tag.textContent && validateGlobPattern(tag.textContent)) {
        tag.classList.remove("tag-error-state")
      } else {
        tag.classList.add("tag-error-state")
      }
    })
  }, [tagsElements])

  return (
    <div className="mb-10 mt-2 space-y-3 text-primary">
      <h3 className="text-sm font-medium">{t("ExcludedPaths")}</h3>
      <p className="justify-center text-xs text-secondary">
        {t("SpecifyPaths")}
        <a className="ml-1 text-link" href={GLOB_PATTERN_URL} target="_blank" rel="noopener noreferrer">
          {t("GlobPatterns")}
        </a>
      </p>

      {configSections.map((config) => (
        <div className="flex">
          <div className="w-52">
            {renderRepositorySelector({
              config,
              configSections,
              repositories,
              handleSearchChange,
              handleToggle,
              handleSelect,
              disabled
            })}
          </div>
          <div className="flex grow">
            <div className="relative grow">
              <TagsInput
                value={config.excludedPaths.split(",").filter((value) => value !== "")}
                onChange={(value) => handlePathsChange(config.id, value)}
                name="paths"
                placeHolder={config.excludedPaths.length ? "" : t("EnterPath")}
                disabled={disabled}
                classNames={{
                  input: `${selectedTheme === "dark" ? "" : ""} h-[22px] rounded-none`,
                  tag: `${selectedTheme === "dark" ? "" : ""} p-0 px-1 h-5`
                }}
              />
              {config.error && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="absolute right-2 top-1/2 -translate-y-1/2">
                        <CircleSlash className="text-red-500" />
                      </div>
                    </TooltipTrigger>

                    <TooltipContent>{config.error}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <button
              disabled={disabled}
              onClick={() => handleDelete(config.id)}
              className={`ml-2 text-primary
              ${disabled ? "cursor-not-allowed" : ""}`}
              title="Delete configuration"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      ))}

      <Button
        disabled={disabled || isAddButtonDisabled}
        onClick={handleAddNew}
        className={`rounded-md p-2 text-xs text-primary ${disabled ? "disabled:pointer-events-auto disabled:cursor-not-allowed" : ""}`}
        variant="outline"
        size="sm"
      >
        <Plus className="mr-1 text-secondary" size={16} /> {t("AddExclude")}
      </Button>
    </div>
  )
}
