import { useEffect, useRef, useState } from "react"
import mermaid from "mermaid"
import { Legend } from "./Legend"
import { ZoomControls } from "./ZoomControls"
import { getMermaidConfig, getMermaidStyles, ThemeConfig } from "@/lib/mermaidCharts"
import { Button } from "@/components"
import { Expand } from "lucide-react"
import { ChartModal } from "@/components/designSystem/MermaidChart/ChartModal"
import { ChangeRequest } from "@/models/Changes"

export const INITIAL_ZOOM = 1.0
const NODE_HEIGHT = 38
const NODE_HEIGHT_PX = `${NODE_HEIGHT}px`

export default function MermaidChart({
  flowChartData,
  currentTheme,
  changeRequest
}: {
  flowChartData: string
  currentTheme: ThemeConfig
  changeRequest: ChangeRequest
}) {
  const flowChartRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)

  const [isRendered, setIsRendered] = useState(false)
  const [isPanEnabled, setIsPanEnabled] = useState(true)
  const [isChartModalOpen, setIsChartModalOpen] = useState(false)
  const [renderedSvg, setRenderedSvg] = useState<string | null>(null)

  useEffect(() => {
    if (!flowChartRef.current) return

    const renderMainChart = async () => {
      mermaid.initialize(getMermaidConfig(currentTheme))

      // Add edge label and SVG styles
      const styleId = "mermaid-edge-styles-main"
      let styleEl = document.getElementById(styleId) as HTMLStyleElement
      if (!styleEl) {
        styleEl = document.createElement("style")
        styleEl.id = styleId
        document.head.appendChild(styleEl)
      }

      // Set styles using extracted function
      styleEl.textContent = getMermaidStyles(currentTheme)

      // Generate unique ID for this render
      const chartId = `chart-${Math.random().toString(36).substring(2, 9)}`

      try {
        const result = await mermaid.render(chartId, flowChartData)
        if (!flowChartRef.current) return

        flowChartRef.current.innerHTML = result.svg
        setRenderedSvg(result.svg)

        const svg = flowChartRef.current.querySelector("svg")
        if (svg) {
          svgRef.current = svg as SVGSVGElement

          const nodeRects = svg.querySelectorAll(".node rect")
          nodeRects.forEach((rect) => {
            if (rect instanceof SVGRectElement) {
              // Get the parent node element
              const nodeGroup = rect.closest(".node")
              if (nodeGroup) {
                // Find any foreign objects inside this node
                const foreignObject = nodeGroup.querySelector("foreignObject")
                if (foreignObject instanceof SVGForeignObjectElement) {
                  // Add the transform to fine-tune vertical positioning
                  foreignObject.setAttribute("transform", "translate(0, -3)")
                }
              }

              const styleEl = document.getElementById("mermaid-edge-styles-main")
              if (styleEl) {
                const nodeRectRule = `#flowchart-container .node rect { height: ${NODE_HEIGHT_PX}; min-height: ${NODE_HEIGHT_PX}; max-height: ${NODE_HEIGHT_PX}; }`
                styleEl.textContent += nodeRectRule
              }
            }
          })

          // Apply initial zoom level (zoomed out and centered)
          const viewBox = svg.viewBox.baseVal
          const originalWidth = viewBox.width
          const originalHeight = viewBox.height

          // Store original center point
          const centerX = viewBox.x + originalWidth / 2
          const centerY = viewBox.y + originalHeight / 2

          // Calculate new dimensions (zoomed out)
          const newWidth = originalWidth / INITIAL_ZOOM
          const newHeight = originalHeight / INITIAL_ZOOM

          // Calculate new origin to keep center point
          const newX = centerX - newWidth / 2
          const newY = centerY - newHeight / 2

          // Update SVG viewBox
          svg.setAttribute("viewBox", `${newX} ${newY} ${newWidth} ${newHeight}`)

          setIsRendered(true)
        }
      } catch (error) {
        console.error("Error rendering main chart:", error)
        const errorDiv = document.querySelector(`#d${chartId}`)
        errorDiv?.remove()
      }
    }

    renderMainChart()

    return () => {
      const styleEl = document.getElementById("mermaid-edge-styles-main")
      if (styleEl) {
        styleEl.remove()
      }
    }
  }, [currentTheme, flowChartData])

  // Effect to update pointer-events based on pan active state
  useEffect(() => {
    if (!svgRef.current) return

    const edgeLabels = svgRef.current.querySelectorAll(".edgeLabel, .edgeLabel div, .edgeLabel span")
    const nodes = svgRef.current.querySelectorAll(".node, .node *")

    const elements = [...Array.from(edgeLabels), ...Array.from(nodes)]

    elements.forEach((element) => {
      if (element instanceof HTMLElement || element instanceof SVGElement) {
        element.style.pointerEvents = isPanEnabled ? "none" : "auto"
      }
    })

    // Update cursor style on container
    if (flowChartRef.current) {
      flowChartRef.current.style.cursor = isPanEnabled ? "grab" : "default"
    }
  }, [isPanEnabled])

  const handlePanToggle = () => {
    setIsPanEnabled(!isPanEnabled)
  }

  const handleOpenModal = () => {
    setIsChartModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsChartModalOpen(false)
  }

  return (
    <div
      className="relative my-4 flex h-[250px] w-full items-center justify-center rounded border"
      style={{
        backgroundImage: `url(${currentTheme.backgroundImage})`,
        backgroundSize: "auto",
        backgroundPosition: "center"
      }}
    >
      <div
        id="flowchart-container"
        ref={flowChartRef}
        className="relative flex size-full select-none flex-col items-center justify-center overflow-auto"
        style={{ width: "100%", height: "100%" }}
      />

      {isRendered && (
        <>
          <div
            className={`absolute right-28 top-2.5 z-10 flex items-center rounded-full border p-1 ${currentTheme.mermaidTheme === "dark" ? "border-primary-pressed" : "border-border"}`}
            style={{
              backgroundColor: currentTheme.mermaidTheme === "dark" ? "rgba(28, 32, 38, 1)" : "rgba(255, 255, 255, 1)",
              borderRadius: 6
            }}
          >
            <Button
              variant="ghost"
              onClick={handleOpenModal}
              title="Expand chart"
              className="size-6 p-0.5 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <div style={{ color: currentTheme.mermaidTheme === "dark" ? "#94A3B8" : "#64748B" }}>
                <Expand size={16} />
              </div>
            </Button>
          </div>

          <ZoomControls
            svgRef={svgRef}
            theme={currentTheme.mermaidTheme}
            containerRef={flowChartRef}
            onPanToggle={handlePanToggle}
          />
          <Legend theme={currentTheme.mermaidTheme} />
        </>
      )}

      {renderedSvg && (
        <ChartModal
          open={isChartModalOpen}
          changeRequest={changeRequest}
          onClose={handleCloseModal}
          renderedSvg={renderedSvg}
          currentTheme={currentTheme}
        />
      )}
    </div>
  )
}
