const GithubSVG = ({ primaryColor = "var(--base-primary)", secondaryColor = "var(--base-background)", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" fill="none" viewBox="0 0 19 20" {...props}>
    <g id="icons/logos/github" clipPath="url(#clip0_4152_255897)">
      <path
        id="Vector"
        fill={primaryColor}
        d="M9.667 1.444C4.512 1.444.333 5.472.333 10.44c0 3.975 2.674 7.346 6.383 8.535.466.083.637-.194.637-.432 0-.215-.007-.924-.012-1.675-2.596.545-3.145-1.062-3.145-1.062-.424-1.039-1.036-1.316-1.036-1.316-.847-.557.064-.546.064-.546.937.063 1.431.927 1.431.927.833 1.375 2.184.977 2.716.747.084-.58.326-.978.592-1.202-2.073-.228-4.252-.999-4.252-4.445 0-.983.365-1.785.961-2.415-.096-.227-.416-1.141.091-2.38 0 0 .784-.242 2.567.922a9.3 9.3 0 0 1 2.337-.303 9.3 9.3 0 0 1 2.338.303c1.78-1.164 2.564-.922 2.564-.922.508 1.239.188 2.154.092 2.38.627.646.972 1.514.96 2.415 0 3.454-2.184 4.215-4.262 4.438.335.279.633.826.633 1.665 0 1.204-.01 2.172-.01 2.469 0 .24.167.52.641.431C16.329 17.783 19 14.413 19 10.44c0-4.967-4.179-8.995-9.333-8.995"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_4152_255897">
        <path fill={secondaryColor} d="M.333.667H19v18.667H.333z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default GithubSVG
