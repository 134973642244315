import { useTranslation } from "react-i18next"
import { Button, Dialog, DialogContent, DialogOverlay, DialogTitle } from "@/components"
import { ChangeRequest } from "@/models/Changes"
import { mdTransformer } from "@/lib/mdTransformer"
import { useSettings } from "@/services/store/useSettings.ts"
import { formatDate } from "@/lib/dateFormats.ts"

interface Props {
  onClose: () => void
  open: boolean
  changeRequest: ChangeRequest
}

export const DescriptionDialog = ({ onClose, open, changeRequest }: Props) => {
  const { t: discussionsT } = useTranslation("Discussions")
  const { t } = useTranslation("Common")
  const { selectedTheme } = useSettings()

  return (
    <Dialog open={open}>
      <DialogOverlay className="backdrop-blur-md">
        <DialogContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          className="flex h-fit max-h-[80%] w-full max-w-[70%] flex-col rounded-2xl border-0 bg-border p-1 text-primary"
        >
          <DialogTitle className="flex h-8 w-full items-center justify-between">
            <p className="content-center px-3 py-2 text-s text-primary">{changeRequest.title}</p>
          </DialogTitle>

          <section className="relative flex min-h-0 flex-1 flex-col rounded-sm bg-background p-4 px-6 text-xs">
            <div className="mb-1 mr-1 text-xxs text-secondary">
              {t("CreatedOn")} {formatDate(changeRequest.created_at)}
            </div>
            <div className="flex-1 overflow-y-auto pb-[40px] pr-[60px]">
              {mdTransformer(changeRequest.description, selectedTheme) || discussionsT("NoDescription")}
            </div>
            <div className="mt-2">
              <Button className="float-end px-[10px] text-xs font-medium" size="sm" onClick={onClose}>
                {t("Done")}
              </Button>
            </div>
          </section>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  )
}
