import { useNavigate } from "react-router-dom"
import { Descope } from "@descope/react-sdk"
import { LocalStorageService } from "@/lib/localStorageService.ts"
import { useTranslation } from "react-i18next"
import { Tabs, TabsList, TabsTrigger } from "@/components"
import BazLogoLight from "@/assets/icons/BazLogo-light"
import BazLogoDark from "@/assets/icons/BazLogo-dark"
import { useSettings } from "@/services/store/useSettings"
import { MoonStar, Sun } from "lucide-react"

const logger = {
  error: (title: string, description: string) => {
    if (title === "User not invited") {
      window.location.href = "https://baz.co/contact"
    }
    console.error("Flow error ", title, description)
  },
  info: (_title: string, _description: string, _state: never) => {},
  warn: (_title: string, _description: string) => {},
  debug: (_title: string, _description: string) => {}
}

const localStorageService = new LocalStorageService("sessionStorage")

export const LoginSection = () => {
  const { setTheme, selectedTheme } = useSettings()
  const navigate = useNavigate()
  const from = localStorageService.getItem("from")
  const { t } = useTranslation("Auth")

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <Tabs value={selectedTheme} className="flex w-full justify-start">
        <TabsList className="absolute top-10 rounded-md border border-border ">
          <TabsTrigger className="data-[state=active]:bg-border" onClick={() => setTheme("light")} value="light">
            <span className="flex w-full items-center gap-1">
              <Sun size={16} />
              {t("Light")}
            </span>
          </TabsTrigger>
          <TabsTrigger className="data-[state=active]:bg-border" onClick={() => setTheme("dark")} value="dark">
            <span className="flex w-full items-center gap-1">
              <MoonStar size={16} />
              {t("Dark")}
            </span>
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="small-content-container">
        <div className="mb-4 flex w-full justify-center">
          {selectedTheme === "light" ? (
            <BazLogoLight width="102px" height="50px" />
          ) : (
            <BazLogoDark width="102px" height="50px" />
          )}
        </div>
        <h1 className="mb-8 text-center text-xl font-semibold text-primary lg:text-2xl 2xl:text-4xl">
          {t("StartCoding")}
        </h1>

        <div className="flex w-full flex-col">
          <div className="mb-9 min-h-[188px] w-full rounded-2xl bg-darker-fill px-6 pb-6 pt-10 shadow-xl">
            <Descope
              flowId={"sign-up-or-in"}
              onSuccess={() => navigate(from ? from : "/")}
              onError={(e) => console.error(`Could not log in, ${JSON.stringify(e)}`)}
              theme={selectedTheme}
              logger={logger}
            />
          </div>

          <p className="mx-auto mb-1 w-[315px] text-center text-[12px] text-secondary">
            {t("SignInAccept")}&nbsp;
            <strong>
              <a href="https://baz.co/legal#terms" target="_blank" rel="noopener noreferrer">
                {t("TermsOfUse")}
              </a>
            </strong>
          </p>
          <p className="mx-auto text-center text-[12px] text-secondary">
            &nbsp;{t("Acknowledge")}&nbsp;
            <strong>
              <a href="https://baz.co/legal#privacy" target="_blank" rel="noopener noreferrer">
                {t("PrivacyStatement")}
              </a>
            </strong>
            &nbsp;{t("And")}&nbsp;
            <strong>
              <a href="https://baz.co/legal#privacy" target="_blank" rel="noopener noreferrer">
                {t("CookiePolicy")}
              </a>
            </strong>
          </p>
        </div>
      </div>
    </div>
  )
}
