import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { X } from "lucide-react"
import { Dialog, DialogContent, DialogTitle } from "@/components"
import { ChangeRequest } from "@/models/Changes"
import { ThemeConfig } from "@/lib/mermaidCharts"
import { ZoomControls } from "@/components/designSystem/MermaidChart/ZoomControls"
import { Legend } from "./Legend"
import { INITIAL_ZOOM } from "@/components/designSystem/MermaidChart/index"

export const ChartModal = ({
  onClose,
  open,
  renderedSvg,
  currentTheme
}: {
  onClose: () => void
  open: boolean
  changeRequest: ChangeRequest
  renderedSvg: string
  currentTheme: ThemeConfig
}) => {
  const modalSvgRef = useRef<SVGSVGElement | null>(null)
  const [isModalRendered, setIsModalRendered] = useState(false)
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null)

  // Generate unique ID for the modal chart container to avoid conflicts
  const modalChartId = useMemo(() => `modal-flowchart-${Math.random().toString(36).substring(2, 9)}`, [])

  const setupChartStyles = useCallback((chartId: string) => {
    const styleId = `mermaid-edge-styles-${chartId}`
    let styleEl = document.getElementById(styleId) as HTMLStyleElement
    if (!styleEl) {
      styleEl = document.createElement("style")
      styleEl.id = styleId
      styleEl.textContent = `
        #${chartId} svg {
          width: 100% !important;
          height: 100% !important;
          max-width: 100% !important;
          max-height: 100% !important;
        }
      `
      document.head.appendChild(styleEl)
    }
  }, [])

  // Extract SVG handling into separate function
  const setupSvgViewBox = useCallback((svg: SVGSVGElement) => {
    const viewBox = svg.viewBox.baseVal
    if (viewBox) {
      const originalWidth = viewBox.width
      const originalHeight = viewBox.height

      // Store original center point
      const centerX = viewBox.x + originalWidth / 2
      const centerY = viewBox.y + originalHeight / 2

      // Calculate new dimensions (adjusted for zoom)
      const newWidth = originalWidth / INITIAL_ZOOM
      const newHeight = originalHeight / INITIAL_ZOOM

      // Calculate new origin to keep center point
      const newX = centerX - newWidth / 2
      const newY = centerY - newHeight / 2

      // Update SVG viewBox
      svg.setAttribute("viewBox", `${newX} ${newY} ${newWidth} ${newHeight}`)
    }
  }, [])

  useEffect(() => {
    if (open && containerElement && renderedSvg) {
      // Clear previous content
      containerElement.innerHTML = ""

      // Add styles
      setupChartStyles(modalChartId)

      // Set new content
      containerElement.innerHTML = renderedSvg

      // Reset rendered state
      setIsModalRendered(false)

      // Find the SVG element
      const svg = containerElement.querySelector("svg")
      if (svg) {
        // Store reference to SVG for zoom controls
        modalSvgRef.current = svg as SVGSVGElement

        // Configure SVG viewBox
        setupSvgViewBox(modalSvgRef.current)

        // Mark as rendered
        setIsModalRendered(true)
      } else {
        console.error("No SVG was found in the rendered content")
      }
    } else if (open) {
      console.error("Chart modal condition failed:", {
        open,
        hasElement: !!containerElement,
        hasSvg: !!renderedSvg
      })
    }
  }, [open, renderedSvg, modalChartId, containerElement, setupChartStyles, setupSvgViewBox])

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      const styleEl = document.getElementById(`mermaid-edge-styles-${modalChartId}`)
      if (styleEl) {
        styleEl.remove()
      }
    }
  }, [modalChartId])

  const getOverlayStyles = () => {
    return currentTheme?.mermaidTheme === "dark" ? "bg-black/80 backdrop-blur-sm" : "bg-black/50 backdrop-blur-sm"
  }

  return (
    <Dialog open={open} modal={true}>
      <DialogContent
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
        className="flex h-full max-h-[90vh] max-w-[90vw] flex-col overflow-hidden rounded-2xl border border-border bg-background p-8 text-primary"
        overlayClassName={getOverlayStyles()}
      >
        <DialogTitle className="mb-1 flex w-full items-center justify-between">
          <p className="h-8 content-center px-2 py-1 text-xs text-primary"></p>
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
        </DialogTitle>

        {/* Mermaid Chart Container */}
        <div
          className="relative mb-6 flex size-full items-center justify-center rounded border"
          style={{
            backgroundImage: `url(${currentTheme.backgroundImage})`,
            backgroundSize: "auto",
            backgroundPosition: "center",
            height: "calc(90vh - 120px)", // Adjust to leave room for header and padding
            width: "100%"
          }}
        >
          <div
            id={modalChartId}
            ref={(el) => {
              if (el) setContainerElement(el)
            }}
            className="relative flex size-full select-none flex-col items-center justify-center overflow-auto"
          />

          {isModalRendered && modalSvgRef.current && (
            <>
              <ZoomControls
                svgRef={modalSvgRef}
                theme={currentTheme.mermaidTheme}
                containerRef={{ current: containerElement }}
                onPanToggle={() => {}}
              />
              <Legend theme={currentTheme.mermaidTheme} />
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
