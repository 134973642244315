import { useTranslation } from "react-i18next"
import { RedundantRun } from "@/services/i18n/locales/en/RedundantRun"
import { MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { TruncatedTextWithTooltip } from "@/components/designSystem/Tooltips/TruncatedTextWithTooltip"

type RedundantRunKey = keyof typeof RedundantRun

export interface StatCard {
  title: RedundantRunKey
  icon: React.ReactNode
  value?: string | number
}

interface Props {
  statCard: StatCard
}

export const StatCard = ({ statCard }: Props) => {
  const { title, icon, value } = statCard
  const { t } = useTranslation("RedundantRun")

  return (
    <div className="flex flex-col rounded-lg border border-border bg-background px-4 py-5">
      <h3 className="mb-2 text-base font-medium text-primary">{t(title)}</h3>
      {value ? (
        <div className="flex size-8 w-full items-center">
          <div className="mr-2 rounded-md border border-border p-2 text-secondary">{icon}</div>
          <TruncatedTextWithTooltip
            truncate={title === "MostRedundantJob"}
            tooltipTrigger={value}
            tooltipContent={value}
            triggerClassName="max-w-[300px] text-xl font-semibold text-primary cursor-default"
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <MagnifyingGlassIcon color="var(--base-secondary)" />
          <p className="mt-1 text-xs text-secondary">{t("NoEstimatedSavingsAvailable")}</p>
        </div>
      )}
    </div>
  )
}
