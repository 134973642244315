import { X } from "lucide-react"
import { v4 as uuidv4 } from "uuid"
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/Dialog"
import { useSelection } from "@/services/store/useSelection"
import { useSuggestion } from "@/services/store/useSuggestion"
import { useCallback, useEffect, useState } from "react"
import { Button } from "@/components/ui/Button"
import { useCreateComment } from "@/hooks/mutations"
import { getServerFormat } from "@/components/designSystem/Discussions/components/Comment/utils"
import { NewCommentBubble } from "@/models/Discussions"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { SuggestionDiffPreview } from "./SuggestionDiffPreview"
import { useTranslation } from "react-i18next"
import { RichEditor } from "@/components/designSystem/Discussions/components/RichEditor"
import { GithubWriteAccessButtonWrapper } from "@/components"
import { useChangeRequest } from "@/services/store/useChangeRequest"

interface SuggestionDialogProps {
  crId?: string
  commitSha?: string
}

export const SuggestionDialog = ({ crId, commitSha }: SuggestionDialogProps) => {
  const { isModalOpen, setSuggestionModalClose, suggestionValue, newSuggestionValue } = useSuggestion()
  const { mutateAsync: createComment, status } = useCreateComment()
  const { selectedTableFilePath, firstSelectedSide, selectedLines } = useSelection()
  const [localSuggestionValue, setLocalSuggestionValue] = useState(newSuggestionValue || suggestionValue)
  const [commentBody, setCommentBody] = useState<string>("")
  const { changeRequest } = useChangeRequest()

  const { t } = useTranslation("Discussions")

  const onSubmit = useCallback(() => {
    const suggestForGitHub = `\`\`\`suggestion\n${localSuggestionValue}\n\`\`\``
    const fullComment = commentBody ? `${suggestForGitHub}\n\n${commentBody}` : suggestForGitHub

    const newSuggestion: NewCommentBubble = {
      id: uuidv4(),
      side: firstSelectedSide,
      file: selectedTableFilePath,
      selectedLines,
      selectedText: suggestionValue,
      end_line: selectedLines.endLine,
      start_line: selectedLines.startLine
    }

    const args = getServerFormat({
      body: fullComment,
      commitSha,
      crId,
      current: newSuggestion
    })
    args ? createComment(args) : console.error("Unable to generate args for suggestion server format")
  }, [
    createComment,
    firstSelectedSide,
    selectedTableFilePath,
    selectedLines,
    commitSha,
    crId,
    suggestionValue,
    commentBody,
    localSuggestionValue
  ])

  const onClose = useCallback(() => {
    setSuggestionModalClose()
    setLocalSuggestionValue("")
    setCommentBody("")
  }, [setSuggestionModalClose])

  useEffect(() => {
    if (status === "success") {
      onClose()
    }
  }, [status, onClose])

  //  sync new suggestion value in case the user didn't change the value it got from the suggestion it will be empty string
  useEffect(() => {
    if (!localSuggestionValue) {
      setLocalSuggestionValue(newSuggestionValue || suggestionValue)
    }
  }, [suggestionValue, localSuggestionValue, newSuggestionValue])

  return (
    <Dialog open={isModalOpen} modal={false}>
      <DialogContent
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
        onOpenAutoFocus={(event) => event.preventDefault()}
        className="max-h-[calc(100vh-250px)] w-fit min-w-[900px] max-w-screen-l gap-[2px] rounded-sm border-0 bg-background p-4 shadow-xl"
      >
        <DialogTitle className="mb-4 flex w-full items-center justify-between">
          <p className="w-full content-center px-2 py-1 text-[16px] font-bold text-primary">{t("SuggestAChange")}</p>
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
        </DialogTitle>

        <div className="p-2">
          <SuggestionDiffPreview
            selectedTableFilePath={selectedTableFilePath}
            suggestionValue={suggestionValue}
            setNewSuggestionValue={setLocalSuggestionValue}
            newSuggestionValue={newSuggestionValue}
          />

          <div className="mt-4 h-20 rounded-lg border border-border bg-background p-3">
            <RichEditor
              initialContent=""
              handleEditorChange={setCommentBody}
              parseMode="inline"
              onCommandEnter={onSubmit}
            />
          </div>

          <div className="flex justify-end">
            <GithubWriteAccessButtonWrapper repoFullName={changeRequest?.base_repo_full_name || ""}>
              {(hasAccess) => (
                <Button
                  onClick={onSubmit}
                  size="sm"
                  className="mt-4 min-w-20 text-xs"
                  type="submit"
                  disabled={status === "pending" || !hasAccess}
                >
                  {status === "pending" ? <SpinnerLoader size="16px" color="var(--primary)" /> : "Submit"}
                </Button>
              )}
            </GithubWriteAccessButtonWrapper>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
