import { BranchSelector } from "./components/BranchSelector"
import { RepositorySelector } from "./components/RepositorySelector"
import { Loader } from "./components/Loader"
import { BranchStatus } from "./components/BranchStatus"
import { TreeRoot } from "./components/TreeBranch/TreeRoot"
import { BranchInfo, Repository } from "@/models/Repository"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useSettings } from "@/services/store/useSettings.ts"

interface Props {
  org?: string
  branch?: string
  repository?: string
  repositoriesLoading: boolean
  branchesLoading: boolean
  repositories: Repository[]
  currentRepositoryName?: string
  currentRepositoryIcon?: string
  setCurrentRepositoryName: Dispatch<SetStateAction<string | undefined>>
  setCurrentRepositoryIcon: Dispatch<SetStateAction<string>>
  setCurrentBranch: Dispatch<SetStateAction<string | undefined>>
  branches: BranchInfo[]
  branchesError: unknown
  currentRepository?: Repository
}

export const SideMenu = ({
  branch,
  repository,
  branches,
  branchesError,
  branchesLoading,
  currentRepository,
  repositories,
  repositoriesLoading,
  setCurrentRepositoryName,
  setCurrentRepositoryIcon,
  setCurrentBranch,
  currentRepositoryName,
  currentRepositoryIcon
}: Props) => {
  const loading = repositoriesLoading || branchesLoading
  const { setSidebarWidth } = useSettings()

  useEffect(() => {
    setSidebarWidth(320)
  }, [setSidebarWidth])

  if (loading) return <Loader />

  return (
    <aside className="sticky top-16 h-0">
      <div className="no-scrollbar h-[calc(100vh-64px)] overflow-y-auto overflow-x-hidden border-r bg-background py-6 pl-12 pr-[22px]">
        <RepositorySelector
          repositories={repositories}
          defaultValue={currentRepositoryName}
          defaultIcon={currentRepositoryIcon}
          setCurrentRepositoryName={setCurrentRepositoryName}
          setCurrentRepositoryIcon={setCurrentRepositoryIcon}
          setCurrentBranch={setCurrentBranch}
        />
        <BranchSelector
          key={branch}
          branches={branches}
          error={branchesError}
          defaultValue={branch || repositories[0]?.defaultBranch}
          currentRepositoryName={currentRepositoryName}
          setCurrentBranch={setCurrentBranch}
        />
        <BranchStatus branches={branches} branchName={branch} defaultBranch={currentRepository?.defaultBranch} />
        <TreeRoot key={`${branch} ${repository}`} branchName={branch} repositoryId={currentRepository?.id} />
      </div>
    </aside>
  )
}
