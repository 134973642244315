import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { cn } from "@/lib/utils"
import { useTruncatedTooltip } from "@/hooks/useTruncatedTooltip"

export const TruncatedTextWithTooltip = ({
  tooltipTrigger,
  triggerClassName,
  contentClassName,
  tooltipContent,
  truncate = true
}: {
  tooltipTrigger: string | number
  triggerClassName?: string
  contentClassName?: string
  tooltipContent: string | number
  truncate?: boolean
}) => {
  const { textRef, isTooltipOpen, tooltipProps } = useTruncatedTooltip<HTMLParagraphElement>()

  return (
    <TooltipProvider delayDuration={1000}>
      <Tooltip open={truncate ? isTooltipOpen : undefined}>
        <TooltipTrigger {...(truncate ? tooltipProps : {})} asChild>
          <p ref={textRef} className={cn(truncate ? "truncate" : "", triggerClassName)}>
            {tooltipTrigger}
          </p>
        </TooltipTrigger>
        {tooltipContent && <TooltipContent className={contentClassName}>{tooltipContent}</TooltipContent>}
      </Tooltip>
    </TooltipProvider>
  )
}
