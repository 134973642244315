import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { RadioGroup, RadioGroupItem } from "@/components"

interface Props {
  images: string[]
}

export const ImageCarousel = ({ images }: Props) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(
      () =>
        setIndex((prev) => {
          return prev >= images.length - 1 ? 0 : prev + 1
        }),
      5000
    )

    return () => {
      clearInterval(interval)
    }
  }, [images])

  return (
    <div className="relative size-full overflow-hidden">
      <motion.div className="flex h-full" animate={{ x: `-${index * 100}%` }} transition={{ duration: 0.5 }}>
        {images.map((img, i) => (
          <div key={i} className="flex size-full shrink-0 items-center justify-center">
            <img src={img} alt={`slide-${i}`} className="w-[550px] max-w-[50%] object-contain" />
          </div>
        ))}
      </motion.div>

      <div className="absolute bottom-[90px] flex w-full justify-center">
        <RadioGroup defaultValue={String(index)} value={String(index)} className="flex">
          {images.map((img, i) => (
            <div key={img} className="flex items-center space-x-2">
              <RadioGroupItem className="bg-background" onClick={() => setIndex(i)} value={String(i)} id={String(i)} />
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  )
}
