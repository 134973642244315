import { useEffect } from "react"
import { useSettings } from "@/services/store/useSettings"

export const useSidebarWidth = (options: {
  width?: number
  conditional?: {
    condition: boolean
    whenTrue: number
    whenFalse: number
  }
}) => {
  const { setSidebarWidth } = useSettings()

  useEffect(() => {
    // Fixed width has priority
    if (options.width !== undefined) {
      setSidebarWidth(options.width)
      return
    }

    // Conditional width
    if (options.conditional) {
      const { condition, whenTrue, whenFalse } = options.conditional
      setSidebarWidth(condition ? whenTrue : whenFalse)
    }
  }, [setSidebarWidth, options.width, options.conditional])
}

// Convenience method for a collapsed sidebar
export const useCollapsedSidebar = () => {
  useSidebarWidth({ width: 0 })
}
