import { MessageSquare, Pencil, Sparkles, Link2 } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNewCommentBubble } from "@/hooks/useNewCommentbubble"
import { useSuggestion } from "@/services/store/useSuggestion"
import { useSelection } from "@/services/store/useSelection"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useBazzyMessages } from "@/hooks/useBazzyMessages"
import { BazzyCodeOptions, BazzyQuestionsCategories } from "@/models/Bazzy"
import { useBazzy } from "@/services/store/useBazzy"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useToast } from "@/hooks/useToast"
import { getCurrentURLWithoutHash } from "@/lib/urls"
import { getSHA256Hash } from "@/lib/utils"
import { Side } from "@/models/FCC"

interface Props {
  open: boolean
  handleDropdownClose: () => void
  filePath?: string
  lineNumber: number | null
  side: Side
}

const buildLinkUrl = (decodedFilePath: string | undefined, lineNumber: number | null, side: Side) =>
  `${getCurrentURLWithoutHash()}#line-${decodedFilePath}-${side[0]}${lineNumber}`

export const CommentsDropdown = ({ open, handleDropdownClose, filePath, lineNumber, side }: Props) => {
  const [decodedFilePath, setDecodedFilePath] = useState<string>()
  const { handleAddCommentClick } = useNewCommentBubble()
  const { setSuggestionModalOpen, setSuggestionValue } = useSuggestion()
  const { selectedLines, firstSelectedSide, selectedTableFilePath } = useSelection()
  const { expandDiscussionsContainer } = useDiscussions()
  const { options, toggleBazzy } = useBazzy()
  const { addUserQuestionToBazzy } = useBazzyMessages()
  const { toast } = useToast()
  const { t } = useTranslation("TableDropdown")
  const { t: commonTranslation } = useTranslation("Common")

  const cleanupAndCloseDropdown = useCallback(() => {
    window.getSelection()?.removeAllRanges()
    handleDropdownClose()
  }, [handleDropdownClose])

  const onAddCommentClick = useCallback(async () => {
    cleanupAndCloseDropdown()
    toggleBazzy(false)
    handleAddCommentClick()
    expandDiscussionsContainer()
  }, [cleanupAndCloseDropdown, toggleBazzy, handleAddCommentClick, expandDiscussionsContainer])

  const onSuggestionClick = useCallback(() => {
    cleanupAndCloseDropdown()
    if (!selectedLines.endLine && !selectedLines.startLine) {
      console.error("Trying to open a suggestion modal without lines context")
      return
    }

    if (!selectedTableFilePath) {
      console.error("Trying to open a suggestion modal without file context")
      return
    }

    toggleBazzy(false)
    setSuggestionModalOpen()
    setSuggestionValue({
      selectedLines,
      selectedSide: firstSelectedSide,
      selectedFile: selectedTableFilePath
    })
  }, [
    cleanupAndCloseDropdown,
    selectedLines,
    selectedTableFilePath,
    toggleBazzy,
    setSuggestionModalOpen,
    setSuggestionValue,
    firstSelectedSide
  ])

  const openBazzy = useCallback(
    (questionId: BazzyCodeOptions, questionLabel: string) => {
      cleanupAndCloseDropdown()
      addUserQuestionToBazzy(questionId, questionLabel)
    },
    [cleanupAndCloseDropdown, addUserQuestionToBazzy]
  )

  const onCopyLink = useCallback(() => {
    toast({
      title: commonTranslation("CopiedLink")
    })
  }, [toast, commonTranslation])

  useEffect(() => {
    getSHA256Hash(filePath || "").then((res) => setDecodedFilePath(res))
  }, [filePath])

  const menuItemClass =
    "relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-fill focus:text-primary data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
  return (
    <>
      <ul
        data-id="contextMenuDropdown"
        className={`absolute top-4 z-100 select-none font-inter ${open ? "block" : "hidden"} min-w-32 overflow-hidden rounded-md border bg-background p-1 text-primary shadow-md shadow-border dark:shadow-background`}
      >
        <CopyToClipboard text={buildLinkUrl(decodedFilePath, lineNumber, side)} onCopy={onCopyLink}>
          <li className={menuItemClass}>
            <Link2 className="mr-2 size-4 text-primary" /> {t("CopyLink")}
          </li>
        </CopyToClipboard>
        <li onClick={onAddCommentClick} className={menuItemClass}>
          <MessageSquare className="mr-2 size-4 text-primary" /> {t("Comment")}
        </li>
        <li onClick={onSuggestionClick} className={menuItemClass}>
          <Pencil className="mr-2 size-4 text-primary" /> {t("Suggest")}
        </li>
        {options[BazzyQuestionsCategories.CODE]?.length > 0 && (
          <li>
            <div className="h-px bg-border"></div>
          </li>
        )}
        {options[BazzyQuestionsCategories.CODE]?.map((question) => (
          <li key={question.id} className={menuItemClass} onClick={() => openBazzy(question.id, question.label)}>
            <Sparkles className="mr-2 size-4" />
            {question.label}
          </li>
        ))}
      </ul>
    </>
  )
}
