export const Auth = {
  SignInAccept: "By signing in you accept the",
  TermsOfUse: "Terms of Use",
  Acknowledge: "and acknowledge the",
  PrivacyStatement: "Privacy Statement",
  And: "and",
  CookiePolicy: "Cookie Policy",
  StartCoding: "Start code reviewing with Baz",
  Dark: "Dark",
  Light: "Light"
}
