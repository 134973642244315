import { useCallback, useEffect, useRef, useState } from "react"

interface ScrollState {
  reachedTarget: boolean
  reachedEnd: boolean
}

/**
 * Custom hook to track scroll position relative to specified thresholds
 * @param scrollTarget - Y position at which reachedTarget becomes true
 * @param endThreshold - Distance from bottom of page to trigger reachedEnd
 * @param throttleMs - Optional throttle time in milliseconds
 * @returns Object containing scroll state and reset function
 */
export const useScrollListener = (scrollTarget: number, endThreshold = 50, throttleMs = 100) => {
  const [scrollState, setScrollState] = useState<ScrollState>({
    reachedTarget: false,
    reachedEnd: false
  })

  // Use refs to track state between renders and avoid unnecessary updates
  const stateRef = useRef<ScrollState>(scrollState)
  const animationFrameRef = useRef<number | null>(null)
  const lastUpdateTimeRef = useRef<number>(0)

  // Update ref when state changes
  useEffect(() => {
    stateRef.current = scrollState
  }, [scrollState])

  // Calculate document height reliably
  const getDocumentHeight = useCallback(() => {
    return Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    )
  }, [])

  const calculateScrollState = useCallback(() => {
    // Capture scroll position outside requestAnimationFrame for better accuracy
    const scrollY = window.scrollY
    const windowHeight = window.innerHeight
    const documentHeight = getDocumentHeight()
    const distanceFromBottom = documentHeight - (scrollY + windowHeight)

    return {
      reachedTarget: scrollY >= scrollTarget,
      reachedEnd: documentHeight > windowHeight && distanceFromBottom <= endThreshold
    }
  }, [scrollTarget, endThreshold, getDocumentHeight])

  const resetScrollState = useCallback(() => {
    // Calculate the current state rather than just setting to false
    // This prevents flashing if we're still actually above the threshold
    const newState = calculateScrollState()

    // Update the state if it's different from current
    if (
      stateRef.current.reachedTarget !== newState.reachedTarget ||
      stateRef.current.reachedEnd !== newState.reachedEnd
    ) {
      setScrollState(newState)
    }
  }, [calculateScrollState])

  const handleScroll = useCallback(() => {
    // Throttle updates based on time
    const now = Date.now()
    if (now - lastUpdateTimeRef.current < throttleMs) {
      return
    }

    // Skip if already processing a frame
    if (animationFrameRef.current) {
      return
    }

    // Mark the time of this update attempt
    lastUpdateTimeRef.current = now

    animationFrameRef.current = requestAnimationFrame(() => {
      resetScrollState()

      // Clear animation frame reference
      animationFrameRef.current = null
    })
  }, [resetScrollState, throttleMs])

  // Force a state check whenever scrollTarget changes
  useEffect(() => {
    handleScroll()
  }, [scrollTarget, handleScroll])

  useEffect(() => {
    // Initial calculation after component mount
    const timeoutId = setTimeout(() => {
      handleScroll()
    }, 0)

    // Add event listeners with passive flag for better performance
    window.addEventListener("scroll", handleScroll, { passive: true })
    window.addEventListener("resize", handleScroll, { passive: true })

    // Detect DOM mutations that might affect scroll height
    const observer = new MutationObserver(handleScroll)
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["style", "class"]
    })

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleScroll)
      observer.disconnect()

      // Clean up any pending animation frame
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [handleScroll])

  return { ...scrollState, resetScrollState }
}
