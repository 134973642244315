import { useCallback, useMemo, useState } from "react"
import { CellContext } from "@tanstack/react-table"
import { ExtendedCellContext, FccRow, Side } from "@/models/FCC"
import {
  checkLineStatus,
  extractCellNumber,
  generateCellBackgroundColor,
  isEmptyContent,
  isLine,
  isSubRow
} from "../utils"
import { getFileExtension, isCellMarked, renderCodeLine, renderSubRowString } from "../utils/codeCellComputations"
import { useSelection } from "@/services/store/useSelection"
import { InsightsLabel } from "@/components/designSystem/Insights/InsightsLabel"
import { useDiscussions } from "@/services/store/useDiscussions"
import { PlusButton } from "../Table/components/PlusButton"
import { CommentsDropdown } from "../Table/components/CommentsDropdown"
import { isRowWithInsights } from "@/components/designSystem/Insights/helpers"
import { useBazzy } from "@/services/store/useBazzy"
import { useHashMarkedCell } from "@/components/designSystem/FCC/utils/useHashMarkedCell"

interface Props {
  info: CellContext<FccRow, FccRow>
  columnSide: Side
  isSplitView: boolean
  tableSide: Side
}

// Function to determine if selection should be blocked
const checkBlockedSelection = (tableSide: Side, firstSelectedSide: Side | null): boolean =>
  firstSelectedSide ? firstSelectedSide !== tableSide : false

export const CodeCell = ({ info, columnSide, isSplitView, tableSide }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { firstSelectedSide } = useSelection()
  const { currentHoveredDiscussion, currentOpenedDiscussion } = useDiscussions()
  const { currentContext, isOpen } = useBazzy()

  const line = info.getValue()
  const cellNumber = extractCellNumber(line, isSplitView, columnSide)
  const { decodedFilePath } = info as ExtendedCellContext<FccRow, unknown>
  const { isMarkedByHash } = useHashMarkedCell(info, cellNumber, columnSide)

  const filePath = info.table.options.meta?.filePath
  const marked =
    isCellMarked({
      discussion: currentOpenedDiscussion || currentHoveredDiscussion,
      columnSide,
      filePath,
      info,
      isSplitView,
      bazzyContext: currentContext,
      isBazzyOpen: isOpen
    }) || isMarkedByHash

  const fileExtension = getFileExtension(filePath)
  const newDiff = info.table.options.meta?.newDiff
  const border = isSplitView && columnSide === "left"
  const computedTableSide = isLine(line) && line.line_type === "Deleted" ? "left" : tableSide
  const isSelectionBlocked = checkBlockedSelection(computedTableSide, firstSelectedSide)
  const lineStatus = isLine(line) ? checkLineStatus(line, isSplitView, columnSide) : null
  const subRow = isSubRow(line)
  const emptyContent = isEmptyContent(isSplitView, line.line_type, columnSide)
  const backgroundColor = generateCellBackgroundColor(info.row, isSplitView, columnSide, marked, subRow)
  const isPlusButtonVisible = (isHovered || isDropdownOpen) && !subRow && !emptyContent && !newDiff

  const { codeCellValue, lineNumber, longestWordLength } = useMemo(() => {
    let longestWordLength = 0
    let codeContent = null
    const lineNum = columnSide === "right" ? line.new_line_number : line.number

    if (isLine(line)) {
      codeContent = renderCodeLine(line, fileExtension, columnSide, isSplitView)
    } else if (!(isSplitView && columnSide === "left") && line.content) {
      codeContent = renderSubRowString(line.content, fileExtension)
    }

    const cellContent = line.new_content || line.content
    // getting the longest word to decide if break-all css is needed on this line
    const wordsSortedByLength = cellContent?.split(" ").sort((a, b) => b.length - a.length)

    if (wordsSortedByLength) {
      longestWordLength = wordsSortedByLength[0].length
    }

    return { codeCellValue: codeContent, lineNumber: lineNum, longestWordLength }
  }, [line, fileExtension, columnSide, isSplitView])

  const handleDropdownToggle = useCallback(() => setIsDropdownOpen((prev) => !prev), [setIsDropdownOpen])
  const handleDropdownClose = useCallback(() => setIsDropdownOpen(false), [setIsDropdownOpen])

  const side = columnSide
  const hasInsights = isRowWithInsights(info.table, lineNumber, side)

  return (
    <div
      id={`line-${decodedFilePath}-${columnSide[0]}${cellNumber}`}
      key={`${lineNumber}-${side}`}
      data-table-side={computedTableSide}
      className={`table size-full items-center ${longestWordLength > 30 ? "break-all" : "break-words"} ${backgroundColor} ${border ? "border-r" : "border-r-0"} ${isSelectionBlocked ? "select-none" : "select-text"}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="relative flex" data-content="true">
        {isPlusButtonVisible ? (
          <PlusButton
            handleDropdownClose={handleDropdownClose}
            onClick={handleDropdownToggle}
            side={side}
            lineNumber={lineNumber}
            filePath={filePath}
          />
        ) : null}

        <span className="w-5 min-w-5 select-none">{lineStatus}</span>
        {codeCellValue}
        {hasInsights && <InsightsLabel table={info.table} lineNumber={lineNumber} side={side} />}
        <CommentsDropdown
          open={isDropdownOpen}
          handleDropdownClose={handleDropdownClose}
          filePath={info.table.options.meta?.filePath}
          lineNumber={lineNumber}
          side={side}
        />
      </span>
    </div>
  )
}
