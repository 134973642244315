import { Discussion as DiscussionI, NewCommentBubble } from "@/models/Discussions"
import { useEffect, useState } from "react"
import { Discussion } from "../components/Discussion"
import { isNewCommentBubble } from "../utils"
import { NewCommentBubble as NewCommentBubbleElement } from "../components/Comment/NewCommentBubble"

interface Props {
  discussions: Array<DiscussionI | NewCommentBubble>
  crId: string
  commitSha: string
}

export const GeneratedDiscussions = ({ discussions, crId, commitSha }: Props) => {
  const [discussionsElements, setDiscussionsElements] = useState<JSX.Element[]>([])

  useEffect(() => {
    const elements: JSX.Element[] = []

    for (let i = 0; i < discussions.length; i++) {
      const currentDiscussion = discussions[i]
      const prevDiscussion = discussions[i - 1]

      const newCommentElement = isNewCommentBubble(currentDiscussion) ? (
        <NewCommentBubbleElement
          discussions={discussions}
          total={discussions.length}
          key={currentDiscussion.id}
          discussionNumber={i}
          current={currentDiscussion}
          prev={prevDiscussion}
          crId={crId}
          commitSha={commitSha}
        />
      ) : null

      elements.push(
        newCommentElement || (
          <Discussion
            discussions={discussions}
            total={discussions.length}
            key={currentDiscussion.id}
            discussionNumber={i}
            current={currentDiscussion as DiscussionI}
            prev={prevDiscussion}
            crId={crId}
            commitSha={commitSha}
            id={"discussion-" + currentDiscussion.id}
          />
        )
      )
    }
    setDiscussionsElements(elements)
  }, [discussions, crId, setDiscussionsElements, commitSha])

  return discussionsElements
}
