import { createColumnHelper } from "@tanstack/react-table"
import { RedundantRun } from "@/models/RedundantRuns"
import StopWatchSVG from "@/assets/icons/StopWatchSVG"
import { formatDuration, localeDateTime, relativeTime } from "@/lib/dateFormats"
import { CRLinkCell } from "./CRLinkCell"
import { JobNameCell } from "@/components/designSystem/RedundantCIRuns/RedundantRunsTable/JobNameCell"
import { TruncatedTextWithTooltip } from "@/components/designSystem/Tooltips/TruncatedTextWithTooltip"

const columnHelper = createColumnHelper<RedundantRun>()

/**
 * Creates table columns configuration for RedundantCIRunsTable
 *
 * @returns Array of column definitions
 */
export const createRedundantCIRunsColumns = () => {
  return [
    columnHelper.accessor("jobName", {
      header: "Job name",
      enableSorting: true,
      cell: (info) => {
        return <JobNameCell row={info.row.original} />
      }
    }),
    columnHelper.accessor("repoName", {
      header: "Repository",
      enableSorting: true,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("codeReviewLink", {
      header: "Change Requests",
      enableSorting: true,
      cell: (info) => {
        return <CRLinkCell value={info.getValue()} row={info.row.original} />
      },
      sortingFn: (rowA, rowB) => {
        return rowA.original.jobNumOfCRs - rowB.original.jobNumOfCRs
      }
    }),
    columnHelper.accessor("duration", {
      header: "Total duration",
      enableSorting: true,
      cell: (info) => {
        const seconds = info.getValue()
        return formatDuration(seconds)
      }
    }),
    columnHelper.accessor("time", {
      header: "Last Run Time",
      enableSorting: true,
      cell: (info) => (
        <div className="flex items-center text-primary">
          <div className="mr-2 rounded-md border border-border p-1 text-secondary">
            <StopWatchSVG />
          </div>
          <TruncatedTextWithTooltip
            truncate={false}
            tooltipTrigger={relativeTime(new Date(info.getValue()).toISOString())}
            tooltipContent={localeDateTime(info.getValue())}
            triggerClassName="cursor-text"
          />
        </div>
      )
    })
  ]
}
