import { Button } from "@/components"
import { ProgressBar } from "@/components/designSystem/ProgressBar"
import BazLogoLight from "@/assets/icons/BazLogo-light.tsx"
import ProgressList from "@/pages/onboarding/ProgressList.tsx"
import { LogOut } from "lucide-react"
import { useTranslation } from "react-i18next"
import { OnboardingStateResponse } from "@/hooks/queries/useFetchOnboardingState"

const BazLogo = BazLogoLight

interface OnboardingProgressProps {
  isError: boolean
  onboardingStatus?: OnboardingStateResponse
  onLogout: () => void
}

export function OnboardingProgress({ isError, onboardingStatus, onLogout }: OnboardingProgressProps) {
  const { t } = useTranslation("OnBoarding")

  return (
    <section className="small-content-container" data-testid="onboarding-progress">
      <div className="mb-6 flex h-12 w-full items-center justify-center">
        <BazLogo width="169" height="50" />
      </div>

      <div className="mb-9 w-full rounded-2xl bg-white px-8 pb-8 pt-10 shadow-xl">
        <h3 className="mb-7 text-center text-xl text-primary">{t("OnboardingToBaz")}</h3>
        <ProgressBar isError={isError} />

        {isError ? <p className="mb-8 mt-7 text-center text-s text-error-dark">{t("SomethingWentWrong")}</p> : null}
        {isError ? (
          <Button onClick={onLogout} size="sm" className="w-full gap-2 text-sm font-light">
            <LogOut width={16} height={16} />
            {t("SignOut")}
          </Button>
        ) : (
          <ProgressList onboardingStatus={onboardingStatus} />
        )}
      </div>
    </section>
  )
}
