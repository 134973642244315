import { forwardRef, MouseEvent, useCallback, useEffect, useRef, useState } from "react"
import {
  calculateScrollbarBottomBoundary,
  calculateScrollbarHeight,
  calculateScrollbarTopPosition,
  isVisibleScroll,
  navigateToClickedScrollbarLocation,
  transformThumbTopToWindowScroll
} from "@/pages/crFileChanges/components/Scrollbar/utils"
import { useDiscussions } from "@/services/store/useDiscussions"
import { LineMark } from "@/pages/crFileChanges/components/Scrollbar/LineMark"

export const colorsMap = {
  green: {
    bg: "bg-success-dark",
    border: "border-success-normal"
  },
  yellow: {
    bg: "bg-yellow-400",
    border: "border-highlight-light"
  },
  gray: {
    bg: "bg-hint",
    border: "border-border"
  }
} as const

export const Scrollbar = forwardRef<HTMLElement | null, unknown>((_, ref) => {
  const [scrollbarTop, setScrollbarTop] = useState(0)
  const scrollbarHeight: number = calculateScrollbarHeight(ref)
  const { discussionsWithTop } = useDiscussions()
  const [isDragging, setIsDragging] = useState(false)
  const startYRef = useRef(0)
  const startTopRef = useRef(0)
  const scrollAreaRef = useRef<HTMLDivElement | null>(null)

  const syncScrollbarPosition = useCallback(() => setScrollbarTop(calculateScrollbarTopPosition(ref)), [ref])

  const handleScrollAreaClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement

      if (target && target.getAttribute) {
        target.getAttribute("data-id") === "scrolling-container-area" &&
          navigateToClickedScrollbarLocation(e.clientY, ref)
      }
    },
    [ref]
  )

  const handleMouseDown = (e: MouseEvent) => {
    setIsDragging(true)
    startYRef.current = e.clientY
    startTopRef.current = scrollbarTop
    document.body.style.userSelect = "none"
  }

  const handleMouseMove = useCallback(
    (e: globalThis.MouseEvent) => {
      if (isDragging) {
        const bottomBoundary = calculateScrollbarBottomBoundary(ref, scrollAreaRef)
        const deltaY = e.clientY - startYRef.current
        const newTop = Math.min(bottomBoundary, Math.max(0, startTopRef.current + deltaY))

        setScrollbarTop(newTop)
        scrollTo({ top: transformThumbTopToWindowScroll(ref, newTop), behavior: "instant" })
      }
    },
    [isDragging, ref]
  )

  const handleMouseUp = useCallback((e: globalThis.MouseEvent) => {
    e.stopPropagation()
    setIsDragging(false)
    document.body.style.userSelect = "auto"
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", syncScrollbarPosition)
    return () => {
      window.removeEventListener("scroll", syncScrollbarPosition)
    }
  }, [syncScrollbarPosition])

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove)
    if (isDragging) {
      document.addEventListener("mouseup", handleMouseUp)
    } else {
      document.removeEventListener("mouseup", handleMouseUp)
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }, [isDragging, handleMouseUp, handleMouseMove])

  return (
    <aside className="sticky top-16 flex h-0 w-6 bg-fill">
      <div
        data-id="scrolling-container-area"
        onClick={handleScrollAreaClick}
        className="relative h-[calc(100vh-64px)] w-6 border-l"
        ref={scrollAreaRef}
      >
        {/*Scrollbar thumb*/}
        {isVisibleScroll(ref, scrollbarHeight) && (
          <div
            onMouseDown={handleMouseDown}
            style={{ height: `${scrollbarHeight}px`, top: scrollbarTop }}
            className="absolute w-full cursor-default bg-border"
          ></div>
        )}

        {discussionsWithTop.map((discussion) => (
          <LineMark key={discussion.id} discussion={discussion} ref={ref} data-discussion-id={discussion.id} />
        ))}
      </div>
    </aside>
  )
})
