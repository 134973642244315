export const UserDropdown = {
  MyAccount: "My Account",
  ProfileItem: "Your Profile",
  ToggleTheme: "Toggle Theme",
  DarkMode: "Dark mode",
  LightMode: "Light mode",
  Settings: "Settings",
  SignOut: "Sign out"
}

export const RepoSelector = {
  Changes: "Changes",
  Public: "Public",
  Private: "Private",
  LastUpdatedOn: "Last updated on"
}

export const TableDropdown = {
  Comment: "Comment",
  Suggest: "Suggest",
  Refactor: "Refactor",
  CopyLink: "Copy link"
}
