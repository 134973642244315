import { Button } from "@/components"
import { Undo2 } from "lucide-react"
import { useSearchParams } from "react-router-dom"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { clearAllFiltersFromLocalStorage } from "../utils"

interface Props {
  clearingException?: string | string[]
  localStorageKey?: string
  variant: "link" | "default" | "destructive" | "outline" | "secondary" | "ghost" | null | undefined
}

export const ClearAllFilters = ({ clearingException, localStorageKey, variant = "ghost" }: Props) => {
  const { t } = useTranslation("Filters")
  const [searchParams, setSearchParams] = useSearchParams()

  const handleReset = useCallback(() => {
    if (typeof clearingException === "string") {
      const exception = searchParams.get(clearingException)
      setSearchParams({ [clearingException]: exception || "" })
      return
    }

    if (localStorageKey) {
      clearAllFiltersFromLocalStorage(localStorageKey)
    }

    setSearchParams({})
  }, [searchParams, setSearchParams, clearingException, localStorageKey])

  return (
    <div>
      <Button
        onClick={handleReset}
        size="sm"
        variant={variant}
        className="mx-2 flex h-[28px] justify-center px-2 text-xs text-secondary"
      >
        <Undo2 width={16} height={16} className="mr-1" />
        {t("ClearFilters")}
      </Button>
    </div>
  )
}
