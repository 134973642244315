import { Button } from "@/components"
import { ChevronDown } from "lucide-react"
import { TriggerElementProps } from "./types"
import { cn } from "@/lib/utils"

export const BaseTriggerElement = ({
  disabled,
  isOpen,
  label,
  TriggerIcon,
  selectItemPlaceholder,
  triggerElementClass
}: TriggerElementProps) => {
  return (
    <Button
      disabled={disabled}
      variant="outline"
      role="combobox"
      aria-expanded={isOpen}
      className={cn("h-8 w-full justify-between px-2 text-xs", triggerElementClass)}
    >
      <span className="flex items-center gap-2 overflow-x-hidden">
        {TriggerIcon ? <TriggerIcon className="min-w-fit" width={16} height={16} /> : null}
        {label ? <span className="overflow-x-hidden  text-ellipsis">{label}</span> : selectItemPlaceholder}
      </span>
      <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
    </Button>
  )
}
