import { Discussion } from "@/models/Discussions"
import { marginInPixels } from "../../Inline/useSyncElementPosition"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/Tooltip"
import { TooltipComment } from "./TooltipComment"
import { useDiscussions } from "@/services/store/useDiscussions"
import { CombinedAvatarChip } from "@/components/designSystem/Avatar/CombinedAvatarChip.tsx"
import { useMemo } from "react"
import { calculateAdjustedTopPosition } from "@/components/designSystem/Discussions/utils"

interface Props {
  current: Discussion
  discussionNumber: number
  isResolved: boolean
  handleOpen: () => void
  topPosition: number | null
  id: string
}

export interface CommentedUser {
  name: string
  imageSrc: string | undefined
}

function removeDuplicateUsers(users: CommentedUser[]) {
  return Array.from(new Map(users.map((user) => [user.name, user])).values())
}

export const DiscussionChip = ({ current, discussionNumber, isResolved, handleOpen, topPosition, id }: Props) => {
  const firstComment = current.comments[0]
  const lastComment = current.comments[current.comments.length - 1]

  const { setCurrentHoveredDiscussion, clearCurrentHoveredDiscussion } = useDiscussions()

  const commentedUsers: CommentedUser[] = useMemo(() => {
    return removeDuplicateUsers(
      current.comments.map((comment) => ({
        name: comment.author_user?.display_name ?? comment.author,
        imageSrc: comment.author_user?.avatar_url
      }))
    )
  }, [current.comments])

  const avatarColor = isResolved ? "green" : current.outdated ? "gray" : "yellow"

  // marginInPixels is the top assigned to the discussion when it has no placement on the page
  const isDiscussionVisible = useMemo(() => topPosition && topPosition > marginInPixels, [topPosition])

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger
          onClick={handleOpen}
          onMouseEnter={() => setCurrentHoveredDiscussion(current)}
          onMouseLeave={() => clearCurrentHoveredDiscussion()}
        >
          <div
            id={id}
            data-discussion-number={discussionNumber}
            style={{ top: calculateAdjustedTopPosition(topPosition) }}
            className={`flex-center w-[calc(100%-16px)] ${isDiscussionVisible ? "absolute" : "hidden"}`}
          >
            <CombinedAvatarChip color={avatarColor} avatars={commentedUsers} />
          </div>
        </TooltipTrigger>
        <TooltipContent
          style={{ top: calculateAdjustedTopPosition(topPosition) }}
          className="absolute left-[-420px] min-h-[80px] w-[420px] rounded-xl bg-background shadow-md"
        >
          <TooltipComment
            avatarColor={avatarColor}
            commentedUsers={commentedUsers}
            comment={firstComment}
            isResolved={isResolved}
            lastComment={lastComment}
          />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
