import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation } from "@tanstack/react-query"
import { useCallback } from "react"

interface Args {
  repoName: string
}

export const useDeleteReviewerExcludedConfiguration = () => {
  const deleteExcludedConfiguration = useCallback(
    async ({ repoName }: Args) => await axiosClient.delete(apiRoutes.reviewerConfig.excluded.delete(repoName)),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["deleteReviewerExcludedConfiguration"],
    mutationFn: deleteExcludedConfiguration
  })

  return { data, mutateAsync, status }
}
