import { FileStatus, FileWithDiffs } from "@/models/FCC"
import { CIRun, ReviewStatus } from "@/models/Changes"
import { LocalStorageService } from "@/lib/localStorageService.ts"
import { Discussion, NewCommentBubble } from "@/models/Discussions.ts"
import { isDiscussion } from "@/components/designSystem/Discussions/utils.ts"
import { BlockerBoxItem } from "@/pages/crFileChanges/components/BlockerBox.tsx"
import { AlertOctagon, File, LucideIcon } from "lucide-react"
import {
  CheckIcon,
  Cross2Icon,
  MinusCircledIcon,
  QuestionMarkIcon,
  ReloadIcon,
  UpdateIcon
} from "@radix-ui/react-icons"
import { IconProps } from "@radix-ui/react-icons/dist/types"
import { CollaborationUser } from "@/models/CollaborationUser.ts"

export interface TreeNode {
  name: string
  children?: TreeNode[]
  editType: FileStatus
  fullPath?: string
}

export interface CRViewStatus {
  pullRequestID: string
  commitSha: string
  seenAt: string
}

export interface ReviewersUnresolvedDiscussions {
  [username: string]: { counter: number; user: CollaborationUser }
}

export const buildTreeFromFilePaths = (files: FileWithDiffs[]) => {
  const root: TreeNode[] = []

  files.forEach(({ file_relative_path: path, file_status }) => {
    const parts = path.split("/")
    let currentLevel = root

    parts.forEach((part, index) => {
      let existingNode = currentLevel.find((node) => node.name === part)

      if (!existingNode) {
        existingNode = { name: part, editType: null }
        if (index < parts.length - 1) {
          existingNode.children = []
        } else {
          existingNode.editType = file_status
          existingNode.fullPath = path
        }
        currentLevel.push(existingNode)
      }

      // the key in this algorithm is to go deep in the children for each iteration
      if (index < parts.length - 1) {
        currentLevel = existingNode.children!
      }
    })
  })

  return root
}

export const filterOldChangeRequests = () => {
  const localStorageService = new LocalStorageService("localStorage")
  const changes = localStorageService.getItem<Array<CRViewStatus>>("viewedChanges", true)

  if (!changes) return

  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

  const filteredOldChanges = changes.filter((pr) => {
    const seenAtDate = new Date(pr.seenAt)
    return seenAtDate > oneWeekAgo
  })

  localStorageService.setItem("viewedChanges", filteredOldChanges)
}

export const extractUnresolvedDiscussions = (discussions: (Discussion | NewCommentBubble)[]): Discussion[] => {
  return discussions.filter(
    (discussion) => isDiscussion(discussion) && discussion.discussion_state !== "resolved" && !discussion.outdated
  ) as Discussion[]
}

export const aggregateReviewersDiscussions = (discussions: Discussion[]) => {
  return discussions.reduce((acc, { author_user, author }) => {
    const id = author_user?.id || author
    const user = author_user || { id, display_name: id }

    acc[id] = {
      counter: (acc[id]?.counter || 0) + 1,
      user
    }

    return acc
  }, {} as ReviewersUnresolvedDiscussions)
}

export const getFormattedReviewsStatus = (reviewsStatus: ReviewStatus): BlockerBoxItem[] => {
  if (typeof reviewsStatus === "string" || !reviewsStatus.waiting_for_approvals) return []

  const formattedDescription = `At least ${reviewsStatus.waiting_for_approvals[0]} approving reviews are required`
  const formattedSubDescription =
    reviewsStatus.waiting_for_approvals[1] > 0 ? `Only ${reviewsStatus.waiting_for_approvals[1]} had approved` : ""

  return [
    {
      Icon: AlertOctagon,
      description: formattedDescription,
      isLink: false,
      subDescription: formattedSubDescription
    }
  ]
}

export const getUnresolvedDiscussionsBlocker = (description: string): BlockerBoxItem[] => {
  return [
    {
      Icon: AlertOctagon,
      description: description,
      isLink: false
    }
  ]
}

export const getFormattedCiRuns = (ciRuns: CIRun[], showAll: boolean): BlockerBoxItem[] => {
  const statusOrder = Array.from(CICheckStatusToIconMap.keys())

  return ciRuns
    .filter((ci) => showAll || ["failure", "expected", "pending", "cancelled"].includes(ci.status))
    .sort((a, b) => {
      // Primary sort by status
      const statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
      if (statusComparison !== 0) return statusComparison

      // Secondary sort by required if status is "failure"
      if (a.status === "failure" && b.status === "failure") {
        return (b.required ? 1 : 0) - (a.required ? 1 : 0)
      }

      return 0
    })
    .map((ci) => ({
      Icon: CICheckStatusToIconMap.get(ci.status)?.icon ?? QuestionMarkIcon,
      description: `@${ci.ci_name} ${ci.name}`,
      isLink: true,
      link: ci.link,
      iconColor: CICheckStatusToIconMap.get(ci.status)?.color ?? "gray",
      iconToolTip: CICheckStatusToIconMap.get(ci.status)?.tooltip
    }))
}

export const getFormattedConflicts = (conflicts: string[]): BlockerBoxItem[] =>
  conflicts?.map((conflict) => ({
    description: conflict,
    Icon: File,
    isLink: false
  })) || []

export const handleScrollToTop = () => scrollTo({ top: 0, behavior: "smooth" })

// Order of fields determines the order of sorted CI checks
const CICheckStatusToIconMap = new Map<
  string,
  {
    icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> | LucideIcon
    color: string
    tooltip: string
  }
>([
  ["failure", { icon: Cross2Icon, color: "red", tooltip: "Failed" }],
  ["expected", { icon: UpdateIcon, color: "gray", tooltip: "Expected" }],
  ["pending", { icon: UpdateIcon, color: "gray", tooltip: "Pending" }],
  ["success", { icon: CheckIcon, color: "green", tooltip: "Success" }],
  ["skipped", { icon: ReloadIcon, color: "gray", tooltip: "Skipped" }],
  ["cancelled", { icon: MinusCircledIcon, color: "gray", tooltip: "Cancelled" }],
  ["unknown", { icon: QuestionMarkIcon, color: "gray", tooltip: "Unknown" }]
])

/**
 * Extracts Mermaid chart content from a markdown string
 * @param description Markdown text that may contain Mermaid charts
 * @returns The content of the first Mermaid chart found, or null if none exists
 */
export const extractMermaidContent = (description?: string): string | null => {
  if (!description) return null

  const mermaidRegex = /```mermaid([\s\S]*?)```/
  const match = description.match(mermaidRegex)

  return match ? match[1].trim() : null
}

export const PAGE_CONTAINER_PADDING = "pl-12 pr-6"
