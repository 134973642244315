import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { defaultQueryConfig } from "@/hooks/queries/queryConfig"

export type FeatureFlags = {
  [key: string]: boolean
}

export const useFetchFeatureFlags = () => {
  return useQuery<FeatureFlags>({
    queryKey: ["featureFlag"],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.featureFlag.get, {})
      return response.data
    },
    ...defaultQueryConfig
  })
}
