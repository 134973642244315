interface DollarSVGProps {
  color?: string
  className?: string
}

const DollarSVG = ({ color = "#16AD7A", className, ...props }: DollarSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
    className={className}
    {...props}
  >
    <g id="icons/main/sparks">
      <g id="Union" fill={color} fillRule="evenodd" clipRule="evenodd">
        <path d="M7.046 6.712a2.3 2.3 0 0 1 1.62-.671h1.042V4.999a.625.625 0 1 1 1.25 0v1.042h2.709a.625.625 0 1 1 0 1.25h-2.709v2.083H12a2.292 2.292 0 1 1 0 4.583h-1.042V15a.625.625 0 0 1-1.25 0v-1.042H7a.625.625 0 1 1 0-1.25h2.708v-2.083H8.667a2.292 2.292 0 0 1-1.62-3.912m2.662 2.662V7.291H8.667a1.042 1.042 0 0 0 0 2.083zm1.25 1.25v2.083H12a1.042 1.042 0 1 0 0-2.083z"></path>
        <path d="M8.432 1.531a3.96 3.96 0 0 1 4.99.996 3.958 3.958 0 0 1 4.383 4.384 3.96 3.96 0 0 1 0 6.177 3.96 3.96 0 0 1-4.373 4.383 3.956 3.956 0 0 1-5 1 4 4 0 0 1-1.188-1 3.958 3.958 0 0 1-4.382-4.373 3.958 3.958 0 0 1 0-6.198 3.958 3.958 0 0 1 4.383-4.373 4 4 0 0 1 1.187-.996m1.901.764a2.71 2.71 0 0 0-2.282 1.25.625.625 0 0 1-.663.272 2.708 2.708 0 0 0-3.236 3.23.625.625 0 0 1-.276.664 2.708 2.708 0 0 0 0 4.577c.222.14.333.406.276.663a2.708 2.708 0 0 0 3.236 3.23.625.625 0 0 1 .664.274 2.71 2.71 0 0 0 4.57 0 .625.625 0 0 1 .663-.274 2.71 2.71 0 0 0 3.23-3.237.625.625 0 0 1 .273-.663 2.71 2.71 0 0 0 0-4.564.625.625 0 0 1-.273-.663 2.71 2.71 0 0 0-3.236-3.237.625.625 0 0 1-.664-.273 2.71 2.71 0 0 0-2.282-1.25"></path>
      </g>
    </g>
  </svg>
)

export default DollarSVG
