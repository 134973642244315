import BGLight from "@/assets/images/BGLight.png"
import BGDark from "@/assets/images/BGDark.svg"
import { ImageCarousel } from "@/pages/auth/ImageCarousel"
import BazzyAILight from "@/assets/images/BazzyAILight.png"
import BazReviewerLight from "@/assets/images/BazReviewerLight.png"
import CRTopicsLight from "@/assets/images/CRTopicsLight.png"
import BazzyAIDark from "@/assets/images/BazzyAIDark.png"
import BazReviewerDark from "@/assets/images/BazReviewerDark.png"
import CRTopicsDark from "@/assets/images/CRTopicsDark.png"
import { LoginSection } from "@/pages/auth/LoginSection"
import { useSettings } from "@/services/store/useSettings"
import { useEffect, useState } from "react"

const lightImages = [BazzyAILight, CRTopicsLight, BazReviewerLight]
const darkImages = [BazzyAIDark, CRTopicsDark, BazReviewerDark]

export default function Login() {
  document.title = "Baz"
  const { selectedTheme } = useSettings()
  const [images, setImages] = useState(selectedTheme === "light" ? lightImages : darkImages)
  const [bgImage, setBGImage] = useState(selectedTheme === "light" ? BGLight : BGDark)

  useEffect(() => {
    setImages(selectedTheme === "light" ? lightImages : darkImages)
    setBGImage(selectedTheme === "light" ? BGLight : BGDark)
  }, [selectedTheme])

  useEffect(() => {
    const body = document.querySelector("body")

    if (selectedTheme === "dark") {
      body?.classList.add("dark")
    } else {
      body?.classList.remove("dark")
    }
  }, [selectedTheme])

  return (
    <div className="flex h-screen w-screen">
      <aside className="flex w-full grow items-center justify-center bg-background  lg:w-2/4">
        <LoginSection />
      </aside>
      <section
        className="hidden size-full lg:block"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <ImageCarousel images={images} />
      </section>
    </div>
  )
}
