import { CenteredLayout } from "@/pages/CenteredLayout.tsx"
import BazAvatar from "@/assets/icons/BazAvatar-light.svg?react"
import { useTranslation } from "react-i18next"
import { Button } from "@/components"
import GithubIcon from "@/assets/icons/Github-white.svg?react"
import GithubIconLight from "@/assets/icons/Github.svg?react"
import ArrowsIcon from "@/assets/icons/Arrows.svg?react"
import { githubUrl } from "@/models/GithubIntegrations"

export function OnBoardingConnect() {
  const { t: OnBoardingTranslation } = useTranslation("OnBoarding")

  return (
    <CenteredLayout>
      <section className="small-content-container">
        <div className="mb-9 min-h-[188px] w-full rounded-2xl bg-white/50 px-6 pb-6 pt-10 shadow-xl">
          <div className="mb-7 flex items-center justify-center gap-2">
            <div className="flex size-[72px] items-center justify-center rounded-2xl border bg-white shadow-md">
              <BazAvatar className="size-9" />
            </div>
            <ArrowsIcon />
            <div className="flex size-[72px] items-center justify-center rounded-2xl border bg-white shadow-md">
              <GithubIconLight className="size-9" />
            </div>
          </div>

          <h3 className="mb-7 text-center text-xl text-primary">{OnBoardingTranslation("ConnectBazToGithub")}</h3>
          <p className="mx-auto mb-7 w-[315px] text-center text-sm text-light-secondary">
            {OnBoardingTranslation("Start")}
          </p>
          <Button className="w-full">
            <a
              className="flex w-full items-center justify-center gap-2"
              target="_blank"
              rel="noopener noreferrer"
              href={githubUrl}
            >
              <GithubIcon />
              {OnBoardingTranslation("CTAConnectToGithub")}
            </a>
          </Button>
          <p className="mx-auto mt-2 text-center text-xxs text-light-secondary">
            {OnBoardingTranslation("OrgOnlySupport")}
          </p>
        </div>
      </section>
    </CenteredLayout>
  )
}
