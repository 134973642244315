import { Check } from "lucide-react"
import { cn } from "@/lib/utils"
import { compareParsedUrlToValue } from "../utils"
import { CommandItem, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { Option } from "../types"

interface Props {
  option: Option
  handleSelect: (value: string) => void
  urlFilter: string | null
  withTooltip?: boolean
}

export const SelectOption = ({ option, handleSelect, urlFilter, withTooltip }: Props) => {
  return (
    <CommandItem
      key={option.value}
      value={option.value}
      onSelect={() => handleSelect(option.value)}
      className="text-xs"
    >
      <Tooltip>
        <TooltipTrigger className="flex w-full text-secondary">
          <Check
            className={cn(
              "mr-2 h-4 w-4",
              compareParsedUrlToValue(urlFilter, option.value) ? "opacity-100" : "opacity-0"
            )}
          />
          {option.label}
        </TooltipTrigger>
        {withTooltip && (
          <TooltipContent side="right" align="end">
            {option.tooltipContent || option.label}
          </TooltipContent>
        )}
      </Tooltip>
    </CommandItem>
  )
}
