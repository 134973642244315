import { useEffect } from "react"
import { sortDiscussionsBasedOnFileOrder } from "../utils"
import { useDiscussions } from "@/services/store/useDiscussions"
import { ChangeRequest } from "@/models/Changes"
import { GeneratedDiscussions } from "./GeneratedDiscussions"
import { Discussion } from "@/models/Discussions"
import { DiscussionsHeader } from "@/components/designSystem/Discussions/Inline/DiscussionHeader"

interface Props {
  discussions: Discussion[]
  changeRequest: ChangeRequest
  filePaths: string[]
}

export const InlineDiscussionsLayout = ({ discussions: discussionsFromBE, changeRequest, filePaths }: Props) => {
  const { discussions, setDiscussions, discussionsExpanded, setDiscussionsWithTop } = useDiscussions()
  const commitSha = changeRequest?.commits[0]?.sha

  useEffect(() => {
    setDiscussions(sortDiscussionsBasedOnFileOrder(discussionsFromBE, filePaths))
  }, [discussionsFromBE, filePaths, setDiscussions])

  useEffect(() => {
    setDiscussionsWithTop()
  }, [setDiscussionsWithTop, discussions])

  return (
    <div
      id="discussion-wrapper"
      className={`flex flex-col ${discussionsExpanded ? "discussion-width-container" : "min-w-closed-discussion"}`}
    >
      <DiscussionsHeader />
      <div className="relative flex-1">
        <div className="relative w-full p-2">
          <GeneratedDiscussions discussions={discussions} crId={changeRequest.id} commitSha={commitSha} />
        </div>
      </div>
    </div>
  )
}
