import { routes } from "@/lib/routes"
import { createBrowserRouter, RouteObject } from "react-router-dom"
import { Root } from "./root"
import ErrorPage from "./error"
import PrivateRoute from "./PrivateRoute.tsx"
import PublicRoute from "./PublicRoute.tsx"
import { OnBoardingConnect } from "@/pages/onboarding/Connect"
import { Onboarding } from "@/pages/onboarding"
import { GithubWebhookCallback } from "@/pages/settings/integrations/github/GithubWebhookCallback"
import { Changes } from "@/pages/changes"
import { CRFileChanges } from "@/pages/crFileChanges"
import { CreateNewCR } from "@/pages/createNewCR"
import NotFound from "@/pages/NotFound"
import { Privilege } from "@/pages/privilege"
import { Code } from "@/pages/code"
import { Slack } from "@/pages/slack"
import { Settings } from "@/pages/settings"
import { Profile } from "@/pages/settings/Profile"
import { Account } from "@/pages/settings/Account"
import { Preferences } from "@/pages/settings/reviewer/Preferences"
import { Integrations } from "@/pages/settings/integrations"
import { SlackCallback } from "@/pages/settings/integrations/slack/SlackCallback"
import Login from "@/pages/auth"
import { Disabled } from "@/pages/disabled"
import { RedundantCIRuns } from "@/pages/redundantCIRuns"

export const routerConfig: RouteObject[] = [
  {
    path: routes.onboarding.root,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.onboarding.root,
        element: <Onboarding />
      },
      {
        path: routes.onboarding.connect,
        element: <OnBoardingConnect />
      }
    ]
  },
  // this page is an exception of a private route because its part of the github callback url and should not be effected
  // by the private route onboarding logic
  {
    path: routes.settings.githubWebhookCallback,
    element: <GithubWebhookCallback />,
    errorElement: <ErrorPage />
  },
  {
    path: routes.disabled,
    element: <Disabled />,
    errorElement: <ErrorPage />
  },
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Root />,
        children: [
          {
            path: routes.code.root,
            element: <Code />
          },
          {
            path: routes.code.filledWithBranch,
            element: <Code />
          },
          {
            path: routes.code.filledWithPath,
            element: <Code />
          },
          {
            path: routes.changes.root,
            element: <Changes />
          },
          {
            path: routes.changes.crFileChanges({
              prNumber: ":prNumber",
              repoName: ":repoName",
              organization: ":organization"
            }),
            element: <CRFileChanges />
          },
          {
            path: routes.changes.createNewCR,
            element: <CreateNewCR />
          },
          {
            path: routes.runs.root,
            element: <RedundantCIRuns />
          },
          {
            path: routes.notFound,
            element: <NotFound />
          },
          {
            path: routes.privilege,
            element: <Privilege />
          },
          {
            path: routes.slack,
            element: <Slack />
          },
          {
            path: routes.settings.root,
            element: <Settings />,
            children: [
              {
                path: routes.settings.profile,
                element: <Profile />
              },
              {
                path: routes.settings.accountSettings,
                element: <Account />
              },
              {
                path: routes.settings.preferences,
                element: <Preferences />
              },
              {
                path: routes.settings.integrations({ provider: ":provider" }),
                element: <Integrations />
              },
              {
                path: routes.settings.slackCallback,
                element: <SlackCallback />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    element: <PublicRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.login,
        element: <Login />
      },
      {
        path: routes.notFound,
        element: <NotFound />
      }
    ]
  }
] as RouteObject[]

export const router = createBrowserRouter(routerConfig)
