const StopWatchSVG = ({ color = "var(--base-primary)" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g id="icons/main/stopwatch">
      <g id="Icon" fill={color}>
        <path d="M6.167 1.334a.5.5 0 0 1 .5-.5h2.666a.5.5 0 1 1 0 1H6.667a.5.5 0 0 1-.5-.5"></path>
        <path d="M10.354 6.98a.5.5 0 0 1 0 .708l-2 2a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0"></path>
        <path
          fillRule="evenodd"
          d="M8 3.5a5.833 5.833 0 1 0 0 11.667A5.833 5.833 0 0 0 8 3.5M3.167 9.334a4.833 4.833 0 1 1 9.666 0 4.833 4.833 0 0 1-9.666 0"
          clipRule="evenodd"
        ></path>
      </g>
    </g>
  </svg>
)

export default StopWatchSVG
