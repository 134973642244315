import {
  getExtendedParent,
  getLineContent,
  getSelectedLineNumber,
  getSelectedText
} from "@/components/designSystem/FCC/utils"
import { SelectedLines } from "@/models/FCC.ts"
import { MouseEvent } from "react"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

export interface UseSelectionState {
  selectedText: string | null
  selectedTableFilePath: string | null
  selectedLines: SelectedLines
  firstSelectedSide: "left" | "right" | null
  handleMouseDown: (event: React.MouseEvent<HTMLElement>) => void
  handleMouseUp: (event: React.MouseEvent<HTMLElement>) => void
  setSelection: (
    selectedTableFilePath: string | null,
    selectedLines: SelectedLines,
    firstSelectedSide: "left" | "right" | null
  ) => void
  currentEditableTable: string | null
  setCurrentEditableTable: (filePath: string | null) => void
  shouldCancelLineSelectionChange: (event: MouseEvent<HTMLElement>) => boolean
  clearLines: () => void
  clearSelection: () => void
}

export const useSelection = create<UseSelectionState>()(
  immer((set, get) => ({
    selectedText: null,
    selectedTableFilePath: null,
    selectedLines: { startLine: null, endLine: null },
    firstSelectedSide: null,
    currentEditableTable: null,

    handleMouseDown: (event: MouseEvent<HTMLElement>) => {
      const anchorNode = getExtendedParent(event.target as Node, "data-table-side")

      set((state) => {
        state.firstSelectedSide = (anchorNode?.getAttribute("data-table-side") as "left" | "right") || null
      })
    },

    handleMouseUp: (event: MouseEvent<HTMLElement>) => {
      if (get().shouldCancelLineSelectionChange(event)) return

      const selection = window.getSelection()

      const getLine = (node: Node | null | undefined, attr: string) =>
        parseInt(getExtendedParent(node, attr, 12)?.getAttribute(attr) || "0")

      const side = get().firstSelectedSide

      const lines = {
        startLine: getLine(selection?.anchorNode, `data-${side}`),
        endLine: getLine(selection?.focusNode, `data-${side}`)
      }

      const cellElement = getExtendedParent(selection?.anchorNode, "data-table-side")
      const tableElement = getExtendedParent(cellElement, "data-file-path")
      const filePath = tableElement?.getAttribute("data-file-path")

      const lineElement = getExtendedParent(selection?.anchorNode, "data-table-side")

      set((state) => {
        state.selectedText = lines.startLine === lines.endLine ? getLineContent(lineElement) : getSelectedText()
        state.selectedTableFilePath = filePath || null
        state.selectedLines = {
          startLine: getSelectedLineNumber(lines.startLine, lines.endLine, "start"),
          endLine: getSelectedLineNumber(lines.startLine, lines.endLine, "end")
        }
      })
    },
    setCurrentEditableTable(filePath) {
      set((state) => {
        state.currentEditableTable = filePath
      })
    },
    shouldCancelLineSelectionChange: (event: MouseEvent<HTMLElement>): boolean => {
      const lines = get().selectedLines
      const selectedText = get().selectedText
      const isSingleLine = !lines.endLine && !lines.startLine
      const plusButton = getExtendedParent(event.target as Node, "data-id", 2)
      const dropdownMenu = getExtendedParent(event.target as Node, "data-id", 4)

      const cancelOperation =
        plusButton?.getAttribute("data-id") === "plusButton" ||
        dropdownMenu?.getAttribute("data-id") === "contextMenuDropdown"

      // If we have multi-line selection, preserve existing calculation
      if (selectedText && lines.startLine !== lines.endLine) {
        return cancelOperation
      }

      // For single-line or empty selection scenarios
      if (isSingleLine && plusButton) {
        return false
      }

      // Default fallback
      return cancelOperation
    },
    clearLines: () => {
      set((state) => {
        state.selectedLines = {
          endLine: null,
          startLine: null
        }
      })
    },
    clearSelection: () => {
      set((state) => {
        state.setSelection(null, { startLine: null, endLine: null }, null)
      })
    },
    setSelection: (selectedTableFilePath, selectedLines, firstSelectedSide) => {
      set((state) => {
        state.selectedTableFilePath = selectedTableFilePath
        state.selectedLines = selectedLines
        state.firstSelectedSide = firstSelectedSide
        state.selectedText = getSelectedText()
      })
    }
  }))
)
