import { ChangeRequest } from "@/models/Changes.ts"
import { SubHeaderMetaData } from "@/pages/crFileChanges/components/CRHeader/CRSubHeader/SubHeaderMetaData.tsx"
import { motion } from "framer-motion"
import { InfoActions } from "@/pages/crFileChanges/components/CRHeader/CRActions/InfoActions"
import { PAGE_CONTAINER_PADDING } from "@/pages/crFileChanges/utils"

interface Props {
  changeRequest: ChangeRequest
  isMinimalHeader: boolean
  foldersTreeOpen: boolean
}

export const CRSubHeader = ({ changeRequest, isMinimalHeader, foldersTreeOpen }: Props) => {
  return (
    <motion.div
      key="cr-subheader"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
      className={`flex w-full items-center justify-between ${PAGE_CONTAINER_PADDING} text-primary`}
      data-id="sub-header"
    >
      <SubHeaderMetaData
        changeRequest={changeRequest}
        isMinimalHeader={isMinimalHeader}
        foldersTreeOpen={foldersTreeOpen}
      />
      <InfoActions changeRequest={changeRequest} />
    </motion.div>
  )
}
