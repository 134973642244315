import { useState, useMemo, useCallback, useEffect, useRef } from "react"
import { Committer, FileDetails, Topic, TopicInsights } from "@/models/Topics"
import { CIRun, CIRunWithErrors } from "@/models/Changes"
import UsageSVG from "@/assets/icons/UsageSVG"
import FailedCheckSVG from "@/assets/icons/FailedCheckSVG"
import GitBranchesSVG from "@/assets/icons/GitBranchesSVG"
import {
  Badge,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { BreakingChangeDetails, UniqueStatEntry } from "@/components/designSystem/Insights/helpers"
import { InsightsDialog } from "@/components/designSystem/Topics/InsightsDialog"
import Checkmark from "@/assets/icons/Checkmark"
import { useAddViewedFile } from "@/hooks/mutations/useAddViewedFile"
import { useDeleteViewedFile } from "@/hooks/mutations/useDeleteViewedFile"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IconType } from "@/components/designSystem/Topics/helpers"
import { TooltipPortal } from "@radix-ui/react-tooltip"

interface TopicBadgeProps {
  topic: Topic
  getInsightsData: (insights: TopicInsights) => {
    uniqueStats: UniqueStatEntry[]
    uniqueBreakingChanges: BreakingChangeDetails[]
  }
  getChecksWithExtraData: (checks: CIRun[], files: FileDetails[], insights: TopicInsights) => CIRunWithErrors[]
  minimized: boolean
  withHover?: boolean
}

type IconConfig = {
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  type: Extract<IconType, "usage" | "checks" | "developers">
  condition: boolean
}

type PopoverContentType = {
  [K in IconType]: {
    topicDescription: string
    type: K
    data: (UniqueStatEntry | BreakingChangeDetails | CIRunWithErrors | Committer | FileDetails)[] | []
  }
}

const TopicBadge: React.FC<TopicBadgeProps> = ({
  topic,
  getInsightsData,
  getChecksWithExtraData,
  minimized,
  withHover = true
}) => {
  const badgeRef = useRef<HTMLDivElement>(null)
  const [popoverState, setPopoverState] = useState<{
    isOpen: boolean
    type: IconType
    selectedType: IconType | null
    hoverPosition: number
    badgeWidth: number
  }>({
    isOpen: false,
    type: "empty",
    selectedType: null,
    hoverPosition: 0,
    badgeWidth: 0
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const {
    selectedTopic,
    setSelectedTopic,
    topics,
    setTopics,
    changeRequest,
    setBreakingChangeItemToBeShown,
    setRunErrorItemToBeShown
  } = useChangeRequest()

  const { uniqueStats, uniqueBreakingChanges } = getInsightsData(topic.insights)
  const checksData = getChecksWithExtraData(topic.checks, topic.files_details, topic.insights)
  const { mutateAsync: addViewed } = useAddViewedFile()
  const { mutateAsync: deleteViewed } = useDeleteViewedFile()
  const { t } = useTranslation("Topics")

  const popoverContent: PopoverContentType = useMemo(
    () => ({
      empty: { topicDescription: "", type: "empty", data: [] },
      name: { topicDescription: topic.summary, type: "name", data: [] },
      usage: {
        topicDescription: topic.summary,
        type: "usage",
        data: [...uniqueStats, ...uniqueBreakingChanges]
      },
      checks: { topicDescription: topic.summary, type: "checks", data: checksData },
      developers: {
        topicDescription: topic.summary,
        type: "developers",
        data: topic.last_committers
      },
      files: { topicDescription: topic.summary, type: "files", data: topic.files_details }
    }),
    [topic, uniqueStats, uniqueBreakingChanges, checksData]
  )

  useEffect(() => {
    if (!selectedTopic && topic.name === searchParams.get("topic")) {
      setSelectedTopic(topic)
    }
  }, [topic, selectedTopic, setSelectedTopic, searchParams])

  const iconConfigs: IconConfig[] = [
    { Icon: UsageSVG, type: "usage", condition: uniqueStats.length > 0 || uniqueBreakingChanges.length > 0 },
    { Icon: FailedCheckSVG, type: "checks", condition: checksData.length > 0 },
    { Icon: GitBranchesSVG, type: "developers", condition: topic.last_committers.length > 0 }
  ]

  const handleMouseMove = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (badgeRef.current) {
      const badgeRect = badgeRef.current.getBoundingClientRect()
      const hoverPosition = event.clientX - badgeRect.left
      const badgeWidth = badgeRect.width

      setPopoverState((prev) => ({
        ...prev,
        hoverPosition,
        badgeWidth
      }))
    }
  }, [])

  const handleTopicSelect = useCallback(
    (topic: Topic) => {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params)

        if (selectedTopic && topic.name === selectedTopic.name) {
          setSelectedTopic(null)
          newParams.delete("topic")
        } else {
          setSelectedTopic(topic)
          newParams.set("topic", topic.name)
        }

        return newParams
      })
    },
    [selectedTopic, setSearchParams, setSelectedTopic]
  )

  const handlePopoverOpen = (type: IconType) => {
    setPopoverState((prev) => ({
      ...prev,
      isOpen: true,
      type,
      hoverPosition: prev.hoverPosition,
      badgeWidth: badgeRef.current?.getBoundingClientRect().width || 0
    }))
  }

  const handlePopoverClose = (forceClose?: boolean) => {
    if (forceClose || !popoverState.selectedType) {
      setPopoverState({
        isOpen: false,
        type: "empty",
        selectedType: null,
        hoverPosition: 0,
        badgeWidth: 0
      })
    }
    setBreakingChangeItemToBeShown(null)
    setRunErrorItemToBeShown(null)
  }

  const handleIconClick = (type: IconType) => {
    setPopoverState((prev) => ({
      isOpen: true,
      type,
      selectedType: prev.selectedType === type ? null : type,
      hoverPosition: prev.hoverPosition,
      badgeWidth: prev.badgeWidth
    }))
  }

  const toggleTopicViewed = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      const newViewedState = !topic.isViewed

      // Update the topics array with the new view state for the relevant topic
      if (topics) {
        const newTopics = topics.map((stateTopic) => {
          // update the selected topic to the new viewed state
          if (stateTopic.id === topic.id && selectedTopic?.id === topic.id) {
            setSelectedTopic({ ...stateTopic, isViewed: newViewedState })
          }
          return stateTopic.id === topic.id ? { ...stateTopic, isViewed: newViewedState } : stateTopic
        })
        setTopics(newTopics)
      }

      // Update viewed status for each file
      const commitSha = changeRequest?.commits[0]?.sha
      if (!commitSha) {
        console.error("No commit SHA available")
        return
      }

      const updatePromises = topic.files_details.map((file) =>
        newViewedState ? addViewed({ fileId: file.id, commitId: commitSha }) : deleteViewed({ fileId: file.id })
      )

      try {
        await Promise.all(updatePromises)
      } catch (error) {
        console.error("Error updating file viewed status:", error)
      }
    },
    [
      topic.isViewed,
      topic.files_details,
      topic.id,
      topics,
      changeRequest?.commits,
      setTopics,
      selectedTopic?.id,
      setSelectedTopic,
      addViewed,
      deleteViewed
    ]
  )

  return (
    <TooltipProvider>
      <Popover open={popoverState.isOpen} onOpenChange={(isOpen) => (isOpen ? null : handlePopoverClose(true))}>
        <PopoverTrigger>
          <Badge
            ref={badgeRef}
            onMouseMove={handleMouseMove}
            onClick={() => handleTopicSelect(topic)}
            className={`flex cursor-pointer rounded-md border bg-background p-[3px] pl-2 text-xs leading-5 text-secondary ${
              selectedTopic?.id === topic.id ? "border-hint" : "border-border"
            }
            ${minimized ? "h-6" : "h-8"}
            `}
          >
            {(topic.isViewed || topic.id === selectedTopic?.id) && (
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="ghost"
                    className="size-6 p-1"
                    onClick={(event) => toggleTopicViewed(event)}
                    onMouseEnter={() => handlePopoverClose()}
                  >
                    <Checkmark color={topic.isViewed ? "var(--green-success-dark)" : "var(--base-hint)"} />
                  </Button>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>{topic.isViewed ? t("unReviewedTooltip") : t("reviewedTooltip")}</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )}
            <Button
              className={`h-1 bg-background p-1 text-secondary ${minimized ? "text-[12px]" : ""} hover:bg-background`}
              onMouseEnter={() => !popoverState.selectedType && handlePopoverOpen("name")}
            >
              <p>{topic.name}</p>
            </Button>

            <Button className="h-1 bg-background p-1 text-secondary hover:bg-background" variant="default">
              {iconConfigs.map(
                ({ Icon, type, condition }, index) =>
                  condition && (
                    <Icon
                      key={index}
                      className={`hover:rounded-sm ${
                        popoverState.selectedType === type ? "bg-darker-fill" : "hover:bg-darker-fill"
                      }`}
                      onClick={(event: React.MouseEvent) => {
                        event.stopPropagation()
                        handleIconClick(type)
                      }}
                      onMouseEnter={() => !popoverState.selectedType && handlePopoverOpen(type)}
                    />
                  )
              )}
            </Button>
            {!minimized ? (
              <Button
                variant="ghost"
                className={`flex h-6 items-center rounded-sm bg-background px-[5px] font-medium text-secondary ${
                  popoverState.selectedType === "files" ? "bg-darker-fill" : "hover:bg-darker-fill"
                }`}
                onMouseEnter={() => !popoverState.selectedType && handlePopoverOpen("files")}
                onClick={(event) => {
                  event.stopPropagation()
                  handleIconClick("files")
                }}
              >
                {topic.files_details.length}
              </Button>
            ) : null}
          </Badge>
        </PopoverTrigger>
        {withHover && (
          <PopoverContent align="start" onMouseLeave={() => !popoverState.selectedType && handlePopoverClose(true)}>
            <InsightsDialog {...popoverContent[popoverState.type]} handleClose={handlePopoverClose} />
          </PopoverContent>
        )}
      </Popover>
    </TooltipProvider>
  )
}

export default TopicBadge
