import { NavLink } from "react-router-dom"
import { Badge, Button } from "@/components"
import { useSettings } from "@/services/store/useSettings"

interface NavLinkButtonProps {
  to: string
  label: string
  showBadge?: boolean
  badgeText?: string
}

export const NavLinkButton = ({ to, label, showBadge = false, badgeText }: NavLinkButtonProps) => {
  const { selectedTheme } = useSettings()

  const getButtonClassName = (isActive: boolean) => {
    const baseClasses = "h-8 px-2"
    if (isActive) {
      return `${baseClasses} bg-primary-base text-white hover:bg-primary-base`
    }
    return `${baseClasses} hover:bg-${selectedTheme === "dark" ? "base-dark-border" : "border"}`
  }

  const getBadgeClassName = (isActive: boolean) => {
    const baseClasses = "ml-1 h-5 cursor-pointer rounded-md bg-darker-fill p-1 text-xxs font-normal text-primary"
    if (isActive) {
      return `${baseClasses} bg-secondary text-background`
    }
    return `${baseClasses} ${selectedTheme === "dark" ? "bg-base-dark-border text-primary" : "bg-border"}`
  }

  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <Button className={getButtonClassName(isActive)} variant="ghost">
          {label}
          {showBadge && <Badge className={getBadgeClassName(isActive)}>{badgeText}</Badge>}
        </Button>
      )}
    </NavLink>
  )
}
