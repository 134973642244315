const NoRedundantRunsSVGDark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="142" height="67" fill="none" viewBox="0 0 142 67">
    <rect width="107" height="37" x="17.5" y="29.5" fill="#1C2026" stroke="#334155" rx="7.5" />
    <rect width="125" height="44" x="8.5" y="14.5" fill="#1C2026" stroke="#334155" rx="7.5" />
    <path
      fill="#1C2026"
      stroke="#334155"
      d="M.5 8A7.5 7.5 0 0 1 8 .5h126a7.5 7.5 0 0 1 7.5 7.5v35a7.5 7.5 0 0 1-7.5 7.5H8A7.5 7.5 0 0 1 .5 43z"
    />
    <rect width="81" height="5" x="9" y="36" fill="#64748B" opacity="0.13" rx="2.5" />
    <rect width="118" height="5" x="9" y="27" fill="#64748B" opacity="0.13" rx="2.5" />
    <rect width="95" height="7" x="32" y="10" fill="#64748B" opacity="0.18" rx="2" />
    <g strokeLinecap="round" strokeLinejoin="round" opacity="0.55">
      <path
        d="M20 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4 M12 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4 M16.667 10h2A1.333 1.333 0 0 1 20 11.333V16 M15.333 18h-2A1.334 1.334 0 0 1 12 16.667V12"
        stroke="#F3654A"
        strokeWidth="1"
      />
      <use href="#path" stroke="#475569" />
    </g>
  </svg>
)

export default NoRedundantRunsSVGDark
