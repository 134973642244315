import { useEffect, useRef, useState } from "react"

/**
 * A hook that detects if text is truncated and manages tooltip visibility state
 *
 * @template T - The HTML element type for the ref (defaults to HTMLElement)
 * @returns An object containing:
 * - textRef: Ref to attach to the element containing potentially truncated text
 * - isTextTruncated: Boolean indicating if the text is truncated
 * - isTooltipOpen: Boolean controlling tooltip visibility
 * - setIsTooltipOpen: Function to manually control tooltip visibility
 * - tooltipProps: Object containing handlers for tooltip open/close events
 */
export const useTruncatedTooltip = <T extends HTMLElement = HTMLElement>() => {
  const [isTextTruncated, setIsTextTruncated] = useState(false)
  const textRef = useRef<T>(null)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  useEffect(() => {
    // Check if text is truncated when component mounts
    const checkTruncation = () => {
      if (textRef.current) {
        const isTruncated = textRef.current.scrollWidth > textRef.current.clientWidth
        setIsTextTruncated(isTruncated)
      }
    }

    checkTruncation()

    // Create a ResizeObserver to detect changes in element size
    const resizeObserver = new ResizeObserver(checkTruncation)

    // Store the current ref value to use in cleanup
    const currentRef = textRef.current

    if (currentRef) {
      resizeObserver.observe(currentRef)
    }

    return () => {
      // Use the captured value in the cleanup function
      if (currentRef) {
        resizeObserver.unobserve(currentRef)
      }
      resizeObserver.disconnect()
    }
  }, [])

  // Tooltip trigger event handlers
  const tooltipProps = {
    onMouseEnter: () => isTextTruncated && setIsTooltipOpen(true),
    onMouseLeave: () => setIsTooltipOpen(false),
    onFocus: () => isTextTruncated && setIsTooltipOpen(true),
    onBlur: () => setIsTooltipOpen(false)
  }

  return {
    textRef,
    isTextTruncated,
    isTooltipOpen,
    setIsTooltipOpen,
    tooltipProps
  } as const
}
