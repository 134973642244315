import { RedundantRun } from "@/models/RedundantRuns"
import { useTruncatedTooltip } from "@/hooks/useTruncatedTooltip"
import {
  getBackgroundColor,
  getChangeRequestStateIcon
} from "@/components/designSystem/Badges/CodeChangeStateBadge/utils"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"

export const CRLinkCell = ({ value, row }: { value: string; row: RedundantRun }) => {
  const isDraft = row.codeReviewState === "draft"
  const { textRef, isTooltipOpen, tooltipProps } = useTruncatedTooltip<HTMLParagraphElement>()

  return (
    <div className="flex w-full justify-between text-primary hover:cursor-pointer">
      <div className="flex items-center">
        <div
          className={`mr-2 rounded-md border border-border p-1 text-secondary ${getBackgroundColor({
            is_draft: isDraft,
            state: row.codeReviewState
          })}`}
        >
          {getChangeRequestStateIcon({
            is_draft: isDraft,
            state: isDraft ? "open" : row.codeReviewState,
            iconColor: "text-background"
          })}
        </div>
        <TooltipProvider delayDuration={1000}>
          <Tooltip open={isTooltipOpen}>
            <TooltipTrigger {...tooltipProps}>
              <a href={value} target="_blank" rel="noopener noreferrer">
                <p ref={textRef} className="max-w-[600px] truncate underline">
                  {value}
                </p>
              </a>
            </TooltipTrigger>
            <TooltipContent className="max-w-2xl">{value}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="h-6 shrink-0 cursor-text whitespace-nowrap rounded-md bg-darker-fill px-1 py-0.5 text-xs font-medium text-primary">
        +{row.jobNumOfCRs}
      </div>
    </div>
  )
}
