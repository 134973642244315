function ZoomOutSVG({ color = "currentColor", className }: { color?: string; className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      style={{ color: color }}
      className={className}
    >
      <g id="icons/main/zoom-out">
        <g id="Icon" fill={color}>
          <path d="M4.667 5.98a.437.437 0 1 0 0 .874h3.5a.438.438 0 0 0 0-.875z"></path>
          <path
            fillRule="evenodd"
            d="M6.417 11.52c1.252 0 2.398-.45 3.286-1.198l2.238 2.237a.437.437 0 1 0 .618-.618l-2.237-2.238a5.104 5.104 0 1 0-3.905 1.817m0-9.332A4.23 4.23 0 1 0 9.36 9.454a.4.4 0 0 1 .094-.094 4.23 4.23 0 0 0-3.037-7.172"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default ZoomOutSVG
