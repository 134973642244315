import { PanelLeftClose, PanelLeftOpen } from "lucide-react"
import { Button } from "@/components"
import { useTranslation } from "react-i18next"
import { useDiscussions } from "@/services/store/useDiscussions"

export const DiscussionsHeader = () => {
  const { t } = useTranslation("Discussions")
  const { toggleExpandDiscussionsContainer, toggleManualRecalculationTrigger, discussionsExpanded, discussions } =
    useDiscussions()

  const onToggle = () => {
    toggleExpandDiscussionsContainer()
    toggleManualRecalculationTrigger()
  }

  return (
    <div
      id="discussions-header"
      className="sticky top-[186px] z-20 max-w-full border-b border-border bg-background p-3"
    >
      <div className="flex items-center space-x-3">
        <Button onClick={onToggle} variant="outline" size="sm" className="float-right size-7 p-1">
          {discussionsExpanded ? <PanelLeftOpen size={13} /> : <PanelLeftClose size={13} />}
        </Button>
        {discussionsExpanded && (
          <p className="text-xs font-semibold">{`${discussions.length} ${t("Conversation")}${discussions.length === 0 || discussions.length > 1 ? "s" : ""}`}</p>
        )}
      </div>
    </div>
  )
}
