import { CommandItem, Avatar } from "@/components"
import { cn } from "@/lib/utils"
import { Check } from "lucide-react"
import { Option } from "../types"

interface Props {
  selectedValues: Set<string>
  option: Option
  handleOptionSelect: (value: string) => void
}

export const MultiSelectOption = ({ selectedValues, option, handleOptionSelect }: Props) => {
  const isSelected = selectedValues.has(option.value)

  return (
    <CommandItem
      value={option.value}
      key={option.value}
      onSelect={() => handleOptionSelect(option.value)}
      className="my-[2px] h-8 py-2 text-xs text-secondary"
    >
      <Check className={cn("mr-2 h-4 w-4", isSelected ? "opacity-100" : "opacity-0")} />
      {option.icon ? (
        <Avatar className="m-2 size-6 rounded-full" avatarURL={option.icon} username={option.label} />
      ) : (
        ""
      )}
      <span className="text-secondary">{option.label}</span>
    </CommandItem>
  )
}
