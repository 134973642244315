import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { compareDesc } from "date-fns"
import { Avatar, SingleSelect, Button } from "@/components"
import { Repository } from "@/models/Repository"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { LocalStorageService } from "@/lib/localStorageService"
import { RepositoryItem } from "./RepositoryItem"
import { TriggerElementProps } from "@/components/designSystem/SelectVariations/types"
import { ChevronDown } from "lucide-react"

interface Props {
  repositories: Repository[]
  defaultValue?: string
  defaultIcon?: string
  setCurrentRepositoryName: (fullName: string) => void
  setCurrentBranch: Dispatch<SetStateAction<string | undefined>>
  setCurrentRepositoryIcon: Dispatch<SetStateAction<string>>
}

const localStorageService = new LocalStorageService("localStorage")

export const RepositorySelectorTriggerElement = ({
  label,
  selectItemPlaceholder,
  iconURL,
  isOpen
}: TriggerElementProps) => {
  return (
    <Button
      variant="outline"
      role="combobox"
      className={`mb-2 h-10 w-full justify-between border-none px-2 text-xs hover:bg-border ${isOpen ? "bg-border" : "bg-darker-fill"}`}
    >
      <span className="flex items-center gap-2 overflow-x-hidden">
        <Avatar className="size-4 rounded-full" avatarURL={iconURL} username={label} />
        {label ? <span className="overflow-x-hidden  text-ellipsis">{label}</span> : selectItemPlaceholder}
      </span>
      <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
    </Button>
  )
}

export const RepositorySelector = ({
  setCurrentRepositoryName,
  setCurrentRepositoryIcon,
  repositories,
  defaultValue,
  defaultIcon,
  setCurrentBranch
}: Props) => {
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const navigate = useNavigate()
  const { t } = useTranslation("Code")

  const handleSelect = useCallback(
    (fullName: string) => {
      const repository = repositories?.find((repo) => repo.fullName === fullName)

      if (repository) {
        localStorageService.setItem("repositoryFullName", fullName)
        localStorageService.setItem("branch", repository.defaultBranch)

        setCurrentRepositoryName(fullName)
        setCurrentBranch(repository.defaultBranch)
        setOpen(false)
        setSearchTerm("")
        setCurrentRepositoryIcon(repository.avatarUrl)
        navigate(`/code/${fullName}/${repository.defaultBranch}`)
      }
    },
    [setCurrentRepositoryName, setOpen, repositories, navigate, setCurrentBranch, setCurrentRepositoryIcon]
  )

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        setSearchTerm("")
      }
      setOpen(open)
    },
    [setSearchTerm, setOpen]
  )

  const sortedRepositories = useMemo(
    () => repositories.sort((repoA, repoB) => compareDesc(repoA.updatedAt, repoB.updatedAt)),
    [repositories]
  )

  const searchOptions = useMemo(
    () => sortedRepositories.map((repo) => ({ name: repo.fullName, value: repo.fullName, ...repo })),
    [sortedRepositories]
  )

  return (
    <SingleSelect
      options={searchOptions}
      isOpen={open}
      searchValue={searchTerm}
      setSearchValue={setSearchTerm}
      openToggle={handleOpenChange}
      onClick={handleSelect}
      selectedValue={defaultValue || ""}
      optionalParams={{
        CustomOptionElement: RepositoryItem,
        CustomTriggerElement: RepositorySelectorTriggerElement,
        popoverContentClass: "text-xs w-fit min-w-[650px] p-0",
        triggerElementClass: "mb-4",
        withSearch: true,
        selectItemPlaceholder: t("SelectRepository"),
        emptyResultsMessage: t("NoRepositoryFound"),
        searchPlaceholder: t("SearchRepository"),
        showByLabel: false,
        iconURL: defaultIcon
      }}
    />
  )
}
