import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  TooltipProvider,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput
} from "@/components"
import { BaseItemComponent } from "./BaseItemComponent"
import { v4 as uuidv4 } from "uuid"
import { cn } from "@/lib/utils"
import { BaseTriggerElement } from "./BaseTriggerElement"
import { BaseOption, OptionalParams } from "./types"

interface SingleSelectProps<Option extends BaseOption> {
  options: Option[]
  isOpen: boolean
  selectedValue: string
  searchValue: string
  onClick: (value: string) => void
  openToggle: (isOpen: boolean) => void
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  optionalParams: OptionalParams<Option>
}

const filterByLabel = (value: string, search: string, options: BaseOption[]): number => {
  const label = options.find((item) => item.value?.toLowerCase() === value.toLowerCase())?.name?.toLowerCase()
  return label?.includes(search.toLowerCase()) ? 1 : 0
}

export function SingleSelect<SelectOption extends BaseOption>({
  options,
  isOpen,
  searchValue,
  selectedValue,
  setSearchValue,
  onClick,
  openToggle,
  optionalParams: {
    searchPlaceholder = "Search...",
    emptyResultsMessage = "No results found.",
    selectItemPlaceholder = "Please select an item",
    disabled = false,
    popoverContentClass = "",
    triggerElementClass = "",
    withSearch = false,
    TriggerIcon,
    CustomOptionElement,
    CustomTriggerElement,
    withTooltip = false,
    align = "start",
    showByLabel = true,
    iconURL
  }
}: SingleSelectProps<SelectOption>) {
  const chosenLabel = showByLabel ? options.find((option) => option.value === selectedValue)?.name : selectedValue

  const triggerElementProps = {
    disabled,
    isOpen,
    label: chosenLabel,
    TriggerIcon,
    selectItemPlaceholder,
    iconURL,
    triggerElementClass
  }
  const optionElementProps = {
    selectedValue,
    onClick,
    searchValue,
    withTooltip
  }

  return (
    <Popover open={isOpen} onOpenChange={openToggle}>
      <PopoverTrigger className={cn("w-full")}>
        {CustomTriggerElement ? (
          <CustomTriggerElement {...triggerElementProps} />
        ) : (
          <BaseTriggerElement {...triggerElementProps} />
        )}
      </PopoverTrigger>

      <PopoverContent className={cn("p-0 w-[250px] text-xs ", popoverContentClass)} align={align}>
        <Command filter={(value, search) => filterByLabel(value, search, options)}>
          {withSearch ? <CommandInput onValueChange={setSearchValue} placeholder={searchPlaceholder} /> : null}
          <CommandEmpty>{emptyResultsMessage}</CommandEmpty>

          <CommandGroup className="max-h-[400px] overflow-y-auto">
            <TooltipProvider>
              {options.map((option) =>
                CustomOptionElement ? (
                  <CustomOptionElement
                    key={option.value || option.name || uuidv4()}
                    {...optionElementProps}
                    option={option}
                  />
                ) : (
                  <BaseItemComponent
                    key={option.value || option.name || uuidv4()}
                    {...optionElementProps}
                    option={option}
                  />
                )
              )}
            </TooltipProvider>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
