const HandSVG = ({ color = "#F1F5F9", className }: { color?: string; className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" fill="none" viewBox="0 0 14 15" className={className}>
    <g id="icons/main/hand">
      <path
        id="Icon"
        fill={color}
        fillRule="evenodd"
        d="M5.941 1.024C6.23.69 6.621.5 7.03.5s.8.189 1.088.524c.23.27.38.615.431.986a1.37 1.37 0 0 1 .719-.208c.408 0 .8.189 1.088.525.231.269.381.614.432.985a1.37 1.37 0 0 1 .719-.207c.408 0 .8.188 1.088.524s.45.791.45 1.266v3.907c0 1.511-.515 2.96-1.434 4.03C10.693 13.9 9.447 14.5 8.15 14.5h-1.12c-1.68 0-2.736-.613-3.647-1.666H3.38l-2.015-2.345-.015-.018a1.97 1.97 0 0 1-.396-1.247c.01-.46.172-.897.452-1.222s.656-.51 1.051-.521c.28-.008.554.074.794.232v-4.12c0-.475.162-.93.45-1.266.29-.336.68-.525 1.089-.525.253 0 .5.073.719.208.05-.371.2-.717.431-.986m-.45 2.569v2.93c0 .27.187.489.42.489.231 0 .419-.219.419-.489V2.291c0-.216.074-.423.205-.576a.65.65 0 0 1 .495-.238c.185 0 .363.085.494.238s.205.36.205.576v3.907c0 .27.188.488.42.488s.42-.219.42-.488V3.593c0-.216.073-.423.204-.576a.65.65 0 0 1 .495-.238c.186 0 .363.086.495.238.13.153.205.36.205.576v3.256c0 .27.187.488.42.488.231 0 .419-.218.419-.488V4.895c0-.216.074-.423.205-.575a.65.65 0 0 1 .495-.239c.185 0 .363.086.494.239.131.152.205.36.205.575v3.907c0 1.252-.427 2.453-1.188 3.339-.761.885-1.793 1.382-2.87 1.382H7.03c-1.454 0-2.3-.507-3.056-1.38v-.002L1.967 9.808a.9.9 0 0 1-.174-.559A.88.88 0 0 1 2 8.694a.66.66 0 0 1 .478-.237.65.65 0 0 1 .48.205l.417.485.56.652c.164.19.43.19.593 0a.546.546 0 0 0 0-.69L4.092 8.6V3.593c0-.216.073-.423.204-.576a.65.65 0 0 1 .495-.238c.186 0 .363.086.495.238.13.153.205.36.205.576"
        clipRule="evenodd"
      ></path>
    </g>
  </svg>
)

export default HandSVG
